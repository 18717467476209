
import {unauthApi} from './unauth'


export const getTopBusinessesApi = unauthApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopBusinesses: builder.query({
      
      query: ({authType, token}) => `/v2/sp/gettopbusinesses?authType=${authType}&&token=${token}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetTopBusinessesQuery, usePrefetch } = getTopBusinessesApi