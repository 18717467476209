import React, { useState, useEffect } from 'react';
import ResultsSection from './ResultsSection';
import WriteReviewModal from './WriteReview';
import { Button, Box, Grid, Paper, Avatar, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { useThingEndorseMutation } from '../../redux/thunks/things/thingendorse';
import { useThingUnEndorseMutation } from '../../redux/thunks/things/thingunendorse';

import { useGetUserEndorsementsQuery  } from '../../redux/thunks/users/userendorsements';
import { userApi} from '../../redux/thunks/users/user'





const SkillFacePlate = ({ skill, spId, isMyProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  

  console.log('In skillfaceplate, skill', skill)
  //console.log('In skillfaceplate, isMyProfile', isMyProfile)


  const [isTrustedThing, setIsTrustedThing] = useState(false)
  
  

  

  const [triggerEndorse, { data: endorseResponse, isLoading: endorseIsLoading }] = useThingEndorseMutation();
  const [triggerUnEndorse, { data: unendorseResponse, isLoading: unendorseIsLoading }] = useThingUnEndorseMutation();
  const { data: myTrustedThings, error: myTrustedThingsError, isLoading: myTrustedThingsLoading } = useGetUserEndorsementsQuery();
  console.log('myTrustedThings', myTrustedThings)

  useEffect(() => {
    console.log('in skill face plate , useeffect myTrustedThings', myTrustedThings)
    if (myTrustedThings && skill) {
      const isTrustedThing = myTrustedThings.myTrustedThings.some((followingItem) => {
        return (
          followingItem === skill._id
        );
      });
  
      setIsTrustedThing(isTrustedThing);
      
         
    }
  }, [myTrustedThings, skill]);


  const handleWriteReview = () => {
    const feedbackProps = {
      skillName: skill.skillName,
      skillPhoto: skill.photoUrl,
      skillId: skill.skillId,
      spId: spId,
      businessId: skill._id,
      businessName: skill.businessName,
      skill: skill
    };

    // Use navigate to go to the feedback form page and pass props
    navigate('/feedback', { state: feedbackProps });
    
  };

  const handleUnEndorsement = async () => {
    // Add your logic here to handle endorsements (including removal)
    // Handle Endorse action
    const auth = {
      token: token,
      authType: 'token',
    };
    
    const payload = {
      auth,     
      businessId: skill._id,
    };  
        
    await triggerUnEndorse({ payload });
    console.log('finished the trigger')
    // Invalidate the cache manually
    dispatch(userApi.util.invalidateTags(['Endorsements']));

  };

  const handleEndorsement = async () => {
    // Add your logic here to handle endorsements (including removal)
    // Handle Endorse action
    const auth = {
      token: token,
      authType: 'token',
    };
    
    const payload = {
      auth,     
      businessId: skill._id,
    };  
        
    await triggerEndorse({ payload });
    console.log('finished the trigger')
    // Invalidate the cache manually
    dispatch(userApi.util.invalidateTags(['Endorsements']));

  };

  if (!skill || !spId) {
    return null; // Return null if skill or spId is undefined
  }

 
  



  return (
    <Box sx={{ width: '100%', height: 'auto' }}>
      <Grid container>
        <Grid item xs={3}>
          {/* First Column (25%) */}
          <Paper variant="outlined" style={{ border: 'none' }}>
            {/* Content for the first column */}
            <Avatar src={skill.photoUrl || skill.skillPhoto} sx={{ width: 60, height: 60, borderRadius: 0 }} />
            <Typography variant="subtitle2" fontWeight="bold" mb={1} >
            {skill.skillName} 
          </Typography>
          </Paper>
          
        </Grid>
        <Grid item xs={9}>
          {/* Second Column (75%) */}
          <Paper variant="outlined" sx={{ border: 0 }}>
            {/* Content for the second column */}
            <ResultsSection data={skill} isMyProfile={isMyProfile} spId={spId}/>
            {/* Add your additional row content here */}
            {!isMyProfile &&
              <Stack direction="row" spacing={2} justifyContent="flex-start" mt={2}>
                <Button variant="outlined" onClick={handleWriteReview}>
                  Write a Review
                </Button>
                {!isMyProfile && !isTrustedThing &&
                <Button disabled={myTrustedThingsLoading} variant="outlined" onClick={handleEndorsement}>
                  Endorse
                </Button>
                }

                {!isMyProfile && isTrustedThing &&
                <Button disabled={myTrustedThingsLoading} variant="outlined" onClick={handleUnEndorsement}>
                  Unendorse
                </Button>
                }
                
              </Stack>             
            

            }
          
            
          </Paper>
        </Grid>
      </Grid>
      
      
       
    </Box>
  );
};

export default SkillFacePlate;
