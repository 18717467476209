import { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Divider,
  Tooltip,
  Collapse,
  Alert,
  Stack
} from '@mui/material';
import {
  Search as SearchIcon,
  PeopleOutlined as PeopleIcon,
  Business as BusinessIcon,
  RateReview as ReviewIcon,
  ImportContacts as ImportIcon,
  TrendingUp as TrendingIcon,
  StarBorder as StarIcon,
  LocationOnOutlined as LocationIcon,
  StorefrontOutlined as StorefrontIcon,
  ArrowForward as ArrowIcon,
  VerifiedUser  as VerifiedUserIcon,
  RateReview as RateReviewIcon,
  AddBusiness as AddBusinessIcon,
  RateReview,
  TrendingUp,  
  Info as InfoIcon
  

} from '@mui/icons-material';
import { useSelector } from 'react-redux'; // For auth state
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUserByEmailQuery } from '../../redux/thunks/users/getuserbyemail';



// Mock Data
const GETTING_STARTED_ITEMS = [
  {
    id: 'find-people',
    title: 'Find People You Know',
    icon: PeopleIcon,
    description: 'Connect with your network',
    action: 'Find People',
    completed: false,
    color: 'primary'
  },
  {
    id: 'set-location',
    title: 'Set Your Location',
    icon: LocationIcon,
    description: 'Get relevant local recommendations',
    action: 'Set Location',
    completed: false,
    hidden: false, // This would be controlled by user's location status
    color: 'warning'
  },
  {
    id: 'find-businesses',
    title: 'Find Businesses',
    icon: StorefrontIcon,
    description: 'Discover trusted local businesses',
    action: 'Explore',
    completed: false,
    color: 'success'
  }
];

const SUGGESTED_ACTIONS = [
  {
    id: 'trusted-businesses',
    title: 'Businesses Your Connections Trust',
    description: 'See where your network shops and dines',
    count: 12,
    action: 'View All',
    goal: 3, // At least 3 connections
    isUnlocked: (connections) => connections >= 3,
    goalMessage: 'Goal: At least 3 connections',
  },
  {
    id: 'local-influencers',
    title: 'Local Influencers',
    description: 'Connect with trusted reviewers in your area',
    count: 8,
    action: 'Discover',
    goal: 'location', // Requires location to be set
    isUnlocked: (location) => Boolean(location),
    goalMessage: 'Goal: Set your address',
  },
  {
    id: 'review-insights',
    title: 'Review Insights',
    description: 'Analyze your review performance',
    count: 5,
    action: 'Start Reviewing',
    goal: 1, // At least 1 review
    isUnlocked: (reviews) => reviews >= 1,
    goalMessage: 'Goal: Write at least 1 review',
  },
];

const NEXT_GOALS = [
  {
    id: 1,
    title: 'Write First Review',
    description: 'Share your experience',
    current: 0,
    target: 1,
    color: 'primary',
    icon: ReviewIcon
  },
  {
    id: 2,
    title: 'Build Your Network',
    description: 'Connect with 5 people',
    current: 1,
    target: 5,
    color: 'success',
    icon: PeopleIcon
  },
  {
    id: 3,
    title: 'Follow Businesses',
    description: 'Follow 10 trusted businesses',
    current: 3,
    target: 10,
    color: 'warning',
    icon: BusinessIcon
  }
];

const MAIN_ACTIONS = [
  {
    icon: SearchIcon,
    title: 'Discover and Review',
    description: 'Share your experiences with local businesses',
    action: () => navigate('/explore')
  },
  {
    icon: PeopleIcon,
    title: 'Connect with Others',
    description: 'Build your trust network',
    action: () => navigate('/network')
  },
  {
    icon: BusinessIcon,
    title: 'Own a Business?',
    description: 'Claim and manage your business profile',
    action: () => navigate('/business/claim')
  },
  {
    icon: AddBusinessIcon,
    title: 'Request Reviews',
    description: 'Post a product or business to get reviews',
    action: () => navigate('/reviews/request')
  }
];

const FUTURE_SUGGESTED_ACTIONS = [
  {
    id: 'trending-businesses',
    title: 'Trending Businesses',
    description: 'Discover businesses gaining popularity',
    action: 'Explore Now',
    goal: 5, // Follow at least 5 businesses
    isUnlocked: (followedBusinesses) => followedBusinesses >= 5,
    goalMessage: 'Goal: Follow at least 5 businesses',
  },
  {
    id: 'referral-rewards',
    title: 'Invite Friends',
    description: 'Get rewarded for referrals',
    action: 'Invite',
    goal: 3, // Invite at least 3 friends
    isUnlocked: (invitedFriends) => invitedFriends >= 3,
    goalMessage: 'Goal: Invite at least 3 friends',
  },
  {
    id: 'ai-insights',
    title: 'AI Business Insights',
    description: 'Unlock personalized insights for your business',
    action: 'Get Insights',
    goal: 1, // Add or claim one business
    isUnlocked: (businessesAdded) => businessesAdded >= 1,
    goalMessage: 'Goal: Add or claim a business',
  },
];


const GetStartedSection = ({ onAction }) => {
  const [showLocationAlert, setShowLocationAlert] = useState(true);
  const completedSteps = GETTING_STARTED_ITEMS.filter(item => item.completed).length;
  const totalSteps = GETTING_STARTED_ITEMS.length;

  return (
    <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Getting Started
      </Typography>
      
      {/* Location Alert */}
      <Collapse in={!GETTING_STARTED_ITEMS[1].completed && showLocationAlert}>
        <Alert 
          severity="info" 
          sx={{ mb: 2 }}
          onClose={() => setShowLocationAlert(false)}
        >
          Set your location to get personalized recommendations
        </Alert>
      </Collapse>

      {/* Action Cards */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {GETTING_STARTED_ITEMS.map((item) => {
          if (item.hidden) return null;
          const Icon = item.icon;

          return (
            <Paper
              key={item.id}
              elevation={0}
              onClick={() => !item.completed && onAction(item.id)}
              sx={{
                p: 2,
                bgcolor: `${item.color}.50`,
                borderRadius: 2,
                transition: 'all 0.3s ease',
                cursor: item.completed ? 'default' : 'pointer',
                '&:hover': {
                  bgcolor: item.completed ? `${item.color}.50` : `${item.color}.100`,
                  transform: item.completed ? 'none' : 'translateY(-2px)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    bgcolor: `${item.color}.main`,
                    borderRadius: '50%',
                    p: 1,
                    mr: 2,
                    display: 'flex',
                    opacity: item.completed ? 0.7 : 1
                  }}
                >
                  <Icon sx={{ color: 'white' }} />
                </Box>
                <Box flex={1}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ opacity: item.completed ? 0.7 : 1 }}
                  >
                    {item.title}
                    {item.completed && (
                      <Typography 
                        component="span" 
                        sx={{ 
                          ml: 1, 
                          color: 'success.main',
                          fontSize: '0.8rem' 
                        }}
                      >
                        ✓ Done
                      </Typography>
                    )}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ opacity: item.completed ? 0.7 : 1 }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })}
      </Box>

      {/* Progress Indicator */}
      <Box sx={{ 
        mt: 2, 
        pt: 2, 
        borderTop: 1, 
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="body2" color="text.secondary">
          {completedSteps} of {totalSteps} steps completed
        </Typography>
        {completedSteps === totalSteps && (
          <Typography variant="body2" color="success.main">
            All set! ✨
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

const MainActionsSection = ({ onAction }) => {
  return (
    <Paper variant="outlined" sx={{ p: 3, border: 'none' }}>
      <Typography variant="h5" gutterBottom>
        Start Building Your Network
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        Here's what you can do:
      </Typography>

      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {MAIN_ACTIONS.map((action, index) => {
          const Icon = action.icon;
          return (
            <Paper
              key={index}
              elevation={0}
              onClick={action.action}
              sx={{
                p: 2.5,
                bgcolor: 'grey.50',
                borderRadius: 2,
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bgcolor: 'grey.100',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box
                  sx={{
                    minWidth: 48,
                    height: 48,
                    borderRadius: 2,
                    bgcolor: 'primary.50',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2
                  }}
                >
                  <Icon 
                    sx={{ 
                      color: 'primary.main',
                      fontSize: 24
                    }} 
                  />
                </Box>
                <Box>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      mb: 0.5,
                      fontWeight: 500 
                    }}
                  >
                    {action.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                  >
                    {action.description}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })}
      </Box>

      {/* Optional: Help Text */}
      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
        <Typography variant="body2" color="text.secondary">
          Click any card to get started with that activity
        </Typography>
      </Box>
    </Paper>
  );
};

// Skeleton Components
const ProfileSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="circular" width={80} height={80} sx={{ mb: 2 }} />
    <Skeleton variant="text" width={150} />
    <Skeleton variant="text" width={100} />
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" height={8} sx={{ mb: 1 }} />
      <Skeleton variant="text" width={40} />
    </Box>
  </Box>
);

const ContentSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={80} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={200} />
  </Box>
);



// Main Component
export default function EarlyUserHome() {
  const [loading, setLoading] = useState(false);


  const token = useSelector((state) => state.auth?.token || null);
  const userId = useSelector((state) => state.auth?.userId || '');
  const user = useSelector((state) => state.auth?.user || '');

  const getInitials = (name) => {
    if (!name) return '';
    const [firstName, lastName] = name.split(' ');
    return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase();
  };

  const { data: userData, isError, isLoading: isUserLoading } = useGetUserByEmailQuery(user.email);
  console.log('userData', userData);


  const StatsBanner = () => (
    <Box 
      sx={{ 
        width: '100%',
        bgcolor: 'primary.main',
        color: 'white',
        py: 3,
        mb: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Trust Score"
              value="25/100"
              icon={TrendingUp}
              light
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Reviews"
              value="0 Reviews"
              icon={RateReview}
              light
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Network"
              value="2 Connections"
              icon={PeopleIcon}
              light
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
  
  const StatCard = ({ title, value, icon: Icon, light = false }) => (
    <Stack 
      direction="row" 
      alignItems="center" 
      spacing={2}
    >
      <Box
        sx={{
          bgcolor: light ? 'rgba(255,255,255,0.1)' : `${theme.palette.primary.main}15`,
          p: 1.5,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Icon sx={{ color: light ? 'white' : 'primary.main' }} />
      </Box>
      <Box>
        <Typography 
          variant="body2" 
          color={light ? 'rgba(255,255,255,0.7)' : 'text.secondary'} 
          gutterBottom
        >
          {title}
        </Typography>
        <Typography 
          variant="h6"
          color={light ? 'white' : 'inherit'}
        >
          {value}
        </Typography>
      </Box>
    </Stack>
  );
  
  
  const handleGetStartedAction = (itemId) => {
    switch(itemId) {
      case 'find-people':
        navigate('/network/find');
        break;
      case 'set-location':
        // Open location modal or navigate to settings
        setLocationModalOpen(true);
        break;
      case 'find-businesses':
        navigate('/explore');
        break;
      default:
        console.log('Unknown action:', itemId);
    }
  };
  
  const handleSuggestionAction = (itemId) => {
    switch(itemId) {
      case 'trusted-businesses':
        navigate('/network/trusted-businesses');
        break;
      case 'local-influencers':
        navigate('/network/influencers');
        break;
      case 'first-reviews':
        navigate('/reviews/opportunities');
        break;
      default:
        console.log('Unknown suggestion:', itemId);
    }
  };
  

  if (loading) {
    return (
      <Box>
        <Paper sx={{ width: '100%', p: 3, bgcolor: 'primary.main', color: 'white', borderRadius: 0 }}>
          <Container maxWidth="xl">
            <Skeleton variant="text" width={300} height={40} sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
            <Skeleton variant="text" width={400} height={30} sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          </Container>
        </Paper>

        <Container maxWidth="xl" sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Paper><ProfileSkeleton /></Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper><ContentSkeleton /></Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper><ContentSkeleton /></Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box >
      {/* Welcome Banner */}
      <StatsBanner />

      <Container maxWidth="xl" sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            {/* Profile Summary */}
            <Paper variant="outlined" sx={{ p: 2, mb: 3, border: 'none' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{ width: 80, height: 80, mr: 2 }}
                  src={user.profileImageUrl || undefined} 
                  alt={user.displayName}
                >
                  {!user.profileImageUrl && getInitials(user.displayName)}
                </Avatar>
                <Box>
                  <Typography variant="h6">{user.displayName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    New Member
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Complete your profile to unlock more features
              </Typography>
              <Box sx={{ mt: 2 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={10} 
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" color="text.secondary">
                  10% Complete
                </Typography>
              </Box>
            </Paper>

            {/* Getting Started Checklist */}
            <GetStartedSection onAction={handleGetStartedAction} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            {/* Search Bar */}
            <Paper variant="outlined" sx={{ p: 2, mb: 3, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
                Find Trusted Businesses
              </Typography>
              <TextField
                fullWidth
                placeholder="Search businesses, products, or services..."
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
                }}
              />
            </Paper>

            {/* Empty State Content */}
            <MainActionsSection />
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            {/* Suggested Actions */}
            <Paper variant="outlined" sx={{ p: 2, mb: 3, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
                Suggested For You
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {SUGGESTED_ACTIONS.map((action, index) => {
                  const isUnlocked = action.isUnlocked(user.connections || user.location || user.reviews || 0);

                  return (
                    <Paper
                      key={index}
                      variant="outlined"
                      onClick={() => isUnlocked && handleSuggestionAction(action.id)}
                      sx={{
                        p: 2,
                        border: 'none',
                        bgcolor: isUnlocked ? 'grey.50' : 'grey.200',
                        cursor: isUnlocked ? 'pointer' : 'not-allowed',
                        '&:hover': {
                          bgcolor: isUnlocked ? 'grey.100' : 'grey.200',
                        },
                        opacity: isUnlocked ? 1 : 0.6,
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: isUnlocked ? 600 : 400 }}>
                        {action.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {action.description}
                      </Typography>
                      {!isUnlocked && (
                        <Typography
                          variant="body2"
                          sx={{ color: 'error.main', fontWeight: 'bold' }}
                        >
                          {action.goalMessage}
                        </Typography>
                      )}
                    </Paper>
                  );
                })}
              </Box>
            </Paper>
            {/* Next Goals */}
            <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
                Next Goals
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Complete these to level up your profile
              </Typography>

              <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {NEXT_GOALS.map((goal) => (
                  <Paper
                    key={goal.id}
                    variant="outlined"
                    sx={{
                      p: 2,
                      border: 'none',
                      bgcolor: 'grey.50'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          mr: 1,
                          bgcolor: `${goal.color}.light`,
                          color: `${goal.color}.main`
                        }}
                      >
                        {goal.id}
                      </Avatar>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle2">
                          {goal.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {goal.description}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={(goal.current / goal.target) * 100}
                        sx={{ 
                          flex: 1,
                          height: 6,
                          borderRadius: 3,
                          [`& .MuiLinearProgress-bar`]: {
                            bgcolor: `${goal.color}.main`
                          }
                        }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {goal.current}/{goal.target}
                      </Typography>
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}


