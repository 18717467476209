import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { 
  Box, 
  Typography, 
  Container, 
  Grid, 
  Paper, 
  Avatar,
  Chip,
  Button,
  Skeleton
} from '@mui/material';
import { 
    RateReview as RateReviewIcon, 
    Favorite as FavoriteIcon, 
    Share as ShareIcon, 
    ThumbUp as EndorseIcon,
    Visibility as FollowIcon,
    Edit as EditIcon,
    Verified as VerificationIcon,
    LinkOff as UnlinkIcon,
    BusinessCenter as ClaimIcon,
    Report as ReportIcon,

  } from '@mui/icons-material';

// Static Data Structures
const BUSINESS_OWNER_DATA = {
    name: 'Sarah Chen',
    role: 'New Business Owner',
    joinedDate: 'December 2024',
    businessStatus: '📍 First Business'
  };
  
const BUSINESS_DATA = {
  name: 'Green Earth Kitchen',
  category: 'Restaurant • Vegetarian (Pending Verification)',
  initials: 'GEK',
  image: null, // Optional logo/icon
  location: '1234 Oak Street, City',
  status: {
    open: 'Opening Soon',
    concept: '✨ Sustainable dining concept'
  },
  chips: [
    { label: 'New Business', color: 'primary' },
    { label: 'Verification Pending', color: 'warning' }
  ]
};

const COMING_SOON_ITEMS = [
  'Full menu and pricing',
  'Business hours',
  'Photos and ambiance',
  'Special dietary options'
];

const BUSINESS_STATUS_ITEMS = [
  '🆕 Recently Added',
  '📋 Profile in Progress',
  '🔍 Verification Pending',
  '⏰ Opening Timeline: TBA'
];

const COMING_FEATURES = [
  'Customer Reviews',
  'Trust Score',
  'Business Hours',
  'Photo Gallery'
];

// Skeleton Components
const BusinessOwnerSkeleton = () => (
    <Paper sx={{ p: 2, textAlign: 'center' }}>
      <Skeleton variant="circular" width={80} height={80} sx={{ mx: 'auto', mb: 2 }} />
      <Skeleton variant="text" width={120} sx={{ mx: 'auto' }} />
      <Skeleton variant="text" width={100} sx={{ mx: 'auto' }} />
      <Box sx={{ mt: 2 }}>
        {[1, 2, 3].map((item) => (
          <Skeleton key={item} variant="text" />
        ))}
      </Box>
    </Paper>
  );

const BusinessDetailsSkeleton = () => (
  <Box>
    <Skeleton variant="rectangular" height={80} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={250} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={180} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={160} />
  </Box>
);

const BusinessStatusSkeleton = () => (
  <Box>
    {[1, 2, 3, 4].map((item) => (
      <Skeleton key={item} variant="text" />
    ))}
  </Box>
);

const NewBusinessPage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { businessId, title } = useParams();
  const token = useSelector((state) => state.auth?.token || null);
  const spId = useSelector((state) => state.auth?.user?.spId || null); 
  
  const [isLoading, setIsLoading] = useState(true);
  const [businessData, setBusinessData] = useState(BUSINESS_DATA);
  const [businessOwnerData, setBusinessOwnerData] = useState(BUSINESS_OWNER_DATA);
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);


  const [userInteractions, setUserInteractions] = useState({
      isFollowing: false,
      hasEndorsed: false,
      reviewCount: 0
  });
    
      // Stub function for writing a review
  const handleWriteReview = () => {
    // TODO: Open review modal or navigate to review page
    console.log('Navigating to write review for', businessData.name);
    // Potential future implementation:
    // - Open review modal
    // - Navigate to review page
    // - Trigger review writing process
  };
    
  // Stub function for endorsing the business
  const handleEndorse = () => {
    // Toggle endorsement status
    setUserInteractions(prev => ({
      ...prev,
      hasEndorsed: !prev.hasEndorsed
    }));
    
    console.log('Business endorsement toggled', 
      userInteractions.hasEndorsed ? 'removed' : 'added'
    );
    
    // TODO: Implement actual endorsement logic
    // - Update backend
    // - Show confirmation
    // - Handle potential errors
  };

  // Stub function for following the business
  const handleFollow = () => {
    // Toggle follow status
    setUserInteractions(prev => ({
      ...prev,
      isFollowing: !prev.isFollowing
    }));
    
    console.log('Business follow status', 
      userInteractions.isFollowing ? 'unfollowed' : 'followed'
    );
    
    // TODO: Implement actual follow logic
    // - Update backend
    // - Show follow/unfollow confirmation
    // - Handle potential errors
  };
  // Business Owner Actions
  const handleEditBusinessDetails = () => {
    console.log('Navigating to business details edit');
    // TODO: Navigate to business edit page or open edit modal
  };
  
  const handleStartVerification = () => {
    console.log('Initiating business verification process');
    // TODO: Start verification workflow
  };
  
  const handleUnclaim = () => {
    console.log('Unclaiming business');
    // TODO: Implement business unclaim logic
  };
  
  // Non-Business Owner Actions
  const handleClaimBusiness = () => {
    console.log('Initiating business claim process');
    // TODO: Open business claim workflow
  };
  
  const handleReportBusiness = () => {
    console.log('Opening business report dialog');
    // TODO: Implement business reporting mechanism
  };
  
  const handleShareBusiness = () => {
    console.log('Sharing business profile');
    // TODO: Implement business sharing functionality
  };
  

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setIsLoading(false);
      
      // Simulate potential API data update
      // setBusinessData(prevData => ({
      //   ...prevData,
      //   image: 'https://example.com/business-image.jpg'
      // }));
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  
  // Business Logo Component
  const BusinessLogo = () => {
    const { image, name, initials } = businessData;
    
    if (image) {
      return (
        <Avatar
          variant="rounded"
          src={image}
          alt={`${name} logo`}
          sx={{ 
            width: 48, 
            height: 48
          }}
        />
      );
    }
    
    return (
      <Avatar
        variant="rounded"
        sx={{ 
          width: 48, 
          height: 48,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          fontSize: 16
        }}
      >
        {initials}
      </Avatar>
    );
  };

  if (isLoading) {
    return (
      <Container maxWidth="xl" sx={{ py: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <BusinessOwnerSkeleton />
          </Grid>
          <Grid item xs={12} md={6}>
            <BusinessDetailsSkeleton />
          </Grid>
          <Grid item xs={12} md={3}>
            <BusinessStatusSkeleton />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Grid container spacing={3}>
        {/* Left Column - Business Owner */}
        <Grid item xs={12} md={3}>
          <Paper variant="outlined" sx={{ p: 2, textAlign: 'center', mb: 2, border: 'none' }}>
            <Avatar 
              sx={{ 
                width: 80, 
                height: 80, 
                mx: 'auto', 
                mb: 2,
                bgcolor: 'grey.300'
              }}
            >
              {businessOwnerData.name.split(' ').map(n => n[0]).join('')}
            </Avatar>
            <Typography variant="h6">{businessOwnerData.name}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {businessOwnerData.role}
            </Typography>
            <Box sx={{ mt: 2, textAlign: 'left', px: 2 }}>
              <Typography variant="body2" color="text.secondary">
                🆕 Joined {businessOwnerData.joinedDate}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {businessOwnerData.businessStatus}
              </Typography>
            </Box>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Similar Businesses
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Will appear as business category is confirmed
            </Typography>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
            <Typography variant="h6">Business Interactions</Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button 
            fullWidth 
            variant="outlined" 
            startIcon={<RateReviewIcon />}
            onClick={handleWriteReview}
          >
            Write Review
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button 
            fullWidth 
            variant={userInteractions.hasEndorsed ? "contained" : "outlined"}
            color="primary"
            startIcon={<EndorseIcon />}
            onClick={handleEndorse}
          >
            {userInteractions.hasEndorsed 
              ? "Endorsed" 
              : "Endorse Business"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button 
            fullWidth 
            variant={userInteractions.isFollowing ? "contained" : "outlined"}
            color="primary"
            startIcon={<FollowIcon />}
            onClick={handleFollow}
          >
            {userInteractions.isFollowing 
              ? "Following" 
              : "Follow Business"}
          </Button>
        </Grid>
      </Grid>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
  {/* Business Owner View */}
  {isBusinessOwner && (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>Business Owner Actions</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEditBusinessDetails}
          >
            Edit Business Details
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<VerificationIcon />}
            onClick={handleStartVerification}
          >
            Start Verification Process
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            color="error"
            startIcon={<UnlinkIcon />}
            onClick={handleUnclaim}
          >
            Unclaim Business
          </Button>
        </Grid>
      </Grid>
    </>
  )}

  {/* Non-Business Owner View */}
  {!isBusinessOwner && (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>Business Actions</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<ClaimIcon />}
            onClick={handleClaimBusiness}
          >
            Claim This Business
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<ReportIcon />}
            onClick={handleReportBusiness}
          >
            Report Business
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<ShareIcon />}
            onClick={handleShareBusiness}
          >
            Share Business
          </Button>
        </Grid>
      </Grid>
    </>
  )}
</Paper>
        </Grid>

        {/* Middle Column - Business Details */}
        <Grid item xs={12} md={6}>
          <Paper 
            sx={{ 
              bgcolor: 'warning.light', 
              color: 'warning.contrastText', 
              p: 2, 
              mb: 2 
            }}
          >
            <Typography variant="subtitle1">New on Revfer</Typography>
            <Typography variant="body2">
              This business recently joined our platform
            </Typography>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
          <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2, 
                mb: 2 
              }}
            >
              <BusinessLogo />
              <Box>
                <Typography variant="h4">{businessData.name}</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {businessData.category}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
              {businessData.chips.map((chip) => (
                <Chip 
                  key={chip.label}
                  label={chip.label} 
                  color={chip.color} 
                  variant="soft" 
                />
              ))}
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                📍 {businessData.location}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                🕒 {businessData.status.open}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {businessData.status.concept}
              </Typography>
            </Box>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Coming Soon
            </Typography>
            <Box sx={{ pl: 2 }}>
              {COMING_SOON_ITEMS.map((item) => (
                <Typography 
                  key={item} 
                  variant="body2" 
                  color="text.secondary"
                >
                  • {item}
                </Typography>
              ))}
            </Box>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Show Interest
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Be notified when this business:
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button 
                variant="outlined" 
                color="primary" 
                fullWidth
              >
                Opens for Business
              </Button>
              <Button 
                variant="outlined" 
                color="primary" 
                fullWidth
              >
                Adds Menu
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Right Column - Business Status */}
        <Grid item xs={12} md={3}>
          <Paper variant="outlined" sx={{ mb: 2, p: 2 , border: 'none'}}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Business Status
            </Typography>
            <Box>
              {BUSINESS_STATUS_ITEMS.map((status) => (
                <Typography 
                  key={status} 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ my: 1 }}
                >
                  {status}
                </Typography>
              ))}
            </Box>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2 , border: 'none'}}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Coming Features
            </Typography>
            <Box>
              {COMING_FEATURES.map((feature) => (
                <Typography 
                  key={feature} 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ my: 1 }}
                >
                  • {feature}
                </Typography>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewBusinessPage;