import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useGetAllThingsQuery} from '../../redux/thunks/things/thingsget';
import { useGetPreSignedUrlQuery } from '../../redux/thunks/files/filespresignurl';
import { useLazyGetPreSignedUrlDocsQuery } from '../../redux/thunks/files/filespresignurldocs';
import { useEditBusinessMutation } from '../../redux/thunks/sp/editbusiness';
import { spApi } from '../../redux/thunks/sp/sp';






import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Skeleton,
  Chip,
  Autocomplete,
  IconButton,
  Stack,
  Avatar,
  Divider,
  Tooltip
} from '@mui/material';

import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  History as HistoryIcon,
  Preview as PreviewIcon,
  AutoAwesome as AutoAwesomeIcon,
  Lock as LockIcon,
  UploadFile as UploadFileIcon,
  Info as InfoIcon,

} from '@mui/icons-material';

const isPremiumUser = true; // This should come from your auth state


// Mock Data - Replace with API calls later
const MOCK_BUSINESS_DATA = {
  businessName: "Sunrise Cafe & Bakery",
  category: "Restaurant",
  phone: "(555) 123-4567",
  email: "contact@sunrisecafe.com",
  website: "www.sunrisecafe.com",
  description: "Artisanal bakery and coffee shop serving fresh pastries and organic coffee",
  address: "123 Main St, Downtown, Cityville",
  businessHours: "mon_fri",
  additionalInfo: "Early bird special before 9am",
  additionalCategories: [
    { id: 1, name: "Cafe" },
    { id: 2, name: "Bakery" }
  ],
  platforms: [
    { name: "Yelp", link: "yelp.com/sunrisecafe" },
    { name: "Instagram", link: "instagram.com/sunrisecafe" }
  ],
  photo: null,
  specialFeatures: "Outdoor seating, Gluten-free options",
  keywords: "coffee, pastries, breakfast, brunch"
};

const BUSINESS_HOURS_OPTIONS = [
  { value: '24/7', label: 'Open 24/7' },
  { value: 'mon_fri', label: 'Monday-Friday (9 AM - 5 PM)' },
  { value: 'mon_sat', label: 'Monday-Saturday (9 AM - 5 PM)' },
  { value: 'Custom', label: 'Custom' }
];

const TOP_CATEGORIES = [
  { id: 1, name: 'Restaurant' },
  { id: 2, name: 'Retail' },
  { id: 3, name: 'Healthcare' },
  { id: 4, name: 'Technology' },
  { id: 5, name: 'Automotive' },
  { id: 6, name: 'Legal' },
  { id: 7, name: 'Other Professional Services' },
];

// Progress Tracker Component
const EditProgress = ({ currentStep, isLoading }) => {
  const steps = ['Basic Information', 'Location & Hours', 'Categories & Services'];
  
  if (isLoading) {
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        {steps.map((_, i) => (
          <Skeleton key={i} variant="rectangular" height={50} sx={{ my: 1 }} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Edit Progress
      </Typography>
      <Box sx={{ mt: 2 }}>
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              mb: 1,
              bgcolor: currentStep === index ? 'primary.light' : 'grey.50',
              borderRadius: 1,
              color: currentStep === index ? 'primary.main' : 'text.secondary',
            }}
          >
            <Typography variant="body2">
              {index + 1}. {step}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

// Change History Component
const ChangeHistory = ({ isLoading }) => {
  const changes = [
    { field: 'Business Hours', date: '2024-01-10', time: '14:30' },
    { field: 'Description', date: '2024-01-09', time: '11:15' },
    { field: 'Categories', date: '2024-01-08', time: '16:45' }
  ];

  if (isLoading) {
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        {[1, 2, 3].map((i) => (
          <Skeleton key={i} variant="rectangular" height={60} sx={{ my: 1 }} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Recent Changes
      </Typography>
      <Stack spacing={2}>
        {changes.map((change, index) => (
          <Box key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <HistoryIcon color="action" fontSize="small" />
              <Typography variant="body2" fontWeight="medium">
                {change.field} Updated
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary">
              {change.date} at {change.time}
            </Typography>
            {index < changes.length - 1 && <Divider sx={{ mt: 1 }} />}
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

// Preview Component
const BusinessPreview = ({ formData, isLoading }) => {
  if (isLoading) {
    return (
      <Paper sx={{ p: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        <Skeleton variant="circular" width={80} height={80} sx={{ my: 2 }} />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Preview
      </Typography>
      <Box sx={{ textAlign: 'center', my: 3 }}>
        <Avatar
          sx={{
            width: 80,
            height: 80,
            mx: 'auto',
            mb: 2,
            bgcolor: 'primary.light',
          }}
        >
          {formData.businessName?.substring(0, 2).toUpperCase()}
        </Avatar>
        <Typography variant="h6">
          {formData.businessName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formData.category}
        </Typography>
        <Box sx={{ mt: 2 }}>
          {formData.additionalCategories?.map((category) => (
            <Chip
              key={category.id}
              label={category.name}
              size="small"
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

// Main Edit Page Component
const EditBusinessPage = () => {


  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { businessId } = useParams();
  const location = useLocation();
  const businessDetails = location.state;

  const token = useSelector((state) => state.auth?.token || null);
  const userId = useSelector((state) => state.auth?.userId || null);
  const spId = useSelector((state) => state.auth?.user?.spId);  

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(businessDetails);
  const [errors, setErrors] = useState({});

  

  console.log('businessDetails', businessDetails)

  const argsPreSignedUrl = {
    eventType: 'BusinessProfilePhotos',
    businessId
  }
  
  const { data: presignedUrlData, isLoading: isPresignedUrlLoading } = useGetPreSignedUrlQuery(argsPreSignedUrl);

  console.log('presignedUrlData', presignedUrlData)

  const [getPreSignedDocs, { data: resultsPreSignedUrlDocs, isLoading: preSignedUrlDocsLoading }] = useLazyGetPreSignedUrlDocsQuery();

    const [postEdit, { isLoading: isEditBusinessLoading }] = useEditBusinessMutation();
  
  


  const args = {
    spId: spId,
    authType: 'token',
    token: token
  }


  const { data: categoriesData, isLoading: iscategoriesDataLoading } = useGetAllThingsQuery(args);

  const uploadPhotoToS3 = async (file, presignedUrl) => {
    console.log('file', file)
    try {
      console.log('presignedUrl before upload', presignedUrl)
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-eventtype': 'BusinessProfilePhotos'
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload photo');
      }
  
      return true;
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error;
    }
  };

  const uploadDocumentToS3 = async (file, presignedUrl) => {
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-eventtype': 'BusinessDocuments',
          'x-amz-meta-documenttype': file.name.split('.').pop().toLowerCase()
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload document');
      }
  
      return true;
    } catch (error) {
      console.error('Error uploading document:', error);
      throw error;
    }
  };
  

  const handleDocumentChange = (event) => {
    const files = Array.from(event.target.files);
    
    // Validate files
    const validFiles = files.filter(file => {
      const isValidSize = file.size <= 10 * 1024 * 1024; // 10MB limit
      const isValidType = /\.(pdf|doc|docx|json|txt)$/i.test(file.name);
      return isValidSize && isValidType;
    });
  
    // Just store the files in state, don't upload yet
    setFormData(prev => ({
      ...prev,
      pendingDocuments: [...(prev.pendingDocuments || []), ...validFiles.map(file => ({
        file,
        name: file.name,
        type: file.type
      }))]
    }));
  };
  
  const handleDocumentDelete = (index) => {
    setFormData(prev => ({
      ...prev,
      documents: prev.documents.filter((_, i) => i !== index)
    }));
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    
    setFormData((prev) => {
      // Special handling for category field
      if (name === 'category') {
        return {
          ...prev,
          category: value  // Always store as string for new format
        };
      }
      
      // Default handling for other fields
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      
      // Handle photo upload if there's a new photo
      let photoUrl = formData.photoUrl;
      if (formData.photo && presignedUrlData?.url) {
        await uploadPhotoToS3(formData.photo, presignedUrlData.url);
        photoUrl = presignedUrlData.url.split('?')[0];
      }
  
      // Handle document uploads
      const uploadedDocuments = [];
      if (formData.pendingDocuments?.length) {
        for (const docInfo of formData.pendingDocuments) {
          try {
            // Get presigned URL for each document
            console.log('docInfo', docInfo)
            const presignedUrlResponse = await getPreSignedDocs({
              eventType: 'BusinessDocuments',
              businessId: businessId,
              documentType: docInfo.file.name.split('.').pop().toLowerCase()
            }).unwrap();
            console.log('presignedUrlResponse', presignedUrlResponse)
  
            if (presignedUrlResponse?.url) {
              // Upload document
              await uploadDocumentToS3(docInfo.file, presignedUrlResponse.url);
              
              uploadedDocuments.push({
                name: docInfo.name,
                url: presignedUrlResponse.url.split('?')[0],
                keyPath: presignedUrlResponse.keyPath
              });
            }
          } catch (error) {
            console.error(`Error uploading document ${docInfo.name}:`, error);
            // Handle error appropriately
          }
        }
      }

       // Only include fields that are part of the form
      const updatedData = {
        businessName: formData.businessName,
        category: formData.category,
        phone: formData.phone,
        email: formData.email,
        website: formData.website,
        description: formData.description,
        address: formData.address,
        businessHours: formData.businessHours,
        additionalInfo: formData.additionalInfo,
        additionalCategories: formData.additionalCategories,
        specialFeatures: formData.specialFeatures,
        keywords: formData.keywords
      };
      // Only add photoUrl if it was changed
      if (photoUrl !== formData.originalPhotoUrl) {
        updatedData.photoUrl = photoUrl;
      }

          // Only add documents if there are new ones
      if (uploadedDocuments.length > 0) {
        updatedData.documents = [...(formData.documents || []), ...uploadedDocuments];
      }
    
  
  
      // Your API call to save changes would go here
      console.log('Saving changes:', updatedData);

      const auth = {
        token: token,
        authType: 'token',
      };
     
  
      const payload = {
        auth,
        spId,
        data: updatedData,
        businessId: businessId
        
      };

       await postEdit({ payload });
       dispatch(
         spApi.util.invalidateTags([
            { type: 'businessDetails', id: businessId },
         ])
       );
      
      navigate(`/business/${businessId}/${formData.businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`);
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        photo: file
      }));
    }
  };

  const handleCategoryChange = (_, value) => {
    handleChange({
      target: {
        name: 'additionalCategories',
        value: value
      }
    });
};

  const handleCancel = () => {
    //navigate(`/business/${businessId}`);
  };

  const handleNext = () => {
    setCurrentStep((prev) => Math.min(prev + 1, 2));
  };

  const handleBack = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const renderEditForm = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Business Name"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Primary Category"
                  name="category"
                  value={Array.isArray(formData.category) ? formData.category[0] : formData.category}
                  onChange={handleChange}
                  required
                >
                  {TOP_CATEGORIES.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  value={formData.phone || formData.telephone || formData.businessPhone || ''}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email || formData.spEmail || formData.businessEmail || ''}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Website"
                  name="website"
                  value={formData.website || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Business Description"
                  name="description"
                  value={formData.description || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Business Photo
                  </Typography>
                  {formData.photoUrl && (
                    <Box sx={{ mb: 2 }}>
                      <img 
                        src={formData.photoUrl} 
                        alt="Current business photo" 
                        style={{ 
                          maxWidth: '200px', 
                          maxHeight: '200px', 
                          objectFit: 'cover',
                          borderRadius: '4px'
                        }} 
                      />
                    </Box>
                  )}
                </Box>
                <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  sx={{ height: 100 }}
                >
                  {formData.photo ? 'Change Photo' : (formData.photoUrl ? 'Update Photo' : 'Upload Photo')}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Button>
                {formData.photo && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected: {formData.photo.name}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Business Operations & AI Assistant
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Enhance your business profile with detailed operations information and AI-powered assistance
            </Typography>
      
            <Grid container spacing={3}>
              {/* Location Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Location Details
                </Typography>
                <PlacesAutocomplete
                  value={formData.address}
                  onChange={(address) => handleChange({
                    target: { name: 'address', value: address }
                  })}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Box>
                      <TextField
                        {...getInputProps({
                          label: 'Business Address',
                          fullWidth: true,
                          required: true
                        })}
                      />
                      {suggestions.length > 0 && (
                        <Paper sx={{ mt: 1 }}>
                          {loading && <CircularProgress />}
                          {suggestions.map((suggestion) => (
                            <MenuItem
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                            >
                              {suggestion.description}
                            </MenuItem>
                          ))}
                        </Paper>
                      )}
                    </Box>
                  )}
                </PlacesAutocomplete>
              </Grid>
      
              {/* Business Hours */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Operating Hours
                </Typography>
                <TextField
                  select
                  fullWidth
                  label="Business Hours"
                  name="businessHours"
                  value={formData.businessHours}
                  onChange={handleChange}
                >
                  {BUSINESS_HOURS_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
      
              {/* Document Upload Section */}
              <Grid item xs={12}>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: 3, 
                    mt: 2, 
                    border: '1px dashed',
                    borderColor: isPremiumUser ? 'primary.main' : 'grey.400',
                    bgcolor: 'background.default'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <AutoAwesomeIcon color="primary" />
                    <Typography variant="h6">
                      AI Business Analysis
                      {!isPremiumUser && (
                        <Tooltip title="Premium feature">
                          <LockIcon 
                            sx={{ 
                              ml: 1, 
                              fontSize: '0.9em', 
                              verticalAlign: 'middle',
                              color: 'warning.main'
                            }} 
                          />
                        </Tooltip>
                      )}
                    </Typography>
                  </Box>
      
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Upload business documents (menus, price lists, service catalogs, etc.) 
                    for AI-powered analysis and enhanced customer assistance.
                  </Typography>
      
                  <Box sx={{ mb: 2 }}>
                    {formData.documents?.map((doc, index) => (
                      <Chip
                        key={index}
                        label={doc.name}
                        onDelete={() => handleDocumentDelete(index)}
                        sx={{ mr: 1, mb: 1 }}
                      />
                    ))}
                    {formData.pendingDocuments?.map((doc, index) => (
                      <Chip
                        key={`pending-${index}`}
                        label={`${doc.name} (Pending)`}
                        onDelete={() => handleDocumentDelete('pending', index)}
                        sx={{ mr: 1, mb: 1, bgcolor: 'action.hover' }}
                      />
                    ))}
                  </Box>
      
                  <Button
                    variant="outlined"
                    component="label"
                    fullWidth
                    startIcon={<UploadFileIcon />}
                    disabled={!isPremiumUser || preSignedUrlDocsLoading}
                    sx={{ 
                      height: 100,
                      borderStyle: 'dashed',
                      opacity: isPremiumUser ? 1 : 0.7
                    }}
                  >
                    {preSignedUrlDocsLoading ? (
                      <CircularProgress size={24} />
                    ) : isPremiumUser ? (
                      'Upload Business Documents'
                    ) : (
                      'Upgrade to Premium to Upload Documents'
                    )}
                    <input
                      type="file"
                      hidden
                      accept=".pdf,.doc,.docx,.json,.txt"
                      onChange={handleDocumentChange}
                      disabled={!isPremiumUser || preSignedUrlDocsLoading}
                      multiple
                    />
                  </Button>
                  {preSignedUrlDocsLoading && (
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                      Uploading documents...
                    </Typography>
                  )}
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                    Supported formats: PDF, DOC, DOCX, JSON, TXT (Max 10MB per file)
                  </Typography>
                </Paper>
              </Grid>
      
              {/* Additional Information with AI Context */}
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    Additional Information
                    <Tooltip title="This information will be used by our AI to better assist your customers">
                      <InfoIcon sx={{ fontSize: '0.9em', color: 'primary.main' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleChange}
                    placeholder="Include special conditions, seasonal changes, or any other relevant information about your business operations. Our AI will use this to provide better assistance to your customers."
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        );

      case 2:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Categories & Services
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={categoriesData.data}
                  value={formData.additionalCategories || []}
                  getOptionLabel={(option) => (option ? option.name || '' : '')}
                  onChange={handleCategoryChange}
                  renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search and select categories"
                        helperText="You can select multiple categories"
                        fullWidth
                    />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                      const { key, ...chipProps } = getTagProps({ index });
                      return (
                          <Chip
                              key={key}
                              {...chipProps}
                              label={option?.name}
                              color="primary"
                              variant="outlined"
                          />
                      );
                  })
              }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Special Features"
                  name="specialFeatures"
                  value={formData.specialFeatures}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Keywords/Tags"
                  name="keywords"
                  value={formData.keywords}
                  onChange={handleChange}
                  helperText="Separate keywords with commas"
                />
              </Grid>
            </Grid>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 2, mb: 3 }}>
        <Container maxWidth="xl">
          <Typography variant="h5">
            Edit Business Details
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <EditProgress currentStep={currentStep} isLoading={isLoading} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
              {renderEditForm()}
              
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Box>
                  <Button
                    onClick={handleBack}
                    disabled={currentStep === 0 || isLoading}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  {currentStep < 2 ? (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={isLoading}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={handleSave}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
                    </Button>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            <ChangeHistory isLoading={isLoading} />
            <BusinessPreview formData={formData} isLoading={isLoading} />
            
            {/* Tips Section */}
            <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
                Tips
              </Typography>
              <Box sx={{ mt: 2 }}>
                {currentStep === 0 && (
                  <>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Keep business name consistent with official documents
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Choose the most relevant primary category
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Provide active contact information
                    </Typography>
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Enter complete street address
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Update business hours if they've changed
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Include holiday hours in additional information
                    </Typography>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Select all relevant additional categories
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Highlight unique features and services
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      • Use specific keywords to improve visibility
                    </Typography>
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EditBusinessPage;