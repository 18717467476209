import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';


import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Rating,
  List,
  ListItem,
  ListItemText,
  Chip,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  LinearProgress,
  Paper,
  Divider,
  IconButton,
  Badge,
  Skeleton,
  ListItemButton
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import { useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useSpReviewLikeMutation } from '../../redux/thunks/sp/spreviewlike';
import { useGetSpReviewQuery } from '../../redux/thunks/sp/spreviewget';
import { useGetReviewsByBusinessIdQuery } from '../../redux/thunks/unauth/getreviewsbybusinessid'


// Skeleton Components
const LeftColumnSkeleton = () => (
  <Grid item xs={12} md={3}>
    {/* Filters Skeleton */}
    <Paper sx={{ p: 2, mb: 2 }}>
      <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={56} sx={{ mb: 3 }} />
      
      <Skeleton variant="text" width="40%" height={24} sx={{ mb: 1 }} />
      {[1, 2, 3, 4, 5].map((item) => (
        <Box key={item} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Skeleton variant="rectangular" width={120} height={24} />
          <Skeleton variant="text" width={30} height={24} sx={{ ml: 1 }} />
        </Box>
      ))}
      
      <Skeleton variant="text" width="40%" height={24} sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {[1, 2, 3, 4].map((item) => (
          <Skeleton key={item} variant="rounded" width={80} height={32} />
        ))}
      </Box>
    </Paper>

    {/* Similar Businesses Skeleton */}
    <Paper sx={{ p: 2, mb: 2 }}>
      <Skeleton variant="text" width="80%" height={32} sx={{ mb: 2 }} />
      {[1, 2, 3].map((item) => (
        <Box key={item} sx={{ mb: 2 }}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
      ))}
    </Paper>
  </Grid>
);

const MiddleColumnSkeleton = () => (
  <Grid item xs={12} md={6}>
    {/* Reviews Header Skeleton */}
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Skeleton variant="text" width={200} height={32} />
        <Skeleton variant="rectangular" width={150} height={40} />
      </Box>
    </Paper>

    {/* Reviews List Skeleton */}
    {[1, 2, 3, 4].map((item) => (
      <Paper key={item} sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="text" width={100} height={20} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="rectangular" width={120} height={24} />
          </Box>
        </Box>
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Skeleton variant="text" width={60} height={20} />
          <Skeleton variant="rounded" width={80} height={24} />
        </Box>
        
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
      </Paper>
    ))}

    {/* Pagination Skeleton */}
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
      <Skeleton variant="rectangular" width={300} height={36} />
    </Box>
  </Grid>
);

const RightColumnSkeleton = () => (
  <Grid item xs={12} md={3}>
    {/* Stats Skeleton */}
    <Paper sx={{ p: 2, mb: 2 }}>
      <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
      
      {[1, 2, 3, 4, 5].map((item) => (
        <Box key={item} sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Skeleton variant="rectangular" width={100} height={24} />
            <Skeleton variant="text" width={30} height={24} />
          </Box>
          <Skeleton variant="rectangular" width="100%" height={4} />
        </Box>
      ))}

      <Skeleton variant="text" width="60%" height={32} sx={{ mt: 3, mb: 2 }} />
      {[1, 2, 3].map((item) => (
        <Box key={item} sx={{ mb: 2 }}>
          <Skeleton variant="text" width="100%" height={24} />
          <Skeleton variant="text" width="60%" height={20} />
        </Box>
      ))}
    </Paper>
  </Grid>
);


//make it a seperate page, since its being re used

const ReviewInteractionSection = ({ review }) => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const token = useSelector((state) => state.auth?.token || null);
  const reviewLiked = useSelector((state) => state.auth?.user?.reviewsLiked || [], shallowEqual);
  const reviewDisliked = useSelector((state) => state.auth?.user?.reviewsDisliked || [], shallowEqual);

  const comments = review.comments || [];

  const [thumbsUpPressed, setThumbsUpPressed] = useState(reviewLiked.includes(review._id));
  const [thumbsDownPressed, setThumbsDownPressed] = useState(reviewDisliked.includes(review._id));
  const [thisToken, setThisToken] = useState(null);

  const [likeReview, { isLoading: likeDislikeReviewLoading }] = useSpReviewLikeMutation();

  console.log('review', review)

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setThisToken(token);
    }
  }, [authStatus, token, reviewLiked]);


  const handleCommentOnReview = () => {
    navigate(`/review/comments/${review._id}`, { state: review });

  };

  const handleLikeAction = async (actionType) => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    }
    const payload = {
      auth: {
        token: token,
        authType: 'token'
      },
      reviewId: review._id,
      action: actionType
    };

    if (actionType === 'like') {
      setThumbsUpPressed(true);
      setThumbsDownPressed(false);
    } else if (actionType === 'dislike') {
      setThumbsDownPressed(true);
      setThumbsUpPressed(false);
    } else if (actionType === 'unlike') {
      setThumbsUpPressed(false);
    } else if (actionType === 'undislike') {
      setThumbsDownPressed(false);
    }

    await likeReview({ payload });
  };

  const handleThumbsUpPress = () => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    }
    if (thumbsUpPressed) {
      handleLikeAction('unlike');
    } else {
      handleLikeAction('like');
      if (thumbsDownPressed) {
        handleLikeAction('undislike');
      }
    }
  };

  const handleThumbsDownPress = () => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    }
    if (thumbsDownPressed) {
      handleLikeAction('undislike');
    } else {
      handleLikeAction('dislike');
      if (thumbsUpPressed) {
        handleLikeAction('unlike');
      }
    }
  };

  return (
    <>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton 
            color={thumbsUpPressed ? 'primary' : 'default'} aria-label="Thumb Up" onClick={handleThumbsUpPress}
          >
            <ThumbUpIcon />
            
          </IconButton>
          
          <IconButton 
            color={thumbsDownPressed ? 'error' : 'default'} aria-label="Thumb Down" onClick={handleThumbsDownPress}
          >
            <ThumbDownIcon />
          </IconButton>
          
          <IconButton
            onClick={handleCommentOnReview}
        
          >
            <Badge badgeContent={comments.length} color="error">
            <CommentIcon />
            </Badge>
           
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

// Mock Data
const MOCK_REVIEWS = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  userDetails: {
    displayName: `User ${index + 1}`,
    avatar: `/api/placeholder/40/40`
  },
  rating: (Math.floor(Math.random() * 2) + 4), // 4 or 5 stars
  review: index % 3 === 0 
    ? "Amazing coffee and pastries! The atmosphere is cozy, and the staff is incredibly friendly. A perfect spot for morning breakfast or afternoon relaxation."
    : index % 3 === 1
    ? "Great local cafe with delicious baked goods. The sourdough bread is exceptional. Slightly crowded during peak hours."
    : "Best coffee in town! Their new seasonal menu is outstanding. Love the outdoor seating area and the weekend brunch specials.",
  created: new Date(2024, 0, index + 1).toISOString(),
  likes: Array(Math.floor(Math.random() * 20)),
  comments: Array(Math.floor(Math.random() * 5)),
  verified: Math.random() > 0.2
}));

//It should depends on category

const EXPERIENCE_TAGS = [
  'Coffee',
  'Pastries',
  'Atmosphere',
  'Service',
  'Value',
  'Cleanliness'
];

const SORT_OPTIONS = [
  { value: 'date', label: 'Most Recent' },
  { value: 'rating', label: 'Highest Rated' },
  { value: 'likes', label: 'Most Helpful' }
];

const ITEMS_PER_PAGE = 5;

export default function ReviewsPage() {

  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const token = useSelector((state) => state.auth?.token || null);
  const { businessId: urlBusinessId } = useParams();

  // State
  const [businessId, setBusinessId] = useState(urlBusinessId);  
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [sortBy, setSortBy] = useState('date');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [thisToken, setThisToken] = useState(null);



  console.log('businessId', businessId)


  const args = {
    authType: 'none',
    token: token,
    businessId: businessId
  }
  const { data: reviews = [], error: reviewError, isLoading: reviewLoading } = useGetReviewsByBusinessIdQuery(args);
  console.log('reviewLoading', reviewLoading)

  useEffect(() => {
    // This effect runs when businessId state changes
    if (reviews) {
      // Fetch or filter reviews for this businessId
      console.log('Loading reviews ', reviews);
      
    
      
    }
  }, [reviews]);

  useEffect(() => {
    setLoading(reviewLoading );
  
  }, [reviewLoading]);


  useEffect(() => {
    // Update businessId state when URL parameter changes
    if (urlBusinessId !== businessId) {
      setBusinessId(urlBusinessId);
    }
  }, [urlBusinessId]);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setThisToken(token);
    }
  }, [authStatus, token]);

  

  // Calculate rating distribution
  const ratingDistribution = useMemo(() => {
    if (!reviews || reviews.length === 0) return {}; // Return an empty object if no reviews
    return reviews.reduce((acc, review) => {
      acc[review.rating] = (acc[review.rating] || 0) + 1;
      return acc;
    }, {});
  }, [reviews]);

  // Filter and sort reviews
  const filteredAndSortedReviews = useMemo(() => {
    let result = [...reviews];

    // Apply search filter
    if (searchQuery) {
      result = result.filter(review => 
        review.review.toLowerCase().includes(searchQuery.toLowerCase()) ||
        review.author.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply rating filter
    if (selectedRating > 0) {
      result = result.filter(review => review.rating === selectedRating);
    }

    // Apply tag filters
    if (selectedTags.length > 0) {
      result = result.filter(review => 
        selectedTags.every(tag => review.tags.includes(tag))
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      switch (sortBy) {
        case 'rating':
          return b.rating - a.rating;
        case 'likes':
          return b.likes - a.likes;
        case 'date':
        default:
          return b.date - a.date;
      }
    });

    return result;
  }, [reviews, searchQuery, selectedRating, selectedTags, sortBy]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredAndSortedReviews.length / ITEMS_PER_PAGE);
  const paginatedReviews = filteredAndSortedReviews.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    setTimeout(() => setLoading(false), 500);
  };

  console.log('loading', loading)

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Grid container spacing={3}>
          <LeftColumnSkeleton />
          <MiddleColumnSkeleton />
          <RightColumnSkeleton />
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        {/* Left Column - Filters */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Filter Reviews
              </Typography>

              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search reviews..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ mb: 3 }}
              />

              <Typography variant="subtitle2" gutterBottom>
                Filter by Rating
              </Typography>
              <List>
                {[5, 4, 3, 2, 1].map((rating) => (
                  <ListItemButton
                    key={rating}
                    selected={selectedRating === rating}
                    onClick={() => setSelectedRating(rating === selectedRating ? 0 : rating)}
                  >
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center">
                          <Rating value={rating} readOnly size="small" />
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            ({ratingDistribution[rating] || 0})
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>

              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Experience Tags
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {EXPERIENCE_TAGS.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    clickable
                    color={selectedTags.includes(tag) ? "primary" : "default"}
                    onClick={() => {
                      setSelectedTags(prev =>
                        prev.includes(tag)
                          ? prev.filter(t => t !== tag)
                          : [...prev, tag]
                      );
                    }}
                  />
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Middle Column - Reviews */}
        <Grid item xs={12} md={6}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">
                  Customer Reviews ({filteredAndSortedReviews.length})
                </Typography>
                <FormControl size="small" sx={{ minWidth: 150 }}>
                  <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    {SORT_OPTIONS.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Card>

          {paginatedReviews.map((review) => (
              <Paper
                key={review.id}
                variant="outlined"
                sx={{ p: 2, mb: 2, border: 'none' }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="subtitle1">{review.userDetails.displayName}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(review.created).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Rating
                      value={Math.round(review.rating * 10) / 10}
                      precision={0.1}
                      readOnly
                      size="small"
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {Math.round(review.rating * 10) / 10}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {review.review}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    {review.likes.length} Likes
                  </Typography>
                  {review.verified && (
                    <Chip
                      label="Verified"
                      color="success"
                      size="small"
                      variant="outlined"
                    />
                  )}
                </Box>
                <ReviewInteractionSection
                  review={review}
                 
                />
              </Paper>
            ))}

          {totalPages > 1 && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </Grid>

        {/* Right Column - Statistics */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Review Statistics
              </Typography>

              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Rating Distribution
              </Typography>
              {[5, 4, 3, 2, 1].map((rating) => (
                <Box key={rating} sx={{ mb: 1 }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Rating value={rating} readOnly size="small" />
                    <Typography variant="body2">
                      {ratingDistribution[rating] || 0}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={((ratingDistribution[rating] || 0) / reviews.length) * 100}
                    sx={{ mt: 0.5 }}
                  />
                </Box>
              ))}

              <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
                Review Metrics
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Average Rating"
                    secondary={
                      (reviews.reduce((acc, review) => acc + review.rating, 0) / 
                      reviews.length).toFixed(1)
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Total Reviews"
                    secondary={reviews.length}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Verified Reviews"
                    secondary={`${reviews.filter(r => r.verified).length} (${
                      Math.round((reviews.filter(r => r.verified).length / reviews.length) * 100)
                    }%)`}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}