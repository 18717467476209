
import {unauthApi} from './unauth'


export const getReviewsByBusinessIdApi = unauthApi.injectEndpoints({
  endpoints: (builder) => ({
    getReviewsByBusinessId: builder.query({
      
      query: ({businessId, authType, token}) => `/v2/sp/business/${businessId}/reviews?authType=${authType}&&token=${token}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetReviewsByBusinessIdQuery, usePrefetch } = getReviewsByBusinessIdApi