import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  Button,
  Tooltip,
  Chip,
  Box
} from '@mui/material';
import {
  Star,
  PersonAdd as AddPersonIcon,
  CheckCircle as ConnectedIcon,
  Update as ComingSoonIcon,
  Handshake as NetworkIcon,
  PendingActions as PendingIcon,
  HourglassEmpty as WaitingIcon,
} from '@mui/icons-material';
import { useAddTrustedUserMutation  } from '../../redux/thunks/users/useraddtrusteduser';


const NetworkCard = ({ userData }) => {
  
  const thisUserId = useSelector((state) => state.auth?.userId || null);
  const [connectionStatus, setConnectionStatus] = useState('NOT_CONNECTED');
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  useEffect(() => {
    // Check if this is the logged-in user's profile
    if (thisUserId === userData._id) {
      setIsLoggedInUser(true);
      return;
    }

    // Check if user is in first-degree connections
    const isConnected = userData.myFirstDegreeFriend?.some(
      friend => friend.userId === thisUserId
    );
    if (isConnected) {
      setConnectionStatus('CONNECTED');
      return;
    }

    // Check if there's a pending outgoing request
    const isPendingOut = userData.pendingOutTrustedUsers?.some(
      request => request.trustedUserId === thisUserId
    );
    if (isPendingOut) {
      setConnectionStatus('PENDING');
      return;
    }

    // Check if there's a pending incoming request
    const isPendingIn = userData.pendingInTrustedUsers?.some(
      request => request.trustedUserId === thisUserId
    );
    if (isPendingIn) {
      setConnectionStatus('PENDING');
      return;
    }

    // If none of the above, user is not connected
    setConnectionStatus('NOT_CONNECTED');
  }, [thisUserId, userData]);
    
  const stats = {
    connected: userData.firstDegreeFriends?.length || 0,
    referred: userData.myTrustedThings?.length || 0
  }

  
  const [addTrustedUser, { isLoading }] = useAddTrustedUserMutation();

  const handleConnectPress = async () => {
    try {
      const payload = { 'trustedUserId': userData._id, requestType: 'new'   }
      setConnectionStatus('PENDING');
      await addTrustedUser({payload, userId: userData._id});
      

      //navigate to user profile
    } catch (error) {
      console.error('Failed to add trusted user:', error);
    }
  };
  
  
  const renderActionButtons = () => {
    // If viewing own profile
    if (isLoggedInUser) {
      return (
        <Chip
          icon={<Star />}
          label="Your Profile"
          color="primary"
          variant="outlined"
          sx={{ width: '100%' }}
        />
      );
    }
    
    // Switch between different connection states
    switch (connectionStatus) {
      case 'CONNECTED':
        return (
          <Stack spacing={2}>
            <Box sx={{ 
              p: 1.5, 
              bgcolor: 'success.light', 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <ConnectedIcon sx={{ color: 'success.dark' }} />
              <Typography variant="body2" color="success.dark">
                In Your Network
              </Typography>
            </Box>
            <Tooltip title="Coming Soon">
              <span>
                <Button 
                  variant="outlined" 
                  fullWidth
                  startIcon={<ComingSoonIcon />}
                  disabled
                >
                  Follow
                </Button>
              </span>
            </Tooltip>
          </Stack>
        );
        
      case 'PENDING':
        return (
          <Stack spacing={2}>
            <Box sx={{ 
              p: 1.5, 
              bgcolor: 'warning.light', 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <PendingIcon sx={{ color: 'warning.dark' }} />
              <Typography variant="body2" color="warning.dark">
                Connection Request Pending
              </Typography>
            </Box>
            <Button 
              variant="outlined" 
              color="warning"
              fullWidth
              startIcon={<WaitingIcon />}
              disabled
            >
              Awaiting Response
            </Button>
          </Stack>
        );
        
      case 'NOT_CONNECTED':
      default:
        return (
          <Stack spacing={2}>
            <Button 
              variant="contained" 
              fullWidth 
              startIcon={<AddPersonIcon />}
              onClick={handleConnectPress}
              disabled={isLoading}
            >
              Add to Network
            </Button>
            <Tooltip title="Coming Soon">
              <span>
                <Button 
                  variant="outlined" 
                  fullWidth
                  startIcon={<ComingSoonIcon />}
                  disabled
                >
                  Follow
                </Button>
              </span>
            </Tooltip>
          </Stack>
        );
    }
  };

  return (
    <Card variant="outlined" sx={{ border: 'none', mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1 
        }}>
          <NetworkIcon />
          Network
        </Typography>
        <List disablePadding>
          <ListItem disablePadding sx={{ mb: 2 }}>
            <ListItemText 
              primary="Connected"
              secondary={stats.connected}
            />
            <ListItemText 
              primary="Referred"
              secondary={stats.referred}
              sx={{ textAlign: 'right' }}
            />
          </ListItem>
        </List>
        {renderActionButtons()}
      </CardContent>
    </Card>
  );
};

export default NetworkCard;