import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Skeleton,
  CircularProgress,
  Badge
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Add as AddIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { Chart } from 'react-google-charts';
import { useGetUserOutgoingReqQuery } from '../../redux/thunks/users/useroutgoingrequests';
import { useAddTrustedUserMutation  } from '../../redux/thunks/users/useraddtrusteduser';
import { useAddTrustedUserResMutation  } from '../../redux/thunks/users/useraddtrusteduserres';
import { useGetUserIncomingReqQuery } from '../../redux/thunks/users/userincomingrequests';


// Mock Data 
 

const CONNECTION_CATEGORIES = [
  { name: 'Follows', count: 45 },
  { name: 'Referrals', count: 32 },
  { name: 'Reviews', count: 28 },
  { name: 'Views', count: 51 }
];



const INDUSTRY_DISTRIBUTION_DATA = [
  ['Industry', 'Percentage'],
  ['Technology', 35],
  ['Marketing', 25],
  ['Finance', 20],
  ['Other', 20]
];

const GROWTH_TREND_DATA = [
  ['Month', 'Connections'],
  ['Jan', 10],
  ['Feb', 15],
  ['Mar', 20],
  ['Apr', 25],
  ['May', 35]
];

const RECOMMENDED_CONNECTIONS = [
  { id: 1, name: 'Alex Brown', role: 'Product Manager', avatar: '/placeholder.jpg' },
  { id: 2, name: 'Emily Chen', role: 'Sales Director', avatar: '/placeholder.jpg' }
];

// Skeleton Components
const NetworkStatsSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      {[1, 2, 3, 4].map((item) => (
        <Grid item xs={6} key={item}>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="80%" height={24} />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const QuickActionsSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Skeleton variant="text" width="50%" height={32} sx={{ mb: 2 }} />
    {[1, 2, 3].map((item) => (
      <Skeleton 
        key={item}
        variant="rectangular" 
        height={36} 
        sx={{ mb: 2, borderRadius: 1 }}
      />
    ))}
  </Paper>
);

const CategoriesSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    {[1, 2, 3, 4].map((item) => (
      <Box key={item} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="20%" />
      </Box>
    ))}
  </Paper>
);

const ConnectionCardSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton variant="circular" width={50} height={50} />
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="40%" />
      </Box>
      <Box sx={{ width: 100 }}>
        <Skeleton variant="text" width="100%" />
      </Box>
    </Box>
  </Paper>
);

const ChartSkeleton = () => (
  <Paper sx={{ p: 3, mb: 2 }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={300} />
  </Paper>
);

const RecommendedConnectionsSkeleton = () => (
  <Paper sx={{ p: 3 }}>
    <Skeleton variant="text" width="80%" height={32} sx={{ mb: 2 }} />
    {[1, 2].map((item) => (
      <Box
        key={item}
        sx={{
          display: 'flex',
          gap: 2,
          p: 2,
          bgcolor: 'grey.50',
          borderRadius: 1,
          mb: 2
        }}
      >
        <Skeleton variant="circular" width={40} height={40} />
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="40%" />
        </Box>
      </Box>
    ))}
  </Paper>
);

// Sent Request Card Component
const SentRequestCard = ({ request, onResend, onCancel }) => {
  const navigate = useNavigate();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(request.creationTime));
  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }} onClick={() => navigate(`/profile/${request.trustedUserId}/active`)}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar src={request.profileImageUrl} sx={{ width: 50, height: 50 }} />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{request.displayName}</Typography>
          
          <Typography variant="body2" color="text.secondary">
            Sent on {formattedDate}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
          <Button 
            variant="outlined" 
            size="small" 
            onClick={() => onResend(request)}
            sx={{ minWidth: 'auto' }}
          >
            Resend
          </Button>
          <Button 
            variant="outlined" 
            color="error" 
            size="small" 
            onClick={() => onCancel(request)}
            sx={{ minWidth: 'auto' }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Paper>
)};
  
// Received Invite Card Component
const ReceivedInviteCard = ({ invite, onAccept, onReject }) => {

const formattedDate = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "2-digit",
}).format(new Date(invite.creationTime));
  return (
<Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }} onClick={() => navigate(`/profile/${request.trustedUserId}/active`)}>
    <Box sx={{ display: 'flex', gap: 2 }}>
    <Avatar src={invite.profileImageUrl} sx={{ width: 50, height: 50 }} />
    <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle1">{invite.displayName}</Typography>
        <Typography variant="body2" color="text.secondary">
        {invite.role} • {invite.company}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {invite.mutualConnections} mutual connections
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Received on {formattedDate}
        </Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
        <Button 
        variant="contained" 
        color="primary" 
        size="small" 
        onClick={() => onAccept(invite)}
        sx={{ minWidth: 'auto' }}
        >
        Accept
        </Button>
        <Button 
        variant="outlined" 
        color="error" 
        size="small" 
        onClick={() => onReject(invite)}
        sx={{ minWidth: 'auto' }}
        >
        Decline
        </Button>
    </Box>
    </Box>
</Paper>
)};
  
// Skeleton for Request Cards
const RequestCardSkeleton = () => (
<Paper sx={{ p: 2, mb: 2 }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
    <Skeleton variant="circular" width={50} height={50} />
    <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="30%" />
    </Box>
    <Box sx={{ display: 'flex', gap: 1 }}>
        <Skeleton variant="rectangular" width={60} height={30} sx={{ borderRadius: 1 }} />
        <Skeleton variant="rectangular" width={60} height={30} sx={{ borderRadius: 1 }} />
    </Box>
    </Box>
</Paper>
);

// Network Stats Component
const NetworkStatsCard = ({ user, incoming, outgoing }) => {
  console.log('user', user)
  
  
  return (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>

    <Typography variant="h6" gutterBottom>Network Overview</Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{user.myCurrentNetworkList.userDetails.length}</Typography>
        <Typography variant="body2" color="text.secondary">Extended Network</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{incoming.pendingInTrustedUsers.length}</Typography>
        <Typography variant="body2" color="text.secondary">Requests Waiting</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{user.userStats.trustRating}%</Typography>
        <Typography variant="body2" color="text.secondary">Trust Score</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{outgoing.pendingOutTrustedUsers.length}</Typography>
        <Typography variant="body2" color="text.secondary">Pending Requests</Typography>
      </Grid>
    </Grid>
  </Paper>
)};

// Quick Actions Component
const QuickActionsCard = () => {
  const navigate = useNavigate();



  return (
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Quick Actions
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Find Connections */}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/usersearch')}
        >
          Find Connections
        </Button>

        {/* Import Contacts */}
        <Button
          variant="outlined"
          startIcon={<GroupIcon />}
          sx={{
            position: 'relative',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              top: -2,
              right: 8,
              fontSize: '0.7rem',
              color: 'orange',
              fontWeight: 'bold',
            }}
          >
            Coming Soon
          </Typography>
          Import Contacts
        </Button>

        {/* Manage Profile */}
        <Button
          variant="outlined"
          startIcon={<PersonIcon />}
          onClick={() => navigate('/profile')}
        >
          Manage Profile
        </Button>
      </Box>
    </Paper>
  );
};

// Categories Component
const CategoriesCard = ({ categories }) => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Network Stats</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {categories.map((category) => (
        <Box key={category.name} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">{category.name}</Typography>
          <Typography variant="body2" color="text.secondary">({category.count})</Typography>
        </Box>
      ))}
    </Box>
  </Paper>
);

// Connection Card Component
const ConnectionCard = ({ connection }) => {
    const navigate = useNavigate();

    const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(new Date(connection.creationTime));
    
    
   return (
  <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }} onClick={() => navigate(`/profile/${connection.userId}/active`)}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Avatar src={connection.profileImageUrl} sx={{ width: 50, height: 50 }} />
      <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle1">{connection.displayName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {connection.role}  {connection.industry}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Connected since {formattedDate}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="body2" color="primary">
          Trust Score: {connection.trustRating}%
        </Typography>
      </Box>
    </Box>
  </Paper>
)};

// Network Insights Component
const NetworkInsightsCard = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Industry Distribution</Typography>
    <Box sx={{ height: 300 }}>
      <Chart
        chartType="PieChart"
        data={INDUSTRY_DISTRIBUTION_DATA}
        options={{
          pieHole: 0.4,
          legend: { position: 'bottom' },
          colors: ['#1976d2', '#64b5f6', '#bbdefb', '#e3f2fd']
        }}
        width="100%"
        height="100%"
      />
    </Box>
  </Paper>
);

// Growth Trends Component
const GrowthTrendsCard = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Growth Trends</Typography>
    <Box sx={{ height: 300 }}>
      <Chart
        chartType="LineChart"
        data={GROWTH_TREND_DATA}
        options={{
          curveType: 'function',
          legend: { position: 'none' },
          colors: ['#1976d2'],
          hAxis: { title: 'Month' },
          vAxis: { title: 'Connections' }
        }}
        width="100%"
        height="100%"
      />
    </Box>
  </Paper>
);

// Recommended Connections Component
const RecommendedConnectionsCard = ({ recommendations }) => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Recommended Connections</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {recommendations.map((rec) => (
        <Box
          key={rec.id}
          sx={{
            display: 'flex',
            gap: 2,
            p: 2,
            bgcolor: 'grey.50',
            borderRadius: 1
          }}
        >
          <Avatar src={rec.avatar} />
          <Box>
            <Typography variant="subtitle2">{rec.name}</Typography>
            <Typography variant="body2" color="text.secondary">{rec.role}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  </Paper>
);

// Main Network Page Component
export default function NetworkPage() {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.userId)  
  const thisUser = useSelector((state) => state.auth?.user || '');


  const [loading, setLoading] = useState(true);
  const [showRequests, setShowRequests] = useState(false); 
  const [pendingInvites, setPendingInvites] = useState([]); // State to hold the search query
  const [sentInvites, setSentInvites] = useState([]); // State to hold the search query


  const [addTrustedUserRes, { isLoading: addTrustedUserResLoading }] = useAddTrustedUserResMutation();
  const { data: incoming, isLoading: incomingLoading, isSuccess: incomingSuccess  } = useGetUserIncomingReqQuery(userId);

  const { data: outgoing, isLoading: outgoingLoading, isSuccess: outgoingSuccess  } = useGetUserOutgoingReqQuery(userId);
  const [addTrustedUser, { isLoading: addTrustedUserLoading  }] = useAddTrustedUserMutation();


  const firstDegrees = useSelector((state) => state.auth.user.firstDegreeFriends);

  useEffect(() => {   
      setLoading(incomingLoading || outgoingLoading ); 
  }, [incomingLoading, outgoingLoading]);

  useEffect(() => {
    if (incomingSuccess) {
      console.log('incoming', incoming)    
      const pendingInvitesDate = incoming.pendingInTrustedUsers.filter((invite) => invite.state === 'notification' ) ;   
      setPendingInvites(pendingInvitesDate)
      
      // Create a new array with only unique ids, used for prefetching these users
      //const incomingIds = incoming.pendingInTrustedUsers.map(item => item.trustedUserId);  
      //const profileIds = [...new Set([...incomingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
  }, [incoming]);

  useEffect(() => {
    if (outgoingSuccess) {
      
      console.log('outgoing', outgoing)    

      const pendingOutvites = outgoing.pendingOutTrustedUsers;   
      setSentInvites(pendingOutvites)

      
      // Create a new array with only unique ids.
      //const outgoingIds = outgoing.pendingOutTrustedUsers.map(item => item.trustedUserId);
      //const profileIds = [...new Set([ ...outgoingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
  }, [outgoing]);



  // Add these handler functions
  const handleResendRequest = async (requestId) => {
    console.log('Resending request:', requestId);
    // Add your resend logic here
    const payload = { 'trustedUserId': invite.trustedUserId, requestType: 'reminder'  }
    await addTrustedUser({payload, userId: invite.trustedUserId});
    
    setSentInvites((prevInvites) => prevInvites.filter((prevInvite) => prevInvite.trustedUserId !== invite.trustedUserId));

    console.log(`Reminder sent from ${invite.displayName}`);
  };

  const handleCancelRequest = async (requestId) => {
    console.log('Canceling request:', requestId);
    // Add your cancel logic here
    const payload = { 'trustedUserId': invite.trustedUserId, requestType: 'withdraw'  }
    await addTrustedUser({payload, userId: invite.trustedUserId});
    
    setSentInvites((prevInvites) => prevInvites.filter((prevInvite) => prevInvite.trustedUserId !== invite.trustedUserId));

    console.log(`Withdrown invitation from ${invite.displayName}`);
  };

  const handleAcceptInvite = async (invite) => {
    console.log('Accepting invite:', invite);
    const payload = { 'trustedUserId': invite.trustedUserId, response: 1, 'userId': userId }
    await addTrustedUserRes(payload);
    setPendingInvites((prevInvites) => prevInvites.filter((prevInvite) => prevInvite.trustedUserId !== invite.trustedUserId));

    console.log(`Accepted invitation from ${invite.displayName}`);
  };

  const handleRejectInvite = (inviteId) => {
    console.log('Rejecting invite:', inviteId);
    // Add your reject logic here
  };

  // Handler function for ignoring an invitation
  const handleIgnoreInvite = (invite) => {
    // Implement your logic here to ignore the invitation
    console.log(`Ignored invitation from ${invite.displayName}`);
  };
  

  

  if (loading) {
    return (
      <Box>
        <Box sx={{ bgcolor: 'primary.main', p: 3, mb: 3 }}>
          <Container maxWidth="xl">
            <Skeleton variant="text" width="300px" height={40} sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          </Container>
        </Box>

        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {/* Left Column Skeleton */}
            <Grid item xs={12} md={3}>
              <NetworkStatsSkeleton />
              <QuickActionsSkeleton />
              <CategoriesSkeleton />
            </Grid>

            {/* Middle Column Skeleton */}
            <Grid item xs={12} md={6}>
              <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 1 }} />
                  <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 1 }} />
                </Box>
              </Paper>
              {[1, 2, 3].map((item) => (
                 <RequestCardSkeleton key={item} />
              ))}
            </Grid>

            {/* Right Column Skeleton */}
            <Grid item xs={12} md={3}>
              <ChartSkeleton />
              <ChartSkeleton />
              <RecommendedConnectionsSkeleton />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }


  return (
    <Box>
      <Box 
        sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          p: 3,
          mb: 3 
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4">My Network</Typography>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <NetworkStatsCard user={thisUser} incoming={incoming} outgoing={outgoing} />
            <QuickActionsCard />
            <CategoriesCard categories={CONNECTION_CATEGORIES} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  select
                  size="small"
                  defaultValue="all"
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem value="all">All Connections</MenuItem>
                  <MenuItem value="recent">Recently Added</MenuItem>
                  <MenuItem value="business">Business Partners</MenuItem>
                </TextField>
                <TextField
                  select
                  size="small"
                  defaultValue="recent"
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem value="recent">Recently Active</MenuItem>
                  <MenuItem value="trust">Trust Score</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                </TextField>
                <Button
                  variant="outlined"
                  onClick={() => setShowRequests(!showRequests)}
                  sx={{ ml: 'auto' }}
                >
                  {showRequests ? 'Show Connections' : 'Show Requests'}
                </Button>
              </Box>
            </Paper>

            {showRequests ? (
              <>
                {/* Sent Requests Section */}
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" gutterBottom sx={{ px: 1 }}>
                    Sent Connection Requests
                  </Typography>
                  {sentInvites.map((request) => (
                    <SentRequestCard
                      key={request.trustedUserId}
                      request={request}
                      onResend={handleResendRequest}
                      onCancel={handleCancelRequest}
                    />
                  ))}
                </Box>

                {/* Received Invites Section */}
                <Box>
                  <Typography variant="h6" gutterBottom sx={{ px: 1 }}>
                    Received Invites
                  </Typography>
                  {pendingInvites.map((invite) => (
                    <ReceivedInviteCard
                      key={invite.trustedUserId}
                      invite={invite}
                      onAccept={handleAcceptInvite}
                      onReject={handleRejectInvite}
                    />
                  ))}
                </Box>
              </>
            ) : (
              firstDegrees.map((connection) => (
                <ConnectionCard key={connection.userId} connection={connection} />
              ))
            )}
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            <NetworkInsightsCard />
            <GrowthTrendsCard />
            <RecommendedConnectionsCard recommendations={RECOMMENDED_CONNECTIONS} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}