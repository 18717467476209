import React from 'react';
import { Box, Chip } from '@mui/material';
import { STATUS_CONFIG, getSecondaryStatuses } from './BusinessStatusConfig';
import { 
  StoreMallDirectory as StoreIcon,
  LocalOffer as OfferIcon,
  Loyalty as LoyaltyIcon
} from '@mui/icons-material';

// Default chips to show when no tags are present
const DEFAULT_CHIPS = [
  {
    id: 'local-business',
    label: 'Local Business',
    icon: StoreIcon,
    color: {
      bg: '#E3F2FD',
      text: '#1565C0',
      contrastText: '#0D47A1'
    }
  },
  {
    id: 'new-member',
    label: 'Revfer Member',
    icon: LoyaltyIcon,
    color: {
      bg: '#E8F5E9',
      text: '#2E7D32',
      contrastText: '#1B5E20'
    }
  },
  {
    id: 'special-deals',
    label: 'Special Deals',
    icon: OfferIcon,
    color: {
      bg: '#FFF3E0',
      text: '#E65100',
      contrastText: '#E65100'
    }
  }
];

const BusinessStatusChips = ({ tags = [], maxChips = 3 }) => {
  const secondaryStatuses = getSecondaryStatuses(tags);
  const chipsToShow = secondaryStatuses.length > 0 
    ? secondaryStatuses.slice(0, maxChips)
    : DEFAULT_CHIPS;

  return (
    <Box sx={{ display: 'flex', gap: 1, my: 2, flexWrap: 'wrap' }}>
      {secondaryStatuses.length > 0 ? (
        // Show status-based chips
        chipsToShow.map((statusKey) => {
          const status = STATUS_CONFIG[statusKey];
          const StatusIcon = status.icon;
          return (
            <Chip
              key={statusKey}
              icon={<StatusIcon />}
              label={status.title}
              variant="filled"
              sx={{
                bgcolor: status.color.bg,
                color: status.color.text,
                '& .MuiChip-icon': {
                  color: status.color.contrastText
                },
                fontWeight: 500,
                '&:hover': {
                  bgcolor: status.color.bg,
                  opacity: 0.9
                }
              }}
            />
          );
        })
      ) : (
        // Show default chips
        DEFAULT_CHIPS.map((chip) => {
          const ChipIcon = chip.icon;
          return (
            <Chip
              key={chip.id}
              icon={<ChipIcon />}
              label={chip.label}
              variant="filled"
              sx={{
                bgcolor: chip.color.bg,
                color: chip.color.text,
                '& .MuiChip-icon': {
                  color: chip.color.contrastText
                },
                fontWeight: 500,
                '&:hover': {
                  bgcolor: chip.color.bg,
                  opacity: 0.9
                }
              }}
            />
          );
        })
      )}
    </Box>
  );
};

export default BusinessStatusChips;