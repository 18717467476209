import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate} from "react-router-dom";

import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Paper,
  Skeleton,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Send as SendIcon, QuestionAnswer as QAIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useGenerateAnswersMutation } from "../../redux/thunks/ai/generateanswers";

const BusinessQASection = ({ isLoading, businessId, thisToken }) => {
  const navigate = useNavigate();
    
  const token = useSelector((state) => state.auth?.token || null);
  const displayName = useSelector((state) => state.auth?.user?.displayName || 'User');

  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [generateAnswer, { isLoading: isAnswerLoading }] = useGenerateAnswersMutation();

  const handleSubmitQuestion = async () => {
    if(!thisToken) {
        // Use navigate to go to the feedback form page and pass props
        navigate('/auth/signin', { state: 'tbd' });
  
      }
    if (!question.trim()) return;
    
    const newQuestion = {
      id: questions.length + 1,
      question: question.trim(),
      timestamp: new Date().toISOString(),
      status: "pending",
      userName: displayName
    };

    try {
      const auth = {
        token: token,
        authType: "token",
      };
      const payload = {
        auth,
        businessId,
        query: question,
      };

      const response = await generateAnswer({ payload }).unwrap();
      newQuestion.answer = response.text;
    } catch (error) {
      console.error("Error generating answer:", error);
    }
    
    setQuestions([...questions, newQuestion]);
    setQuestion('');
  };

  const handleClearQuestions = () => {
    setQuestions([]);
    setOpenDialog(false);
  };

  if (isLoading || isAnswerLoading) {
    return (
      <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
        <Box sx={{ mb: 2 }}>
          <Skeleton variant="text" width={200} height={32} />
          <Skeleton variant="rectangular" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mt: 2 }} />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <QAIcon />
          <Typography variant="h6">Questions & Answers</Typography>
        </Box>
        {questions.length > 0 && (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setOpenDialog(true)}
            size="small"
          >
            Clear All
          </Button>
        )}
      </Box>

      {/* Attribution */}
      <Typography 
        variant="caption" 
        color="text.secondary" 
        sx={{ mb: 2, textAlign: 'left' }}
      >
        Powered by <a href="https://openai.com/chatgpt" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>ChatGPT</a>
      </Typography>

      <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
        <TextField
          fullWidth
          size="small"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a question about this business..."
          sx={{ flex: 1 }}
        />
        <Button 
          variant="contained" 
          onClick={handleSubmitQuestion}
          disabled={!question.trim()}
          startIcon={<SendIcon />}
        >
          Ask
        </Button>
      </Box>

      <Box sx={{ 
        maxHeight: '300px', 
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        }
      }}>
        {questions.map((q) => (
          <Box key={q.id} sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="subtitle2" color="primary">
                {q.userName}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(q.timestamp).toLocaleDateString()}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {q.question}
            </Typography>
            {q.answer ? (
              <Box sx={{ mt: 1, ml: 2, p: 1.5, bgcolor: 'grey.50', borderRadius: 1 }}>
                <Typography variant="body2">{q.answer}</Typography>
              </Box>
            ) : (
              <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                Awaiting response from business owner
              </Typography>
            )}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Clear All Questions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to clear all questions and answers? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleClearQuestions} color="error" variant="contained">
            Clear All
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BusinessQASection;
