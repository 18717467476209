import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';


import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Chip,
  Button,
  Divider,
  Container,
  Stack,
  LinearProgress,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Pagination,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  LocationOn,
  Verified,
  ThumbUp,
  Star,
  Share,
  TrendingUp,
  EmojiEvents,
  WorkspacePremium
} from '@mui/icons-material';
import Activity from './Activity'; // Our Activity component
import { useGetUserProfileQuery } from '../../redux/thunks/users/userprofile';
import { useGetActivitiesQuery } from '../../redux/thunks/users/getactivities';
import UserAchievementBanner from './UserAchievementBanner';
import AchievementsCard from './AchievementsCard';
import NewUserWelcome from './NewUserWelcome'
import NetworkCard from './NetworkCard';


const userAchievements = ['EARLY_ADOPTER', 'MILESTONE_25', 'TOP_CONNECTOR'];



const mockProfile = {
  name: "Sarah Johnson",
  title: "Restaurant Expert",
  location: "San Francisco Bay Area",
  joinDate: "March 2023",
  stats: {
    trustScore: 92,
    reviews: 84,
    references: 156,
    followers: 243,
    following: 198
  },
  expertise: [
    { category: "Restaurants", level: 90, reviews: 45 },
    { category: "Coffee Shops", level: 80, reviews: 28 },
    { category: "Local Services", level: 70, reviews: 11 }
  ],
};








// Loading skeleton components
const ProfileSkeleton = () => (
  <Card variant="outlined" sx={{ border: 'none' }}>
    <CardContent>
      <Stack spacing={2} alignItems="center">
        <Skeleton variant="circular" width={120} height={120} />
        <Skeleton variant="text" width={150} height={30} />
        <Skeleton variant="text" width={100} height={24} />
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" width="100%" height={24} />
          <Skeleton variant="text" width="100%" height={24} />
          <Skeleton variant="text" width="100%" height={24} />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

const ActivitySkeleton = () => (
  <Card variant="outlined" sx={{ border: 'none' }}>
    <CardContent>
      <Skeleton variant="text" width={200} height={32} sx={{ mb: 2 }} />
      <Stack spacing={2}>
        {[1, 2, 3].map((i) => (
          <Box key={i}>
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ borderRadius: 1 }} />
          </Box>
        ))}
      </Stack>
    </CardContent>
  </Card>
);

const StatsSkeleton = () => (
  <Card variant="outlined" sx={{ border: 'none' }}>
    <CardContent>
      <Skeleton variant="text" width={150} height={32} sx={{ mb: 2 }} />
      <Stack spacing={2}>
        <Skeleton variant="circular" width={80} height={80} sx={{ mx: 'auto' }} />
        <Skeleton variant="text" width="100%" height={24} />
        <Skeleton variant="text" width="100%" height={24} />
      </Stack>
    </CardContent>
  </Card>
);

const TrustScoreCard = ({trustScore}) => (
  <Card variant="outlined" sx={{ border: 'none', mb: 3 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Trust Score
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        my: 3 
      }}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={trustScore}
            size={80}
            thickness={4}
            sx={{ color: 'primary.main' }}
          />
          <Box sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Typography variant="h6" color="primary.main">
              {trustScore}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Elite Status
        </Typography>
      </Box>
      <Stack spacing={2}>
        <Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Monthly Impact
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={85} 
            sx={{ height: 8, borderRadius: 4 }}
          />
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

const UserLogo = ({photoUrl, userName}) => {
    
    if (photoUrl) {
      return (
        <Avatar
          
          src={photoUrl}
          alt={`${userName} logo`}
          sx={{ 
            width: 120, 
            height: 120,
            mx: 'auto', 
            mb: 2,
          }}
        />
      );
    }
    
    return (
      <Avatar 
        sx={{ 
          width: 120, 
          height: 120, 
          mx: 'auto', 
          mb: 2,
          bgcolor: 'grey.300'
        }}
      >
        {userName.split(' ').map(n => n[0]).join('')}
      </Avatar>
    );
  };

const ActiveUserProfile = ({  }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = useState(true);
  

    const token = useSelector((state) => state.auth?.token || null);



    const { userId } = useParams(); 
    const navigate = useNavigate();
    console.log('in user profile, userId', userId);

     // Add pagination state
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    const { data: userData, isError, isLoading: isUserLoading, error } = useGetUserProfileQuery(userId);
    console.log('userData', userData);
    console.log('isLoading', isLoading);

    const activityArgs = { userId: userId, activityType: 'all' };
    const { data: userActivity,  isError: isNetActivityError,  isLoading: isActivityLoading,  error: netActivityError,} = useGetActivitiesQuery(activityArgs);

    console.log('userActivity', userActivity);



    useEffect(() => {
      setIsLoading(isUserLoading || isActivityLoading );
    
    }, [isUserLoading, isActivityLoading]);

    // Handle page change
    const handlePageChange = (event, value) => {
      setPage(value);
      window.scrollTo({
        top: document.getElementById('activities-section')?.offsetTop - 20,
        behavior: 'smooth'
      });
    }

    const getPaginatedActivities = () => {
      if (!userActivity) return [];
      // Sort activities by creationDate in descending order (latest first)
      const sortedActivities = [...userActivity].sort((a, b) => 
        new Date(b.creationDate) - new Date(a.creationDate)
      );
  
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return sortedActivities.slice(startIndex, endIndex);
    };

    const totalPages = userActivity ? Math.ceil(userActivity.length / itemsPerPage) : 0;
  
    if (isLoading) {
        return (
          <Box sx={{ bgcolor: 'grey.50', minHeight: '100vh', py: 3 }}>
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={80} sx={{ borderRadius: 1 }} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={3}>
                    <ProfileSkeleton />
                    <ActivitySkeleton />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ActivitySkeleton />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={3}>
                    <StatsSkeleton />
                    <StatsSkeleton />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
        );
      }   

      console.log('isLoading2', isLoading);
  
    return (
      <Box sx={{ bgcolor: 'grey.50', minHeight: '100vh', py: 3 }}>
        {/* Previous banner and left column remain the same... */}
        <UserAchievementBanner achievements={userAchievements} />
  
        <Container maxWidth="lg">
          <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stack spacing={3}>
              {/* Profile Card */}
              <Card variant="outlined" sx={{ border: 'none' }}>
                <CardContent>
                  <Stack spacing={2} alignItems="center">
                    <UserLogo photoUrl={userData.profileImageUrl} userName={userData.displayName}/>
                    <Typography variant="h6">{userData.displayName}</Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {mockProfile.title}
                    </Typography>
                    <Divider flexItem />
                    <Stack spacing={1} width="100%">
                      <Typography variant="body2" color="text.secondary">
                        <LocationOn sx={{ mr: 1, verticalAlign: 'middle' }} />
                        {mockProfile.location}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <Star sx={{ mr: 1, verticalAlign: 'middle' }} />
                        {mockProfile.stats.reviews} Reviews
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <Share sx={{ mr: 1, verticalAlign: 'middle' }} />
                        {mockProfile.stats.references} References
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>

              {/* Expertise Card */}
              <Card variant="outlined" sx={{ border: 'none' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Category Expertise
                  </Typography>
                  <Stack spacing={3}>
                    {mockProfile.expertise.map((exp) => (
                      <Box key={exp.category}>
                        <Typography variant="body2" gutterBottom>
                          {exp.category}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={exp.level}
                            sx={{ flexGrow: 1, height: 8, borderRadius: 4 }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {exp.reviews} reviews
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>

  
            {/* Middle Column - Activity Feed */}
            <Grid item xs={12} md={6} id="activities-section">
              {(!userActivity || userActivity.length <= 1) ? (
                <NewUserWelcome 
                  userName={userData.displayName}
                  joinDate={userData.creationTime}
                />
              ) : (
              <Card variant="outlined" sx={{ border: 'none', mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Recent Activity
                  </Typography>
                  {getPaginatedActivities().map((activity, index) => (
                    <Activity 
                      key={`${activity.id}-${index}`} 
                      activity={activity} 
                    />
                  ))}
                  {totalPages > 1 && (
                    <Box sx={{ 
                      mt: 3, 
                      display: 'flex', 
                      justifyContent: 'center'
                    }}>
                      <Pagination 
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size={isMobile ? "small" : "medium"}
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  )}
                </CardContent>
              </Card>
            )}
          </Grid>
    
              {/* Right Column */}
            <Grid item xs={12} md={3}>
              <TrustScoreCard trustScore={userData.trustRating} />
              <NetworkCard  userData={userData}/>
              <AchievementsCard />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
};
  
export default ActiveUserProfile;