import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { ACHIEVEMENT_CONFIG, DEFAULT_ACHIEVEMENT, getTopAchievements } from './UserStatusConfig';

const UserAchievementBanner = ({ achievements = [] }) => {
  // Get the highest priority achievement (first one from top achievements)
  const topAchievement = getTopAchievements(achievements, 1)[0];
  const config = topAchievement ? ACHIEVEMENT_CONFIG[topAchievement] : DEFAULT_ACHIEVEMENT;
  const Icon = config.icon;

  return (
    <Paper 
      sx={{ 
        bgcolor: config.color.bg,
        color: config.color.text,
        p: 2,
        mb: 2,
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }
      }}
      elevation={0}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1, 
        mb: 0.5 
      }}>
        <Icon sx={{ 
          color: config.color.contrastText,
          fontSize: 28 
        }} />
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 600, 
            color: config.color.contrastText 
          }}
        >
          {config.title}
        </Typography>
      </Box>
      <Typography 
        variant="body2" 
        sx={{ 
          ml: 4,
          opacity: 0.9 
        }}
      >
        {config.description}
      </Typography>
    </Paper>
  );
};

UserAchievementBanner.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.string)
};

export default UserAchievementBanner;