import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { useSelector } from 'react-redux'; // For auth state


import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Avatar,
  Chip,
  Skeleton,
  IconButton,
  InputAdornment,
  Divider,
  Card,
  CardContent,
  Popover,
  MenuList,
  MenuItem,
  CircularProgress
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  ImportContacts as ImportIcon,
  Share as ShareIcon,
  Email as EmailIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { useFindUserMutation } from '../../redux/thunks/users/userfind';
import { useEmailInviteMutation } from '../../redux/thunks/users/useremailinvite'; // Import your RTK Query mutation



// Mock Data
const NETWORK_STATS = {
  totalSize: 256,
  invitesSent: 23,
  successRate: 78,
  availableInvites: 15
};

const TRUST_SCORE_RANGES = [
  { label: 'All Scores', value: 'all' },
  { label: '90+ (Excellent)', value: '90' },
  { label: '80-89 (Very Good)', value: '80' },
  { label: '70-79 (Good)', value: '70' }
];

const GROWTH_TIPS = [
  'Personalize your invitations',
  'Connect with mutual contacts',
  'Build your trust score',
  'Stay active in your network'
];

const DEFAULT_USERS = [
  {
    _id: 1,
    displayName: 'James Wilson',
    role: 'CEO',
    company: 'Innovation Tech',
    mutualConnections: 15,
    trustScore: 95,
    profileImageUrl: '/placeholder.jpg',
    isPopular: true,
    region: 'TX',

  },
  {
    _id: 2,
    displayName: 'Elena Rodriguez',
    role: 'Head of Marketing',
    company: 'Growth Solutions',
    mutualConnections: 10,
    trustScore: 92,
    profileImageUrl: '/placeholder.jpg',
    isPopular: true,
    region: 'MA',
  },
  {
    _id: 3,
    displayName: 'Alex Chang',
    role: 'Senior Developer',
    company: 'Tech Giants Inc',
    mutualConnections: 8,
    trustScore: 88,
    profileImageUrl: '/placeholder.jpg',
    isPopular: false,
    region: 'FL',
  },
  {
    _id: 4,
    displayName: 'Maria Costa',
    role: 'Product Director',
    company: 'Future Systems',
    mutualConnections: 12,
    trustScore: 90,
    profileImageUrl: '/placeholder.jpg',
    isPopular: true,
    region: 'TX',
  }
];

const SEARCH_RESULTS = [
  {
    _id: 1,
    displayName: 'Sarah Johnson',
    role: 'Product Manager',
    company: 'Tech Co',
    mutualConnections: 12,
    trustScore: 92,
    profileImageUrl: '/placeholder.jpg',
    region: 'MA',

  },
  {
    _id: 2,
    displayName: 'Michael Chen',
    role: 'Software Engineer',
    company: 'Dev Inc',
    mutualConnections: 8,
    trustScore: 88,
    profileImageUrl: '/placeholder.jpg',
    region: 'TX',

  },
];

const RECOMMENDED_USERS = [
  {
    id: 1,
    name: 'David Wilson',
    role: 'Marketing Director',
    avatar: '/placeholder.jpg',
    mutualConnections: 5
  },
  {
    id: 2,
    name: 'Emma Thompson',
    role: 'UX Designer',
    avatar: '/placeholder.jpg',
    mutualConnections: 3
  }
];

// Skeleton Components
const StatCardSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      {[1, 2, 3, 4].map((item) => (
        <Grid item xs={6} key={item}>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="80%" height={24} />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const SearchResultSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton variant="circular" width={50} height={50} />
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="30%" />
      </Box>
      <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: 18 }} />
    </Box>
  </Paper>
);

// Main Components
const NetworkStatsCard = ({ stats }) => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Network Statistics</Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.totalSize}</Typography>
        <Typography variant="body2" color="text.secondary">Total Network Size</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.invitesSent}</Typography>
        <Typography variant="body2" color="text.secondary">Invites This Month</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.successRate}%</Typography>
        <Typography variant="body2" color="text.secondary">Success Rate</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.availableInvites}</Typography>
        <Typography variant="body2" color="text.secondary">Available Invites</Typography>
      </Grid>
    </Grid>
  </Paper>
);

const QuickActionsCard = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Quick Actions</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Button variant="contained" startIcon={<ImportIcon />}>
        Import Email Contacts
      </Button>
      <Button variant="outlined" startIcon={<ShareIcon />}>
        Share Profile Link
      </Button>
    </Box>
  </Paper>
);

const GrowthTipsCard = ({ tips }) => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Network Growth Tips</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {tips.map((tip, index) => (
        <Typography key={index} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
          • {tip}
        </Typography>
      ))}
    </Box>
  </Paper>
);

const SearchResultCard = ({ user, onConnect }) => (
  <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Avatar src={user.profileImageUrl} sx={{ width: 50, height: 50 }} />
      <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle1">{user.displayName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {user.role} at {user.company}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {user.mutualConnections} mutual connections
        </Typography>
      </Box>
      <Button 
        variant="contained" 
        sx={{ borderRadius: 18 }}
        onClick={() => onConnect(user.id)}
      >
        Connect
      </Button>
    </Box>
  </Paper>
);

const EmailInviteCard = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [inviteUser, { isLoading }] = useEmailInviteMutation();

  const handleSubmit = async () => {
    try {
      setError(null); // Reset any previous errors
      const payload = {
        emails: [email],
        message,
      };
      const response = await inviteUser(payload).unwrap();
      console.log('Invitation sent successfully:', response);
      setEmail('');
      setMessage('');
    } catch (err) {
      console.error('Error sending invitation:', err);
      setError(err?.data?.message || 'An unexpected error occurred.'); // Update error state with API error
    }
  };

  
  
  return (
    <Paper variant="outlined" sx={{ p: 3, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Invite via Email
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          placeholder="Enter email address"
          size="small"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Add a personal message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading || !email}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Send Invite'}
        </Button>
      </Box>
    </Paper>
  );
};

const RecommendedUsersCard = ({ users, onConnect }) => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Recommended</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {users.map((user) => (
        <Box
          key={user.id}
          sx={{
            display: 'flex',
            gap: 2,
            p: 2,
            bgcolor: 'grey.50',
            borderRadius: 1,
            alignItems: 'center'
          }}
        >
          <Avatar src={user.avatar} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2">{user.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {user.role} • {user.mutualConnections} mutual
            </Typography>
          </Box>
          <IconButton size="small" onClick={() => onConnect(user.id)}>
            <PersonIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  </Paper>
);

// Main Page Component
export default function SearchInvitePage() {
  const navigate = useNavigate(); // For navigation

  const thisUser = useSelector((state) => state.auth?.user || '');
  console.log('thisUser', thisUser)


  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [showSameRegion, setShowSameRegion] = useState(false);
  const [selectedTrustScore, setSelectedTrustScore] = useState('all');
  const [anchorElTrust, setAnchorElTrust] = useState(null);



  const [triggerSearch, { data: searchData, isLoading: isSearchLoading }] = useFindUserMutation();

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setLoading(false);
      setDisplayedUsers(DEFAULT_USERS);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (searchData) {
      console.log('searchData', searchData)
      setDisplayedUsers(searchData)   
         
    }
  }, [searchData, navigate]);

  const applyFilters = () => {
    let filteredUsers = searchInitiated ? displayedUsers : DEFAULT_USERS;

    if (searchQuery) {
      filteredUsers = filteredUsers.filter(user => 
        user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
       
      );
    }

    // Filter by same region if toggled
    if (showSameRegion) {
      filteredUsers = filteredUsers.filter(user => user.region === thisUser.region);
    }

    if (selectedTrustScore !== 'all') {
      const minScore = parseInt(selectedTrustScore);
      filteredUsers = filteredUsers.filter(user => user.trustScore >= minScore);
    }

    setDisplayedUsers(filteredUsers);
  };


  const handleTrustClick = (event) => {
    setAnchorElTrust(event.currentTarget);
  };
  const handleTrustClose = () => {
    setAnchorElTrust(null);
  };
  const handleTrustSelect = (score) => {
    setSelectedTrustScore(score);
    handleTrustClose();
    applyFilters();
  };

  const handleConnect = (userId) => {
    console.log('Connecting with user:', userId);
  };

  const handleSearch = (event) => {
    
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = async(event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      setSearchInitiated(true);
      const payload = { text: event.target.value };
      await triggerSearch({ payload });
      setSearchQuery(event.target.value);
      
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if within a form
      handleSearchSubmit(event);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSearchInitiated(false);
    setDisplayedUsers(DEFAULT_USERS);
    setShowSameRegion(false);
    setSelectedTrustScore('all');
  };

  if (loading) {
    return (
      <Box>
        <Box 
          sx={{ 
            bgcolor: 'primary.main', 
            color: 'white',
            p: 3,
            mb: 3 
          }}
        >
          <Container maxWidth="xl">
            <Skeleton 
              variant="text" 
              width={300} 
              height={40} 
              sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} 
            />
          </Container>
        </Box>

        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <StatCardSkeleton />
              <StatCardSkeleton />
              <StatCardSkeleton />
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
                <Skeleton variant="rectangular" height={56} sx={{ borderRadius: 1 }} />
              </Paper>
              {[1, 2, 3].map((item) => (
                <SearchResultSkeleton key={item} />
              ))}
            </Grid>

            <Grid item xs={12} md={3}>
              <StatCardSkeleton />
              <StatCardSkeleton />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      <Box 
        sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          p: 3,
          mb: 3 
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4">Find & Invite Connections</Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Grow your trusted network
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <NetworkStatsCard stats={NETWORK_STATS} />
            <QuickActionsCard />
            <GrowthTipsCard tips={GROWTH_TIPS} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
              <form onSubmit={handleSearchSubmit}>
                <TextField
                  fullWidth
                  placeholder="Search by name, company, or role..."
                  value={searchQuery}
                  onChange={handleSearch}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchInitiated && (
                          <Button 
                            size="small" 
                            onClick={clearSearch}
                            sx={{ mr: 1 }}
                          >
                            Clear
                          </Button>
                        )}
                        <IconButton>
                          <FilterIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                <Chip
                  label={`Same Region ${showSameRegion ? '• On' : '• Off'}`}
                  variant="outlined"
                  onClick={() => {
                    setShowSameRegion(!showSameRegion);
                    setTimeout(applyFilters, 0);
                  }}
                  color={showSameRegion ? "primary" : "default"}
                />
                <Chip
                  label={`Trust Score ${selectedTrustScore !== 'all' ? `• ${selectedTrustScore}+` : '▼'}`}
                  variant="outlined"
                  onClick={handleTrustClick}
                  color={selectedTrustScore !== 'all' ? "primary" : "default"}
                />
                
                {/* Trust Score Popover remains the same */}
                <Popover
                  open={Boolean(anchorElTrust)}
                  anchorEl={anchorElTrust}
                  onClose={handleTrustClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <MenuList>
                    {TRUST_SCORE_RANGES.map((range) => (
                      <MenuItem 
                        key={range.value}
                        onClick={() => handleTrustSelect(range.value)}
                        selected={selectedTrustScore === range.value}
                      >
                        {range.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
              </Box>
            </Paper>

            {/* Header for results */}
            <Box sx={{ px: 2, mb: 2 }}>
              <Typography variant="h6">
                {searchInitiated ? 'Search Results' : 'Popular in Your Network'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {searchInitiated 
                  ? `Found ${displayedUsers.length} results for "${searchQuery}"`
                  : 'People you might want to connect with'}
              </Typography>
            </Box>
            
            {displayedUsers.map((user) => (
              <SearchResultCard
                key={user._id}
                user={user}
                onConnect={handleConnect}
              />
            ))}
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            <EmailInviteCard />
            <RecommendedUsersCard 
              users={RECOMMENDED_USERS}
              onConnect={handleConnect}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}