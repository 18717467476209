import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Chip,
  Button,
  Divider,
  Paper,
  Container,
  Alert,
  Skeleton
} from '@mui/material';
import {
  Email as EmailIcon,
  LocationOn as LocationIcon,
  Verified as VerifiedIcon,
  StarBorder as StarIcon
} from '@mui/icons-material';

// Skeleton components for loading states
const ProfileSectionSkeleton = ({ height = 200 }) => (
  <Card sx={{ height, mb: 2 }}>
    <CardContent>
      <Skeleton variant="text" width="60%" height={32} />
      <Skeleton variant="rectangular" height={height - 100} sx={{ mt: 2 }} />
    </CardContent>
  </Card>
);

const ProfileCardSkeleton = () => (
  <Card sx={{ mb: 3 }}>
    <CardContent sx={{ textAlign: 'center' }}>
      <Skeleton variant="circular" width={100} height={100} sx={{ mx: 'auto' }} />
      <Skeleton variant="text" width="70%" sx={{ mx: 'auto', mt: 2 }} />
      <Divider sx={{ my: 2 }} />
      <Box sx={{ textAlign: 'left' }}>
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="80%" />
      </Box>
    </CardContent>
  </Card>
);

const ChipsSkeleton = () => (
  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
    <Skeleton variant="rectangular" width={100} height={32} sx={{ borderRadius: 16 }} />
    <Skeleton variant="rectangular" width={120} height={32} sx={{ borderRadius: 16 }} />
    <Skeleton variant="rectangular" width={90} height={32} sx={{ borderRadius: 16 }} />
  </Box>
);

const NewUserPublicProfile = ({ profile, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ bgcolor: 'grey.50', minHeight: '100vh', py: 4 }}>
        <Container maxWidth="lg">
          <Skeleton variant="rectangular" height={48} sx={{ mb: 3, borderRadius: 1 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <ProfileCardSkeleton />
              <ProfileSectionSkeleton height={200} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProfileSectionSkeleton height={150} />
              <ProfileSectionSkeleton height={150} />
              <ProfileSectionSkeleton height={200} />
            </Grid>
            <Grid item xs={12} md={3}>
              <ProfileSectionSkeleton height={200} />
              <ProfileSectionSkeleton height={180} />
              <ProfileSectionSkeleton height={150} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  const defaultProfile = {
    name: 'John Smith',
    joinDate: 'December 2024',
    location: 'San Francisco Bay Area',
    bio: 'Excited to join the community! Looking forward to discovering and sharing great local business experiences.',
    interests: ['Restaurants', 'Coffee Shops', 'Local Retail'],
    verifications: {
      email: true,
      phone: false
    },
    stats: {
      connections: 0,
      references: 0,
      trustScore: 20
    },
    ...profile
  };

  return (
    <Box sx={{ bgcolor: 'grey.50', minHeight: '100vh' }}>
      {/* New Member Banner */}
      <Paper 
        elevation={0} 
        sx={{ 
          bgcolor: 'primary.50', 
          py: 1, 
          px: 2, 
          mb: 3
         
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="body2" color="primary">
            🆕 New Community Member • Joined {defaultProfile.joinDate}
          </Typography>
        </Container>
      </Paper>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <Card sx={{ mb: 3,  border: 'none', }} variant="outlined">
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    mx: 'auto',
                    mb: 2,
                    bgcolor: 'primary.main'
                  }}
                >
                  {defaultProfile.name.split(' ').map(n => n[0]).join('')}
                </Avatar>
                <Typography variant="h6" gutterBottom>
                  {defaultProfile.name}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    <LocationIcon fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {defaultProfile.location}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    <StarIcon fontSize="small" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Building Trust Network
                  </Typography>
                </Box>
              </CardContent>
            </Card>

            <Card variant="outlined" sx={{ border: 'none', }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Verification Status
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Chip
                    icon={<EmailIcon />}
                    label="Email Verified"
                    color="success"
                    variant="outlined"
                    sx={{ mb: 1 }}
                  />
                  <Chip
                    label="Phone Verification Pending"
                    variant="outlined"
                    sx={{ mb: 1 }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Card  sx={{ mb: 3,  border: 'none', }} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  About
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {defaultProfile.bio}
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ mb: 3,  border: 'none', }} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Interests
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {defaultProfile.interests.map((interest) => (
                    <Chip
                      key={interest}
                      label={interest}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>

            <Card sx={{  border: 'none', }} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Activity
                </Typography>
                <Alert severity="info" sx={{ mt: 2 }}>
                  No reviews or references yet. This member is just getting started!
                </Alert>
              </CardContent>
            </Card>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            <Card sx={{ mb: 3,  border: 'none', }} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Network Overview
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Trust Score: Building
                  </Typography>
                  <Box
                    sx={{
                      height: 8,
                      bgcolor: 'grey.200',
                      borderRadius: 1,
                      mb: 2
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        width: `${defaultProfile.stats.trustScore}%`,
                        bgcolor: 'primary.main',
                        borderRadius: 1
                      }}
                    />
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Connections: {defaultProfile.stats.connections}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    References: {defaultProfile.stats.references}
                  </Typography>
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ mb: 3,  border: 'none', }} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Connect
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  Follow User
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                >
                  Send Message
                </Button>
              </CardContent>
            </Card>

            <Card  sx={{border: 'none', }} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Profile Status
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  🆕 New Member
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  ✉️ Verified Email
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  🌱 Growing Network
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NewUserPublicProfile;