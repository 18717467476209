import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  ClickAwayListener,
  Stack,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useClaimBusinessMutation } from '../../redux/thunks/sp/claimbusiness';
import UaClaimContent from './UaClaimContent';

const ClaimBusiness = ({ isOpen, onClose, businessId }) => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  console.log('authStatus', authStatus)

  


  const displayName = useSelector((state) => state.auth?.user?.displayName || null);
  console.log('displayName', displayName)
  const [userName, setUserName] = useState(displayName);
  const [date, setDate] = useState(new Date()); // Set today’s date as the default
  const [uaAgreement, setUaAgreement] = useState(false);
  const [postClaim, { isLoading }] = useClaimBusinessMutation();
  const [responseMessage, setResponseMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const token = useSelector((state) => state.auth?.token);

  

  const handleClaim = async () => {
    if (authStatus === 'unauthenticated') {
      console.log('navigate to signin page')
      navigate('/auth/signin', { state: 'tbd' });

    }
    if (!uaAgreement) {
      alert('Please accept the User Agreement before claiming.');
      return;
    }

    const auth = {
      token: token,
      authType: 'token',
    };
    const payload = {
      auth,
      userName,
      date: date.toISOString().split('T')[0], // Format date as YYYY-MM-DD
      businessId,
    };

    const res = await postClaim({ payload });

    if (res?.data?.message) {
      setResponseMessage(res.data.message);
      setShowSnackbar(true);
    }
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleClickAway = (e) => {
    if (!e.target.closest('.custom-input')) {
      setUserName('');
      setDate(new Date()); // Reset to today's date
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6" component="span">Claim Business</Typography>
      </DialogTitle>
      <DialogContent>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Stack spacing={2}>
            <Typography variant="body1">
              Please agree to the User Agreement before claiming this business:
            </Typography>
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: UaClaimContent }} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={uaAgreement}
                  onChange={() => setUaAgreement(!uaAgreement)}
                  name="uaAgreement"
                />
              }
              label="I agree to the User Agreement"
            />
            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="custom-input"
            />
            <DatePicker
              label="Date Filed"
              value={date}
              onChange={(newDate) => setDate(newDate)}
              renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
            />
          </Stack>
        </ClickAwayListener>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClaim} variant="contained" color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Claim'}
        </Button>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
      </DialogActions>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={responseMessage}
      />
    </Dialog>
  );
};

export default ClaimBusiness;
