import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Rating,
  Stack,
  ButtonBase,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import {
  ThumbUp,
  Comment,
  Bookmark,
  Share,
  Report,
  Store,
  Person,
  QuestionAnswer,
  CheckCircle,
  Event,
  RateReview,
  NavigateNext,
  NotificationsActive,
  Handshake,
  Campaign,
  VerifiedUser
} from '@mui/icons-material';
import moment from 'moment';

// Add these mock data arrays for testing
const mockReferralUsers = [
  { id: 1, name: 'John Smith', avatar: null, timestamp: '2024-01-15' },
  { id: 2, name: 'Emma Wilson', avatar: null, timestamp: '2024-01-14' },
  { id: 3, name: 'Michael Brown', avatar: null, timestamp: '2024-01-13' }
];

const mockFollowers = [
  { id: 1, name: 'Sarah Davis', avatar: null, timestamp: '2024-01-15' },
  { id: 2, name: 'James Johnson', avatar: null, timestamp: '2024-01-14' },
  { id: 3, name: 'Lisa Anderson', avatar: null, timestamp: '2024-01-13' }
];


// Activity type constants
const ACTIVITY_TYPES = {
  REVIEW: 'reviewed',
  REFERENCE: 'endorsement',
  QA: 'qa',
  VISIT: 'BusinessViewed',
  CONNECTION: 'connection',
  FOLLOWED: 'followedBusiness',
  POST: 'BusinessPostCreate' ,
  BUSINESS_CREATED: 'CreatedNewBusiness', 
  CLAIM_BUSINESS: 'ClaimedBusiness'
};

const ActivityContent = ({ type, data }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   // Handlers for the metrics
   const handleShare = async () => {
    const shareData = {
      title: `Review of ${data.businessName}`,
      text: data.summary,
      url: window.location.href // or construct specific review URL
    };

    try {
      // Try using Web Share API if available
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Fallback to copying link
        await navigator.clipboard.writeText(shareData.url);
        // You might want to show a success toast here
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Handle error - maybe show an error toast
    }
  };

  const handleReport = () => {
    // This would typically open a modal or navigate to a report form
    // For now, we'll just console.log
    console.log('Report clicked for review:', data.id);
    // You might want to trigger a modal or navigate to report form
    // openReportModal(data.id);
  };

  const handleVisitBusiness = () => {
    // Navigate to the business profile
    navigate(`/business/${data.businessId}`);
  };

  const handleViewReviews = () => {
    navigate(`/business/${data.businessId}/reviews`);
  };

  const getActivityIcon = () => {
    switch (type) {
      case ACTIVITY_TYPES.CLAIM_BUSINESS:
        return <VerifiedUser />;
      case ACTIVITY_TYPES.BUSINESS_CREATED:
        return <Store />;
      case ACTIVITY_TYPES.REVIEW:
        return <Store />;
      case ACTIVITY_TYPES.REFERENCE:
        return <Share />;
      case ACTIVITY_TYPES.QA:
        return <QuestionAnswer />;
      case ACTIVITY_TYPES.VISIT:
        return <Event />;
      case ACTIVITY_TYPES.CONNECTION:
        return <Person />;
      case ACTIVITY_TYPES.FOLLOWED:
        return <Bookmark />; 
      case ACTIVITY_TYPES.POST:
        return <Campaign />;
      default:
        return <Store />;
    }
  };

  const renderContent = () => {
    switch (type) {

      case ACTIVITY_TYPES.CLAIM_BUSINESS:
        return (
          <>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" component="div">
                  {data.userDisplayName}
                </Typography>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  claimed
                </Typography>
                <Typography 
                  variant="subtitle1" 
                  sx={{ ml: 1 }}
                >
                  {data.businessName}
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  whiteSpace: 'nowrap',
                  ml: 2
                }}
              >
                {moment(data.creationDate).fromNow()}
              </Typography>
            </Box>
            {data.businessCategory && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                {data.businessCategory}
              </Typography>
            )}
            {data.verificationMethod && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 0.5,
                  mb: 1 
                }}
              >
                <Verified fontSize="small" color="primary" />
                Verified via {data.verificationMethod}
              </Typography>
            )}
          </>
        );

      case ACTIVITY_TYPES.BUSINESS_CREATED:
        return (
          <>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" component="div">
                  {data.businessName}
                </Typography>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  was created
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  whiteSpace: 'nowrap',
                  ml: 2
                }}
              >
                {moment(data.creationDate).fromNow()}
              </Typography>
            </Box>
            {data.businessCategory && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                {data.businessCategory}
              </Typography>
            )}
            {data.description && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  mb: 2,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
              >
                {data.description}
              </Typography>
            )}
            {data.location && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 0.5 
                }}
              >
                <LocationOn fontSize="small" />
                {data.location}
              </Typography>
            )}
          </>
        );

      case ACTIVITY_TYPES.POST:
        return (
          <>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" component="div">
                  {data.businessName}
                </Typography>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  posted an update
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  whiteSpace: 'nowrap',
                  ml: 2
                }}
              >
                {moment(data.creationDate).fromNow()}
              </Typography>
            </Box>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                mb: 2,
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {data.post}
            </Typography>
            {data.photos?.length > 0 && (
              <Stack 
                direction="row" 
                spacing={1} 
                sx={{ mb: 2, overflow: 'auto' }}
              >
                {data.photos.map((photo, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={photo}
                    alt={`Post photo ${index + 1}`}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: 1,
                      objectFit: 'cover'
                    }}
                  />
                ))}
              </Stack>
            )}
          </>
        );
      
      case ACTIVITY_TYPES.REVIEW:
        return (
          <>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" component="div">
                  {data.businessName}
                </Typography>
                <Rating 
                  value={data.rating} 
                  readOnly 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              </Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  whiteSpace: 'nowrap',
                  ml: 2
                }}
              >
                {moment(data.creationDate).fromNow()}
              </Typography>
            </Box>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                mb: 2,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {data.summary}
            </Typography>
            {data.photos?.length > 0 && (
              <Stack 
                direction="row" 
                spacing={1} 
                sx={{ mb: 2, overflow: 'auto' }}
              >
                {data.photos.map((photo, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={photo}
                    alt={`Review photo ${index + 1}`}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: 1,
                      objectFit: 'cover'
                    }}
                  />
                ))}
              </Stack>
            )}
          </>
        );

      case ACTIVITY_TYPES.REFERENCE:
        return (
          <>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 1 
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" component="div">
              {data.userDisplayName} referred {data.businessName}
              </Typography>
            </Box>
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ 
                whiteSpace: 'nowrap',
                ml: 2
              }}
            >
              {moment(data.creationDate).fromNow()}
            </Typography>
          </Box>
          {data.businessCategory && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              {data.businessCategory}
            </Typography>
          )}
          {data.businessLocation && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 0.5 
              }}
            >
              <LocationOn fontSize="small" />
              {data.businessLocation}
            </Typography>
          )}
          </>
        );

      case ACTIVITY_TYPES.QA:
        return (
          <>
            <Typography variant="subtitle1" component="div">
              {data.businessName}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary',
                fontStyle: 'italic',
                mb: 1 
              }}
            >
              Q: {data.question}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              A: {data.answer}
            </Typography>
          </>
        );

      case ACTIVITY_TYPES.FOLLOWED:
        return (
          <>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" component="div">
                  Started following {data.businessName}
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  whiteSpace: 'nowrap',
                  ml: 2
                }}
              >
                {moment(data.creationDate).fromNow()}
              </Typography>
            </Box>
            {data.businessCategory && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                {data.businessCategory}
              </Typography>
            )}
            {data.businessLocation && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 0.5 
                }}
              >
                <LocationOn fontSize="small" />
                {data.businessLocation}
              </Typography>
            )}
          </>
        );

      // Add other activity types as needed...
      default:
        return null;
    }
  };

  const renderMetrics = () => {
    const handleViewReviews = () => {
      navigate(`/business/${data.businessId}/reviews`);
    };
  
    const handleViewReferrals = () => {
      console.log('Show referral users:', mockReferralUsers);
    };
  
    const handleViewFollowers = () => {
      console.log('Show followers:', mockFollowers);
    };
  
    // Common metrics for all business-related activities
    const commonMetrics = [
      {
        icon: <RateReview />,
        label: `Reviews`,
        onClick: handleViewReviews,
        tooltip: 'View all reviews'
      },
      {
        icon: <Handshake />,
        label: `Referrals`,
        onClick: handleViewReferrals,
        tooltip: 'View all referrals'
      },
      {
        icon: <Bookmark />,
        label: `Followers`,
        onClick: handleViewFollowers,
        tooltip: 'View all followers'
      }
    ];
  
    const metrics = {
      [ACTIVITY_TYPES.REVIEW]: commonMetrics,
      [ACTIVITY_TYPES.REFERENCE]: commonMetrics,
      [ACTIVITY_TYPES.FOLLOWED]: commonMetrics,
      [ACTIVITY_TYPES.VISIT]: commonMetrics,
      [ACTIVITY_TYPES.POST]: commonMetrics,
      [ACTIVITY_TYPES.BUSINESS_CREATED]: commonMetrics,
      [ACTIVITY_TYPES.CLAIM_BUSINESS]: commonMetrics,
      [ACTIVITY_TYPES.QA]: [
        {
          icon: <ThumbUp />,
          label: `${data.helpfulCount || 0} Helpful`,
          onClick: () => {},
          tooltip: 'People found this helpful'
        }
      ]
    };
  
    const currentMetrics = metrics[type] || [];
  
    return (
      <Stack 
        direction="row" 
        spacing={3}
        sx={{ 
          mt: 2,
          justifyContent: { xs: 'space-around', sm: 'flex-start' }
        }}
      >
        {currentMetrics.map((metric, index) => (
          <Tooltip key={index} title={metric.tooltip}>
            <ButtonBase
              onClick={metric.onClick}
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main'
                }
              }}
            >
              {React.cloneElement(metric.icon, { 
                sx: { 
                  fontSize: { xs: 20, sm: 20 },
                  color: 'inherit'
                } 
              })}
              <Typography 
                variant="body2" 
                sx={{ 
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                {metric.label}
              </Typography>
            </ButtonBase>
          </Tooltip>
        ))}
      </Stack>
    );
  };
  return (
    <Card variant="outlined" sx={{ border: 'none', mb: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
            {getActivityIcon()}
          </Avatar>
          <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flex: 1 }}>
            {renderContent()}
          </Box>
          {renderMetrics()}
          </Box>
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              whiteSpace: 'nowrap',
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              }
            }}
          >
            {data.timestamp}
          </Typography>
        </Box>
        {isMobile && (
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ display: 'block', mt: 1, textAlign: 'right' }}
          >
            {data.timestamp}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

// Main Activity component that decides which type to render
const Activity = ({ activity }) => {
  return (
    <>
    <ActivityContent 
      type={activity.action} 
      data={activity} 
    />

    <Divider flexItem />
    
    </>
    
  );
};

export default Activity;