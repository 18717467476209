
import {filesApi} from './files' 


export const filespresignurl = filesApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreSignedUrl: builder.query({
      query: ({userId, businessId, reviewId, postId, eventType}) => `s3/user/images?eventType=${eventType}&businessId=${businessId}&reviewId=${reviewId}&postId=${postId}&userId=${userId}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetPreSignedUrlQuery, usePrefetch } = filespresignurl