import React from 'react';
import { Box, Grid, Paper, Typography, Rating } from '@mui/material';
import {
  Reviews as ReviewsIcon,
  PeopleAlt as PeopleIcon,
  Visibility as ViewsIcon,
  Share as ReferralsIcon
} from '@mui/icons-material';

const METRIC_CONFIG = {
  maxTotalReviews: {
    icon: ReviewsIcon,
    label: 'Most Reviewed',
    statsPath: 'reviews.totalReviews',
    valueLabel: 'reviews'
  },
  maxTotalFollows: {
    icon: PeopleIcon,
    label: 'Most Followed',
    statsPath: 'follows.totalFollows',
    valueLabel: 'followers'
  },
  maxTotalPageViews: {
    icon: ViewsIcon,
    label: 'Most Viewed',
    statsPath: 'pageViews.totalPageViews',
    valueLabel: 'views'
  },
  maxTotalReferrals: {
    icon: ReferralsIcon,
    label: 'Most Referred',
    statsPath: 'referrals.totalReferrals',
    valueLabel: 'referrals'
  }
};

const getStatsValue = (stats, path) => {
  return path.split('.').reduce((obj, key) => obj?.[key], stats) || 0;
};

const TrustSignalCard = ({ business }) => {
  const config = METRIC_CONFIG[business.metric];
  if (!config) return null;

  const Icon = config.icon;
  const value = getStatsValue(business.stats, config.statsPath);
  const businessDetails = business.businessDetail;

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Icon sx={{ color: 'primary.main', mr: 1 }} />
              <Typography variant="body2" color="primary" sx={{ fontWeight: 500 }}>
                {config.label}: {value} {config.valueLabel}
              </Typography>
            </Box>
            
            <Typography variant="subtitle1">{businessDetails.businessName}</Typography>
            <Typography variant="body2" color="text.secondary">
              {businessDetails.category.join(' • ')}
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Rating 
                value={businessDetails.avgSkillRating_all || 0} 
                readOnly 
                size="small" 
              />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                {businessDetails.SkillValue_all ? 
                  `Smart Rating: ${Math.round(businessDetails.SkillValue_all * 10) / 10}` : 
                  'Smart Rating: N/A'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const TrustSignals = ({ topBusinesses }) => {
  if (!topBusinesses || !Array.isArray(topBusinesses)) {
    return null;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Trust Signals
      </Typography>
      
      <Grid container spacing={2}>
        {topBusinesses.map(business => (
          <TrustSignalCard 
            key={business._id} 
            business={business}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default TrustSignals;