import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel
} from '@mui/material';

const USER_ROLES = [
  'Business Owner',
  'Business Manager',
  'Legal Representative',
  'Family Representative',
  'Authorized Agent'
];

const UserRoleSelector = ({ value, onChange, error }) => {
  const handleSelectChange = (event) => {
    // Ensure the name property is preserved
    event.target.name = "userRole";
    onChange(event);
  };

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel id="user-role-label">Role</InputLabel>
      <Select
        labelId="user-role-label"
        id="user-role-select"
        name="userRole"
        value={value || ''}
        label="Role"
        onChange={handleSelectChange}
      >
        {USER_ROLES.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default UserRoleSelector;