import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  Grid,
  Alert,
} from '@mui/material';
import { WavingHand as WaveIcon } from '@mui/icons-material';
import moment from 'moment';


const NewUserWelcome = ({ userName, joinDate }) => {

    const formattedDate = moment(joinDate).format('MMM - YYYY');

  return (

    <Grid >
        <Card variant="outlined" sx={{ border: 'none', mb: 3 }}>
            <CardContent>
                <Stack spacing={3}>
                    <Box sx={{ 
                        textAlign: 'center', 
                        py: 3,
                        px: 2
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                            <WaveIcon sx={{ color: 'primary.main' }} />
                            New Community Member
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                            {userName} recently joined our community
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Member since {formattedDate}
                            </Typography>
                    </Box>
                
                
                </Stack>
            </CardContent>
        </Card>

        <Card sx={{  border: 'none', }} variant="outlined">
            <CardContent>
            <Typography variant="h6" gutterBottom>
                Activity
            </Typography>
            <Alert severity="info" sx={{ mt: 2 }}>
                No reviews or references yet. This member is just getting started!
            </Alert>
            </CardContent>
        </Card>


    </Grid>
    
  );
};

export default NewUserWelcome;