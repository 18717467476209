




export const addZeroBeforeNumber = (number) => {
    return (number > 9) ? number : `0${number}`
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  
  export const generateColorFromInitials = initials => {
    const colors = [
      "#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5",
      "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50",
      "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800",
      "#FF5722", "#795548", "#9E9E9E", "#607D8B"
    ];
  
    let hash = 0;
    for (let i = 0; i < initials.length; i++) {
      hash = initials.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // Use the hash to index the colors array
    return colors[Math.abs(hash) % colors.length];
  }
  
  export const getRandomLightColor = () => {
    const r = Math.floor(Math.random() * 156) + 100;
    const g = Math.floor(Math.random() * 156) + 100;
    const b = Math.floor(Math.random() * 156) + 100;
    return `rgb(${r}, ${g}, ${b})`;
  }
  
  export const getUniqueUsers = (array) => {
    const uniqueUserIds = new Set();
    return array.filter((obj) => !uniqueUserIds.has(obj.userId) && uniqueUserIds.add(obj.userId));
  }
  
  export const getShadeOfGrey = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    const greyValue = Math.abs(hash) % 256;
    const shade = `rgb(${greyValue}, ${greyValue}, ${greyValue})`;
    return shade;
  }
  
  export const truncateText = (str, limit = 1) => {
    const sentences = str.split(/([.!?])\s/);
    let truncatedText = '';
  
    for (let i = 0; i < Math.min(limit * 2 - 1, sentences.length); i++) {
      truncatedText += sentences[i];
    }
  
    if (sentences.length > limit * 2) {
      truncatedText += '...';
    }
  
    return truncatedText;
  };

  export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const earthRadiusKm = 6371; // Earth's radius in kilometers
    const earthRadiusMiles = 3958.8

  // Convert latitude and longitude from degrees to radians
  const radLat1 = (Math.PI * lat1) / 180;
  const radLon1 = (Math.PI * lon1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const radLon2 = (Math.PI * lon2) / 180;

  // Calculate the differences between latitudes and longitudes
  const deltaLat = radLat2 - radLat1;
  const deltaLon = radLon2 - radLon1;

  // Haversine formula
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(radLat1) *
      Math.cos(radLat2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance
  const distance = earthRadiusMiles * c;

  return distance;
  };


  //Given a user and a list of businesses, find the users who have endorsed, liked or reviewed this business from my network list

  export const getUsersFromMyNetworkForBusinesses = (myCurrentNetworkList, businessDetails) => {
    
      
    try {

      if(!businessDetails) {
        console.log('in utilities.getUsersFromMyNetworkForBusinesses businessDetails not defined')
        return []
      }


      // Use the map function to extract the id values from myCurrentNetworkList
      //const myNetworkIds = myCurrentNetworkList.userDetails.map((obj) => obj.id.toString()); 

      const currentObjectIds = new Set();

      if (businessDetails.spUserId && businessDetails.spUserId !== '') {
        currentObjectIds.add(businessDetails.spUserId.toString());
      }

      businessDetails.endorsements.forEach((endorsementUserId) => {
        currentObjectIds.add(endorsementUserId.toString());
      });

      businessDetails.follows.forEach((followUserId) => {
        currentObjectIds.add(followUserId.toString());
      });

      //const commonIds = myNetworkIds.filter(id => currentObjectIds.has(id));   
      const commonIds = myCurrentNetworkList.userDetails.filter(obj => currentObjectIds.has(obj.id));
      return commonIds;

    } catch (error) {
      console.error('Error:', error);
    }

  }

  