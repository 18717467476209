import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchAuthSession } from "aws-amplify/auth";

console.log('REACT_APP_SEEKER_KEY', process.env.REACT_APP_SEEKER_KEY)


const url = process.env.REACT_APP_PROXY_PREFIX + 'https://devapi.revfer.com/v2/v2/sp'
console.log('url', url)

export const spApi = createApi({
  reducerPath: 'spApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
        //const token = getState().auth.token
        //console.log('token', token)
        const session = await fetchAuthSession(); 
        const token = session.tokens.idToken.toString();
        console.log('my token in sp base api', token)
    
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          //headers.set('x-api-key', process.env.REACT_APP_SEEKER_KEY)
          //headers.set("Content-Type", "appliation/json")

          headers.set('Authorization', `${token}`)
          headers.set("Content-Type", "application/json")
        }
        //console.log('headers', headers)
    
        return headers
      },
  }),
  tagTypes: ['sp', 'spreviews', 'Spprofile', 'businessDetails'],
  endpoints: () => ({}),
})
