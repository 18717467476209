import { 
    RateReview as ReviewIcon,
    Visibility as ReaderIcon,
    ThumbUp as HelpfulIcon,
    EmojiEvents as AwardIcon,
    Share as ShareIcon,
    GroupAdd as CommunityIcon,
    Star as TopContributorIcon,
    WorkspacePremium as EliteIcon,
    Whatshot as TrendingIcon,
    LocalOffer as BadgeIcon,
    Campaign as InfluencerIcon,
    Photo as PhotoIcon,
    QuestionAnswer as QAIcon,
    Favorite as FavoriteIcon,
    Psychology as InsightIcon,
    Celebration as MilestoneIcon,
    Timer as StreakIcon,
    Diversity3 as NetworkIcon,
    FindInPage as DiscovererIcon,
    Lightbulb as TipsterIcon,
    Loyalty as RegularIcon,
    Hub as ConnectorIcon,
    Update as FreshIcon,
    PersonAdd as WelcomingIcon,
    Comment as EngagingIcon,
    Favorite as LikeIcon,
    MoodBad as DislikeIcon,
    Balance as BalanceIcon,
    AutoGraph as PowerUserIcon,
    Business as BusinessIcon,
    Support as SupporterIcon,
    GppBad as CriticIcon
  } from '@mui/icons-material';
  
  export const ACHIEVEMENT_CATEGORIES = {
    REPUTATION: { range: [900, 999] },
    MILESTONES: { range: [800, 899] },
    COMMUNITY: { range: [700, 799] },
    REVIEWS: { range: [600, 699] },
    READING: { range: [500, 599] },
    HELPFULNESS: { range: [400, 499] },
    ENGAGEMENT: { range: [300, 399] },
    SOCIAL: { range: [200, 299] },
    DISCOVERY: { range: [100, 199] }
  };
  
  export const ACHIEVEMENT_CONFIG = {
    // Platform Pioneer Badges (950-999)
    'EARLY_ADOPTER': {
      priority: 995,
      title: 'Early Adopter',
      description: 'Joined during the first 3 months of launch',
      icon: TrendingIcon,
      color: {
        bg: '#E8F5E9',
        text: '#00796B',
        contrastText: '#004D40'
      }
    },
    'TOP_CONNECTOR': {
      priority: 990,
      title: 'Top Connector',
      description: 'Successfully referred multiple active users',
      icon: ConnectorIcon,
      color: {
        bg: '#E1F5FE',
        text: '#0277BD',
        contrastText: '#01579B'
      }
    },
    // Reputation Badges (900-999)
    'TOP_REVIEWER': {
      priority: 999,
      title: 'Top Reviewer',
      description: 'Among the most trusted reviewers',
      icon: TopContributorIcon,
      color: {
        bg: '#FFF8E1',
        text: '#F57F17',
        contrastText: '#F57F17'
      }
    },
    'TRUSTED_VOICE': {
      priority: 990,
      title: 'Trusted Voice',
      description: 'Consistently helpful and reliable reviews',
      icon: EliteIcon,
      color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
      }
    },
    'TRENDING_REVIEWER': {
      priority: 980,
      title: 'Trending Reviewer',
      description: 'Reviews gaining significant attention',
      icon: TrendingIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
  
    // Milestones (800-899)
    'FIRST_REVIEW': {
      priority: 899,
      title: 'First Review',
      description: 'Wrote their first review',
      icon: MilestoneIcon,
      color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
      }
    },
    'REVIEWER_IN_MAKING': {
      priority: 898,
      title: 'Reviewer in Making',
      description: 'Wrote 3-5 reviews',
      icon: ReviewIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    'ACTIVE_EXPLORER': {
      priority: 897,
      title: 'Active Explorer',
      description: 'Viewed many different businesses',
      icon: DiscovererIcon,
      color: {
        bg: '#FFF3E0',
        text: '#EF6C00',
        contrastText: '#E65100'
      }
    },
    'MILESTONE_10': {
      priority: 896,
      title: '10 Reviews',
      description: 'Completed 10 reviews',
      icon: MilestoneIcon,
      color: {
        bg: '#E8EAF6',
        text: '#3949AB',
        contrastText: '#1A237E'
      }
    },
    'MILESTONE_25': {
      priority: 895,
      title: '25 Reviews',
      description: 'Completed 25 reviews',
      icon: MilestoneIcon,
      color: {
        bg: '#F3E5F5',
        text: '#7B1FA2',
        contrastText: '#4A148C'
      }
    },
    'MILESTONE_50': {
      priority: 894,
      title: '50 Reviews',
      description: 'Completed 50 reviews',
      icon: MilestoneIcon,
      color: {
        bg: '#FFE0B2',
        text: '#EF6C00',
        contrastText: '#E65100'
      }
    },
    'MILESTONE_100': {
      priority: 893,
      title: '100 Reviews',
      description: 'Completed 100 reviews',
      icon: MilestoneIcon,
      color: {
        bg: '#FFF8E1',
        text: '#F57F17',
        contrastText: '#F57F17'
      }
    },
    'REVIEW_CENTURY': {
      priority: 890,
      title: 'Century Reviewer',
      description: 'Published 100 helpful reviews',
      icon: MilestoneIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    'DEDICATED_READER': {
      priority: 880,
      title: 'Dedicated Reader',
      description: 'Read 500+ reviews',
      icon: ReaderIcon,
      color: {
        bg: '#F3E5F5',
        text: '#7B1FA2',
        contrastText: '#4A148C'
      }
    },
    'YEARLY_DEVOTION': {
      priority: 870,
      title: 'Year-Long Journey',
      description: 'Active member for over a year',
      icon: RegularIcon,
      color: {
        bg: '#E8EAF6',
        text: '#3949AB',
        contrastText: '#1A237E'
      }
    },
  
    // Community Contributions (700-799)
    'HELPFUL_SOUL': {
      priority: 790,
      title: 'Helpful Soul',
      description: 'Helped 100+ community members',
      icon: HelpfulIcon,
      color: {
        bg: '#E0F7FA',
        text: '#00838F',
        contrastText: '#006064'
      }
    },
    'QUESTION_SOLVER': {
      priority: 780,
      title: 'Question Solver',
      description: 'Provided 50+ helpful answers',
      icon: QAIcon,
      color: {
        bg: '#F1F8E9',
        text: '#558B2F',
        contrastText: '#33691E'
      }
    },
    'FRIENDLY_FACE': {
      priority: 770,
      title: 'Friendly Face',
      description: 'Welcomes and helps new members',
      icon: WelcomingIcon,
      color: {
        bg: '#FFF3E0',
        text: '#EF6C00',
        contrastText: '#E65100'
      }
    },

    'POWER_ENGAGER': {
    priority: 690,
    title: 'Power Engager',
    description: 'Interacted with 100+ reviews',
    icon: PowerUserIcon,
    color: {
      bg: '#E1F5FE',
      text: '#0288D1',
      contrastText: '#01579B'
    }
  },
  'REVIEW_CONNOISSEUR': {
    priority: 680,
    title: 'Review Connoisseur',
    description: 'Engaged with 20+ different businesses',
    icon: BusinessIcon,
    color: {
      bg: '#E8EAF6',
      text: '#3949AB',
      contrastText: '#1A237E'
    }
  },
  
    // Review Quality (600-699)
    'PHOTO_CONTRIBUTOR': {
      priority: 690,
      title: 'Photo Contributor',
      description: 'Added 50+ helpful photos to reviews',
      icon: PhotoIcon,
      color: {
        bg: '#E1F5FE',
        text: '#0288D1',
        contrastText: '#01579B'
      }
    },
    'DETAILED_REVIEWER': {
      priority: 680,
      title: 'Detailed Reviewer',
      description: 'Known for comprehensive reviews',
      icon: InsightIcon,
      color: {
        bg: '#F3E5F5',
        text: '#7B1FA2',
        contrastText: '#4A148C'
      }
    },
  
    // Reading Engagement (500-599)
    'AVID_READER': {
      priority: 590,
      title: 'Avid Reader',
      description: 'Regularly reads and engages with reviews',
      icon: ReaderIcon,
      color: {
        bg: '#FFE0B2',
        text: '#EF6C00',
        contrastText: '#E65100'
      }
    },
    'FEEDBACK_PROVIDER': {
      priority: 580,
      title: 'Feedback Provider',
      description: 'Regularly rates reviews as helpful',
      icon: HelpfulIcon,
      color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
      }
    },
    'REVIEW_APPRECIATOR': {
      priority: 575,
      title: 'Review Appreciator',
      description: 'Liked 10+ reviews',
      icon: LikeIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    'ACTIVE_CRITIC': {
    priority: 570,
    title: 'Active Critic',
    description: 'Provided critical feedback on 10+ reviews',
    icon: DislikeIcon,
    color: {
      bg: '#FFF3E0',
      text: '#E65100',
      contrastText: '#BF360C'
    }
  },
  'BALANCED_EVALUATOR': {
    priority: 565,
    title: 'Balanced Evaluator',
    description: 'Shows balanced judgment with likes and dislikes',
    icon: BalanceIcon,
    color: {
      bg: '#E8F5E9',
      text: '#2E7D32',
      contrastText: '#1B5E20'
    }
  },

  'REVIEW_FAN': {
    priority: 395,
    title: 'Review Fan',
    description: 'Liked 50+ reviews',
    icon: FavoriteIcon,
    color: {
      bg: '#FCE4EC',
      text: '#C2185B',
      contrastText: '#880E4F'
    }
  },
  'LOYAL_SUPPORTER': {
    priority: 385,
    title: 'Loyal Supporter',
    description: 'Frequently supports specific businesses',
    icon: SupporterIcon,
    color: {
      bg: '#F3E5F5',
      text: '#7B1FA2',
      contrastText: '#4A148C'
    }
  },

  // Dislike-Focused Achievements (200-299)
  'CRITICAL_REVIEWER': {
    priority: 290,
    title: 'Critical Reviewer',
    description: 'Provided substantial critical feedback',
    icon: CriticIcon,
    color: {
      bg: '#FFEBEE',
      text: '#C62828',
      contrastText: '#B71C1C'
    }
  },
  'TOUGH_CRITIC': {
    priority: 280,
    title: 'Tough Critic',
    description: 'Consistently critical of specific businesses',
    icon: DislikeIcon,
    color: {
      bg: '#FFE0B2',
      text: '#E65100',
      contrastText: '#BF360C'
    }
  },

  
    // Helpfulness (400-499)
    'COMMUNITY_HELPER': {
      priority: 490,
      title: 'Community Helper',
      description: 'Consistently helps other users',
      icon: CommunityIcon,
      color: {
        bg: '#FFF3E0',
        text: '#EF6C00',
        contrastText: '#E65100'
      }
    },
  
    // Regular Engagement (300-399)
    'ACTIVE_VOTER': {
      priority: 390,
      title: 'Active Voter',
      description: 'Regularly votes on review helpfulness',
      icon: HelpfulIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    'WEEKLY_REGULAR': {
      priority: 380,
      title: 'Weekly Regular',
      description: '10-week active streak',
      icon: StreakIcon,
      color: {
        bg: '#E0F2F1',
        text: '#00796B',
        contrastText: '#004D40'
      }
    },
  
    // Social Interaction (200-299)
    'CONVERSATION_STARTER': {
      priority: 290,
      title: 'Conversation Starter',
      description: 'Sparks engaging discussions',
      icon: EngagingIcon,
      color: {
        bg: '#F3E5F5',
        text: '#7B1FA2',
        contrastText: '#4A148C'
      }
    },
    'SOCIAL_BUTTERFLY': {
      priority: 280,
      title: 'Social Butterfly',
      description: 'Connected with many community members',
      icon: NetworkIcon,
      color: {
        bg: '#E1F5FE',
        text: '#0288D1',
        contrastText: '#01579B'
      }
    },
  
    // Discovery (100-199)
    'EARLY_REVIEWER': {
      priority: 190,
      title: 'Early Reviewer',
      description: 'Among first to review new businesses',
      icon: DiscovererIcon,
      color: {
        bg: '#E1F5FE',
        text: '#0288D1',
        contrastText: '#01579B'
      }
    },
    'FRESH_PERSPECTIVE': {
      priority: 180,
      title: 'Fresh Perspective',
      description: 'Brings new insights to discussions',
      icon: FreshIcon,
      color: {
        bg: '#F3E5F5',
        text: '#7B1FA2',
        contrastText: '#4A148C'
      }
    }
  };
  
  // Helper functions
  export const getTopAchievements = (achievements, limit = 3) => {
    if (!achievements || achievements.length === 0) return [];
    
    return achievements
      .filter(achievement => ACHIEVEMENT_CONFIG[achievement])
      .sort((a, b) => 
        ACHIEVEMENT_CONFIG[b].priority - ACHIEVEMENT_CONFIG[a].priority
      )
      .slice(0, limit);
  };
  
  export const getAchievementsByCategory = (achievements, category) => {
    if (!achievements || !ACHIEVEMENT_CATEGORIES[category]) return [];
    
    const range = ACHIEVEMENT_CATEGORIES[category].range;
    return achievements.filter(achievement => {
      const config = ACHIEVEMENT_CONFIG[achievement];
      return config && config.priority >= range[0] && config.priority <= range[1];
    });
  };
  
  export const DEFAULT_ACHIEVEMENT = {
    title: 'New Member',
    description: 'Just started exploring reviews',
    icon: BadgeIcon,
    color: {
      bg: '#F5F5F5',
      text: '#616161',
      contrastText: '#424242'
    }
  };