
import {filesApi} from './files' 


export const filespresignurldocs = filesApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreSignedUrlDocs: builder.query({
      query: ({userId, businessId, eventType, documentType}) => `s3/user/docs?eventType=${eventType}&businessId=${businessId}&userId=${userId}&documentType=${documentType}`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useLazyGetPreSignedUrlDocsQuery, useGetPreSignedUrlDocsQuery, usePrefetch } = filespresignurldocs