import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Chip
} from '@mui/material';
import { ACHIEVEMENT_CONFIG, getTopAchievements } from './UserStatusConfig';

// Default achievements for new users or empty states
const DEFAULT_ACHIEVEMENTS = [
  'FIRST_REVIEW',
  'ACTIVE_EXPLORER',
  'REVIEWER_IN_MAKING'
];

const AchievementsCard = ({ achievements = [] }) => {
  // Get 4 top achievements (we'll display 2nd-4th)
  const topAchievements = achievements.length > 0 
    ? getTopAchievements(achievements, 4).slice(1)  // Skip the first (highest) achievement
    : getTopAchievements(DEFAULT_ACHIEVEMENTS, 3);  // Use defaults if no achievements

  return (
    <Card variant="outlined" sx={{ border: 'none' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Achievements
        </Typography>
        <Stack spacing={2}>
          {topAchievements.map((achievementId) => {
            const achievement = ACHIEVEMENT_CONFIG[achievementId];
            const Icon = achievement.icon;
            
            return (
              <Chip
                key={achievementId}
                icon={<Icon />}
                label={achievement.title}
                variant="outlined"
                sx={{
                  justifyContent: 'flex-start',
                  borderColor: achievement.color.text,
                  color: achievement.color.text,
                  bgcolor: achievement.color.bg,
                  '& .MuiChip-icon': {
                    color: achievement.color.contrastText
                  },
                  '&:hover': {
                    bgcolor: achievement.color.bg,
                    opacity: 0.9
                  }
                }}
              />
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

AchievementsCard.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.string)
};

export default AchievementsCard;