import { 
    Whatshot as TrendingIcon,
    NewReleases as NewIcon,
    Verified as VerifiedIcon,
    EmojiEvents as AwardIcon,
    Star as PopularIcon,
    Stars as Stars,
    Favorite as FavoriteIcon,
    TrendingUp as GrowingIcon,
    LocalOffer as SpecialIcon,
    AccessTime as TimerIcon,
    Announcement as AlertIcon,
    Diamond as Diamond,
    Support as Support, 
    Update as UpdateIcon,
    Event as EventIcon,
    Grade as ExcellenceIcon,
    Speed as ResponseIcon,
    WorkspacePremium as PremiumIcon,
    People as CommunityIcon,
    Celebration as CelebrationIcon,
    Analytics as StatsIcon,
    Today as SeasonalIcon,
    CardGiftcard as SpecialOfferIcon,
    CalendarMonth as BookingIcon,
    Build as RenovatedIcon,
    PhoneIphone as MobileIcon,
    Park as EcoIcon,
    Security as SecurityIcon,
    Assignment as LicenseIcon,
    VerifiedUser as IdentityIcon,
    GppGood as InsuranceIcon,
    Description as DocumentIcon,
    QueryStats as PerformanceIcon,
    AlarmOn as QuickResponseIcon,
    WatchLater as AlwaysAvailableIcon,
    ThumbUp as QualityIcon,
    Timer as OnTimeIcon,
    Psychology as ExpertIcon,
    MonetizationOn as PaymentIcon,
    Videocam as VirtualIcon,
    LocationOn as LocationIcon,
    Groups as GroupIcon,
    FamilyRestroom as FamilyIcon,
    Pets as PetIcon,
    AccessibleForward as AccessibleIcon,
    School as StudentIcon,
    Loyalty as DiscountIcon,
    History as EstablishedIcon,
    Group as ServedIcon,
    TrendingUp as ExpandingIcon,
    Hub as CommunityLeaderIcon
  } from '@mui/icons-material';
  
  export const STATUS_CATEGORIES = {
    ALERTS: { range: [900, 999] },
    ACHIEVEMENTS: { range: [800, 899] },
    MILESTONES: { range: [700, 799] },
    SEASONAL: { range: [600, 699] },
    BUSINESS_STATUS: { range: [500, 599] },
    PROMOTIONAL: { range: [400, 499] },
    VERIFICATION: { range: [300, 399] },
    PERFORMANCE: { range: [200, 299] },
    FEATURES: { range: [100, 199] }
  };
  
  export const STATUS_CONFIG = {
      // Alert Headers (900-999)
    'LIMITED_AVAILABILITY': {
        priority: 999,
        title: 'Limited Availability This Week',
        description: 'Book soon to secure your spot',
        icon: TimerIcon,
        color: {
        bg: '#FFE0E0',
        text: '#D32F2F',
        contrastText: '#C62828'
        }
    },
    'IMPORTANT_UPDATE': {
        priority: 990,
        title: 'Important Business Update',
        description: 'Check our latest announcement',
        icon: AlertIcon,
        color: {
        bg: '#E3F2FD',
        text: '#1976D2',
        contrastText: '#1565C0'
        }
    },
    'HOURS_UPDATE': {
        priority: 980,
        title: 'Updated Operating Hours',
        description: 'New business hours now in effect',
        icon: UpdateIcon,
        color: {
        bg: '#E8EAF6',
        text: '#3949AB',
        contrastText: '#283593'
        }
    },
    'SPECIAL_EVENT': {
        priority: 970,
        title: 'Special Event Coming Soon',
        description: 'Stay tuned for our upcoming event',
        icon: EventIcon,
        color: {
        bg: '#F3E5F5',
        text: '#7B1FA2',
        contrastText: '#6A1B9A'
        }
    },

    // Special Achievements (800-899)
    'VERIFIED_EXCELLENCE': {
        priority: 890,
        title: 'Verified Excellence',
        description: 'Top 10% in Customer Satisfaction',
        icon: ExcellenceIcon,
        color: {
        bg: '#FFF8E1',
        text: '#F57F17',
        contrastText: '#F57F17'
        }
    },
    'PERFECT_RESPONSE': {
        priority: 880,
        title: 'Perfect Response Rate',
        description: 'Responds within 24 hours',
        icon: ResponseIcon,
        color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#2E7D32'
        }
    },
    'QUALITY_AWARD': {
        priority: 870,
        title: 'Quality Service Award Winner',
        description: 'Recognized for exceptional service standards',
        icon: AwardIcon,
        color: {
        bg: '#FFF3E0',
        text: '#E65100',
        contrastText: '#E65100'
        }
    },
    'PREMIUM_PARTNER': {
        priority: 860,
        title: 'Premium Business Partner',
        description: 'Certified excellence in service delivery',
        icon: PremiumIcon,
        color: {
        bg: '#E0F7FA',
        text: '#006064',
        contrastText: '#006064'
        }
    },
    'TRUSTED_LOCAL': {
        priority: 850,
        title: 'Trusted by Local Customers',
        description: 'Strong community reputation',
        icon: CommunityIcon,
        color: {
        bg: '#F3E5F5',
        text: '#6A1B9A',
        contrastText: '#6A1B9A'
        }
    },

    // Milestones (700-799)
    'ONE_YEAR': {
        priority: 790,
        title: 'Celebrating 1 Year on Revfer',
        description: 'A year of serving our community',
        icon: CelebrationIcon,
        color: {
        bg: '#E8EAF6',
        text: '#283593',
        contrastText: '#283593'
        }
    },
    'HUNDRED_REVIEWS': {
        priority: 780,
        title: '100+ Verified Reviews',
        description: 'Trusted by our community',
        icon: StatsIcon,
        color: {
        bg: '#E0F2F1',
        text: '#004D40',
        contrastText: '#004D40'
        }
    },
    'FASTEST_GROWING': {
        priority: 770,
        title: 'Fastest Growing in Category',
        description: 'Rapidly gaining customer trust',
        icon: GrowingIcon,
        color: {
        bg: '#F3E5F5',
        text: '#6A1B9A',
        contrastText: '#6A1B9A'
        }
    },
    'HIGH_RETURN_RATE': {
        priority: 760,
        title: 'High Customer Return Rate',
        description: 'Customers love our service',
        icon: FavoriteIcon,
        color: {
        bg: '#FFF3E0',
        text: '#E65100',
        contrastText: '#E65100'
        }
    },
    'MILESTONE_25': {
        priority: 710,
        title: '25 Reviews Milestone',
        description: 'Earned trust from 25 customers',
        icon: AwardIcon,
        color: {
            bg: '#E8EAF6',    // Light indigo
            text: '#3949AB',   // Indigo
            contrastText: '#283593'  // Dark indigo
        }
    },

    'MILESTONE_50': {
        priority: 720,
        title: '50 Reviews Milestone',
        description: 'Trusted by 50 customers',
        icon: PremiumIcon,
        color: {
            bg: '#E3F2FD',    // Light blue
            text: '#1565C0',   // Blue
            contrastText: '#0D47A1'  // Dark blue
        }
    },

    'MILESTONE_75': {
        priority: 730,
        title: '75 Reviews Milestone',
        description: 'Verified by 75 customers',
        icon: Stars,
        color: {
            bg: '#FFF8E1',    // Light amber
            text: '#F57F17',   // Amber
            contrastText: '#FF8F00'  // Dark amber
        }
    },

    'MILESTONE_100': {
        priority: 740,
        title: '100 Reviews Club',
        description: 'Achieved 100 customer reviews',
        icon: PopularIcon,
        color: {
            bg: '#FFE0B2',    // Light orange
            text: '#E65100',   // Orange
            contrastText: '#BF360C'  // Dark orange
        }
    },
    'GROWING_BUSINESS': {
        priority: 705,  // Just below MILESTONE_25 (710)
        title: 'Growing Business',
        description: 'Gaining momentum with 10+ customer reviews',
        icon: GrowingIcon,  // Shows upward growth trajectory
        color: {
            bg: '#E1F5FE',    // Light blue - represents growth/progress
            text: '#0277BD',   // Medium blue
            contrastText: '#01579B'  // Dark blue
        }
    },

    // Seasonal Headers (600-699)
    'HOLIDAY_HOURS': {
        priority: 690,
        title: 'Holiday Hours Updated',
        description: 'Check our special holiday schedule',
        icon: SeasonalIcon,
        color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#2E7D32'
        }
    },
    'SEASON_UPDATE': {
        priority: 680,
        title: 'Seasonal Update Available',
        description: 'Check out our seasonal offerings',
        icon: SeasonalIcon,
        color: {
        bg: '#F3E5F5',
        text: '#6A1B9A',
        contrastText: '#6A1B9A'
        }
    },

    // Business Status Headers (500-599)
    'TRENDING_LOCAL': {
        priority: 590,
        title: 'Trending Local Business',
        description: 'Popular in your neighborhood',
        icon: TrendingIcon,
        color: {
        bg: '#FFF4E5',
        text: '#B76E00',
        contrastText: '#804E00'
        }
    },
    'TOP_RATED': {
        priority: 580,
        title: 'Top Rated in Category',
        description: 'Outstanding performance in their field',
        icon: AwardIcon,
        color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
        }
    },
    'CUSTOMER_FAVORITE': {
        priority: 570,
        title: 'Customer Favorite',
        description: 'Highly recommended by customers',
        icon: FavoriteIcon,
        color: {
        bg: '#FFF3F3',
        text: '#C52828',
        contrastText: '#B71C1C'
        }
    },
    'HIDDEN_GEM': {
        priority: 565,
        title: 'Hidden Gem',
        description: 'Highly rated by early discoverers',
        icon: Diamond,
        color: {
            bg: '#E1F5FE',
            text: '#0277BD',
            contrastText: '#01579B'
        }
    },
    'COMMUNITY_CHOICE': {
        priority: 560,
        title: 'Community Choice',
        description: 'Beloved by local customers',
        icon: CommunityIcon,
        color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
        }
    },

    // Promotional Headers (400-499)
    'SPECIAL_OFFER': {
        priority: 490,
        title: 'Special Offer Available',
        description: 'Limited time deals for our customers',
        icon: SpecialOfferIcon,
        color: {
        bg: '#E8EAF6',
        text: '#283593',
        contrastText: '#1A237E'
        }
    },
    'ONLINE_BOOKING': {
        priority: 480,
        title: 'Now Accepting Online Bookings',
        description: 'Easy and convenient scheduling',
        icon: BookingIcon,
        color: {
        bg: '#E0F2F1',
        text: '#00695C',
        contrastText: '#004D40'
        }
    },
    'RENOVATED': {
        priority: 470,
        title: 'Recently Renovated',
        description: 'Fresh new look and improved facilities',
        icon: RenovatedIcon,
        color: {
        bg: '#F3E5F5',
        text: '#6A1B9A',
        contrastText: '#4A148C'
        }
    },
    'MOBILE_APP': {
        priority: 460,
        title: 'New Mobile App Available',
        description: 'Download our app for better service',
        icon: MobileIcon,
        color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
        }
    },
    'ECO_FRIENDLY': {
        priority: 450,
        title: 'Eco-Friendly Certified',
        description: 'Committed to environmental sustainability',
        icon: EcoIcon,
        color: {
        bg: '#E0F2F1',
        text: '#00695C',
        contrastText: '#004D40'
        }
    },
    'NEW_BUSINESS': {
        priority: 400,
        title: 'New on Revfer',
        description: 'Recently joined our trusted business community',
        icon: NewIcon,
        color: {
        bg: '#F9FBE7',
        text: '#827717',
        contrastText: '#9E9D24'
        }
    },
  
    // Verification & Trust (300-399)
    'IDENTITY_VERIFIED': {
      priority: 399,
      title: 'Identity Verified',
      description: 'Business owner identity confirmed',
      icon: IdentityIcon,
      color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
      }
    },
    'LICENSED_BUSINESS': {
      priority: 390,
      title: 'Licensed Business',
      description: 'Fully licensed and registered',
      icon: LicenseIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    'INSURANCE_VERIFIED': {
      priority: 380,
      title: 'Insurance Verified',
      description: 'Properly insured business',
      icon: InsuranceIcon,
      color: {
        bg: '#F3E5F5',
        text: '#6A1B9A',
        contrastText: '#4A148C'
      }
    },
    'CLAIMED_BUSINESS': {
        priority: 375,  // Between IDENTITY_VERIFIED (399) and INSURANCE_VERIFIED (380)
        title: 'Claimed Business',
        description: 'Verified owner or representative manages this listing',
        icon: IdentityIcon,  // OR BusinessCenter
        color: {
            bg: '#E8F5E9',    // Light green for trust/verification
            text: '#2E7D32',   // Green
            contrastText: '#1B5E20'  // Dark green
        }
    },
    // Add all other new statuses...
  
    // Performance Indicators (200-299)
    'QUICK_RESPONSE': {
      priority: 299,
      title: 'Top 10% Response Rate',
      description: 'Among the most responsive businesses',
      icon: QuickResponseIcon,
      color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
      }
    },
    'HIGHLY_RESPONSIVE': {
        priority: 295,
        title: 'Highly Responsive',
        description: 'Responds to over 90% of customer feedback',
        icon: Support,
        color: {
            bg: '#F3E5F5',    // Soft purple background
            text: '#6A1B9A',   // Deep purple text
            contrastText: '#4A148C'  // Darker purple for icon
        }
    },
    '24_7_AVAILABLE': {
      priority: 290,
      title: '24/7 Available',
      description: 'Always ready to serve customers',
      icon: AlwaysAvailableIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    // Add remaining performance indicators...
  
    // Special Features (100-199)
    'MOBILE_PAYMENTS': {
      priority: 199,
      title: 'Mobile Payments Accepted',
      description: 'Convenient payment options available',
      icon: PaymentIcon,
      color: {
        bg: '#E8F5E9',
        text: '#2E7D32',
        contrastText: '#1B5E20'
      }
    },
    'VIRTUAL_CONSULT': {
      priority: 190,
      title: 'Virtual Consultations',
      description: 'Remote sessions available',
      icon: VirtualIcon,
      color: {
        bg: '#E3F2FD',
        text: '#1565C0',
        contrastText: '#0D47A1'
      }
    },
    // Continue with all other new statuses...
  };
  
  // Helper functions
  export const getHighestPriorityStatus = (tags) => {
    if (!tags || tags.length === 0) return null;
    
    return tags.reduce((highest, current) => {
      const currentConfig = STATUS_CONFIG[current];
      const highestConfig = STATUS_CONFIG[highest];
      
      if (!currentConfig) return highest;
      if (!highest || !highestConfig) return current;
      
      return currentConfig.priority > highestConfig.priority ? current : highest;
    }, null);
  };
  
  export const getSecondaryStatuses = (tags) => {
    if (!tags || tags.length < 2) return [];
    
    return tags
      .filter(tag => STATUS_CONFIG[tag])
      .sort((a, b) => STATUS_CONFIG[b].priority - STATUS_CONFIG[a].priority)
      .slice(1, 4); // Get 2nd, 3rd, and 4th highest priority
  };
  
  export const DEFAULT_BANNER = {
    title: 'Discover Local Excellence',
    description: 'Explore trusted recommendations from your community',
    icon: PopularIcon,
    color: {
      bg: '#A5F5F5',
      text: '#424242',
      contrastText: '#616161'
    }
  };