
import {userApi} from './user'

//Get list trending businesses for this user. Name is recomend, we still fetch trending based on tags
export const userTrendingApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    providesTags:['Endorsements'],
    getUserTrending: builder.query({
      
      query: () => `/recomendations`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserTrendingQuery, usePrefetch } = userTrendingApi