import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Rating,
  Chip,
  Button,
  TextField,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery,
  styled,
  Skeleton
} from '@mui/material';
import { Add as AddIcon, PhotoCamera } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useSpCommentPostMutation } from '../../redux/thunks/sp/spcommentpost';
import { useGenerateTextMutation } from '../../redux/thunks/ai/generatetext';


// Styled components for MUI v5
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  '& .MuiCardContent-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const PhotoUploadButton = styled(IconButton)(({ theme }) => ({
  width: 100,
  height: 100,
  border: '1px dashed',
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const CommentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2)
}));



const COMMENT_TYPES = [
  { id: 'agree', label: 'Agree', color: 'success' },
  { id: 'disagree', label: 'Disagree', color: 'error' },
  { id: 'question', label: 'Question', color: 'default' }
];

const TONES = [
  { id: 'professional', label: 'Professional' },
  { id: 'casual', label: 'Casual' }
];

const ReviewCommentsPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const token = useSelector((state) => state.auth?.token || null);
  const thisUserPhoto = useSelector((state) => state.auth?.user?.profileImageUrl || '');
  const thisUserdisplayName = useSelector((state) => state.auth?.user?.displayName || '');

  const review = location.state;
  console.log('review: ', review);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [comments, setComments] = useState(review.comments || []);
  const [isLoading, setIsLoading] = useState(false);
  const [commentType, setCommentType] = useState('');
  const [tone, setTone] = useState('professional');
  const [comment, setComment] = useState('');
  const [keyPoints, setKeyPoints] = useState(['', '']);
  const [isGenerating, setIsGenerating] = useState(false);
  const [thisToken, setThisToken] = useState(null);


  const [postComment, { isLoading: isCommentsLoading }] = useSpCommentPostMutation();
  const [generateText, { isLoading: isgenerateTextLoading }] = useGenerateTextMutation();

  

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setThisToken(token);
    }
  }, [authStatus, token]);

  

  const handleGenerateComment = async () => {
    console.log('thisToken', thisToken)
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    } else {
      setIsGenerating(true);
      let auth = {
        token: thisToken,
        authType: 'token'
      };
      const payload = {
        auth,
        businessId:review.businessId,
        keywords: keyPoints.filter(kw => kw !== ''),
        type: tone, //fix this later to match backend
        tone: tone,
        generationType: 'comment',
        review: review.review,
        comments: review.comments

      };

        console.log('API Payload:', payload);

        // Call the API with the payload (assuming you have a function to do this)
        const generatedComment = await generateText({payload});

        console.log('generatedComment', generatedComment)
        setIsGenerating(false);

        setComment(generatedComment.data.text);

    }

    
  };

  const handleAddComment = async () => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    } else {

      if (comment.trim() === '') {
        return;
      }
      console.log('posting comment', )
  
      let auth = {
        token: thisToken,
        authType: 'token'
      };
  
      let latestComment = {
        comment: comment,
        reviewId: review._id
      };
  
      const payload = {
        auth,
        review: latestComment,
        spId: review.spId
      };
  
      console.log('posting comment', payload)
  
      const response = await postComment({ payload });
      console.log('response', response)
      const updatedComments = [...comments, { displayName: thisUserdisplayName, comment: comment, created: new Date(), photo: thisUserPhoto }];
      setComments(updatedComments);
  
      setComment('');
    }
   
  };


  const LoadingReview = () => (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Skeleton variant="circular" width={56} height={56} />
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width={120} height={24} />
          <Skeleton variant="text" width={80} height={20} />
          <Skeleton variant="text" width={120} height={24} />
        </Box>
      </Box>
      <Skeleton variant="rectangular" height={100} />
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Skeleton variant="rectangular" width={120} height={80} />
        <Skeleton variant="rectangular" width={120} height={80} />
        <Skeleton variant="rectangular" width={120} height={80} />
      </Box>
      <Box>
        <Skeleton variant="text" width={200} height={32} />
        {[1, 2].map((_, index) => (
          <Box key={index} sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <Skeleton variant="circular" width={32} height={32} />
              <Box sx={{ flex: 1 }}>
                <Skeleton variant="text" width={120} />
                <Skeleton variant="text" width={80} />
              </Box>
            </Box>
            <Skeleton variant="rectangular" height={60} />
          </Box>
        ))}
      </Box>
    </Stack>
  );

  const LoadingComment = () => (
    <Box sx={{ width: '100%' }}>
      
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

    </Box>
    
  );

  const LoadingSuggestion = () => (
    <Card variant="outlined">
      <CardContent>
        <Skeleton variant="text" sx={{ mb: 1 }} />
        <Skeleton variant="text" width="80%" sx={{ mb: 1 }} />
        <Skeleton variant="rectangular" width={80} height={32} />
      </CardContent>
    </Card>
  );


  return (
    <Box sx={{ maxWidth: theme.breakpoints.values.lg, margin: '0 auto', p: 2 }}>
      <Grid container spacing={2}>
        {/* Review Content */}
        <Grid item xs={12} md={5}>
          <StyledCard>
            <CardContent>
              {isLoading ? (
                <LoadingReview />
              ) : (
                <>
                  <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                    <Avatar sx={{ width: 56, height: 56 }}>
                      {review.userDetails.displayName[0]}
                    </Avatar>
                    <Box>
                      <Typography variant="h6">{review.userDetails.displayName}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {review.created}
                      </Typography>
                      <Rating value={review.rating} readOnly />
                    </Box>
                  </Box>

                  <Typography variant="body1" paragraph>
                    {review.review}
                  </Typography>

                  {review.photos && review.photos.length > 0 && (
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Photos ({review.photos.length})
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        {review.photos.map((photo, index) => (
                          <Box
                            key={index}
                            component="img"
                            src={photo}
                            alt={`Review ${index + 1}`}
                            sx={{
                              width: 120,
                              height: 80,
                              borderRadius: 1,
                              objectFit: 'cover'
                            }}
                          />
                        ))}
                      </Stack>
                    </Box>
                  )}

                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Previous Comments ({comments.length})
                    </Typography>
                    <Stack spacing={2}>
                      {comments.map(comment => (
                        <Box key={comment.id}>
                          <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                            <Avatar
                             
                              sx={{ width: 32, height: 32 }}
                            >
                              {comment.displayName[0]}
                            </Avatar>
                            <Box>
                              <Typography variant="subtitle2">
                                {comment.displayName}
                              </Typography>
                              <Typography variant="caption" color="text.secondary">
                                {comment.created.toString()}
                              </Typography>
                            </Box>
                          </Box>
                          <CommentBox>
                            <Typography variant="body2">
                              {comment.comment}
                            </Typography>
                          </CommentBox>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Comment Writing */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Write a Comment
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Share your thoughts about this review
              </Typography>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Comment Type
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {COMMENT_TYPES.map(type => (
                    <Chip
                      key={type.id}
                      label={type.label}
                      color={type.color}
                      onClick={() => setCommentType(type.id)}
                      variant={commentType === type.id ? 'filled' : 'outlined'}
                      sx={{ m: 0.5 }}
                    />
                  ))}
                </Stack>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Your Comment
                </Typography>
                {isGenerating ? (
                  <LoadingComment />
                ) : (
                  <TextField
                    multiline
                    rows={8}
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write your comment here..."
                    variant="outlined"
                  />
                )}
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Attachments (Optional)
                </Typography>
                <PhotoUploadButton component="label">
                  <input hidden accept="image/*" type="file" />
                  <AddIcon />
                </PhotoUploadButton>
              </Box>

              <Stack spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddComment} 
                  disabled={isCommentsLoading}
                  fullWidth
                >
                  Post Comment
                </Button>
                <Button variant="outlined" fullWidth>
                  Cancel
                </Button>
              </Stack>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* AI Assistant */}
        <Grid item xs={12} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                AI Assistant
              </Typography>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Select Tone
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {TONES.map(t => (
                    <Chip
                      key={t.id}
                      label={t.label}
                      onClick={() => setTone(t.id)}
                      variant={tone === t.id ? 'filled' : 'outlined'}
                      sx={{ m: 0.5 }}
                    />
                  ))}
                </Stack>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Key Points
                </Typography>
                <Stack spacing={1}>
                  {keyPoints.map((point, index) => (
                    <TextField
                      key={index}
                      size="small"
                      value={point}
                      onChange={(e) => {
                        const newPoints = [...keyPoints];
                        newPoints[index] = e.target.value;
                        setKeyPoints(newPoints);
                      }}
                      placeholder={`Key point ${index + 1}`}
                      variant="outlined"
                    />
                  ))}
                </Stack>
              </Box>

              <Button
                variant="contained"
                fullWidth
                onClick={handleGenerateComment}
                disabled={isGenerating}
                sx={{ mb: 3 }}
              >
                {isGenerating ? 'Generating...' : 'Generate Comment'}
              </Button>

              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Suggestions
                </Typography>
                <Stack spacing={2}>
                  {isGenerating ? (
                    <>
                      <LoadingSuggestion />
                      <LoadingSuggestion />
                    </>
                  ) : (
                    ['I completely agree with your experience. The professional service and cleanliness you mentioned are exactly what makes this place stand out.',
                     'Thank you for sharing. I\'d like to know more about their cleaning protocols...'].map((suggestion, index) => (
                      <Card key={index} variant="outlined">
                        <CardContent>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            {suggestion}
                          </Typography>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => setComment(suggestion)}
                          >
                            Use This
                          </Button>
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Stack>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewCommentsPage;