

import { createSlice } from '@reduxjs/toolkit';
import { registerUser, signinUser} from '../thunks/auth'
import { userApi} from '../thunks/users/user'
import { spApi} from '../thunks/sp/sp'
import { filesApi} from '../thunks/files/files'
import { thingsApi} from '../thunks/things/things'
import { statsApi} from '../thunks/stats/stats'
import { unauthApi} from '../thunks/unauth/unauth'

//import {chatClear} from './chatSlice'
//import {notificationsClear} from './notificationSlice'


const initialState = {
  isLoggedIn: false,
  username: null,
  userId: null,
  token: null,
  loading: false,
  error: null,
  success: null,
  user: null,
  userRegSuccess: null,
  spId: null
}


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSignIn: (state, action) => {

      console.log("action in setSignIn=", action)

      state.username = action.payload.email;
      state.isLoggedIn = true;
      state.userId = action.payload._id;
      state.token = action.payload._id;
      state.user = action.payload;
      state.spId = action.payload.spId;

  },
    setSignOut: (state) => {
      state.username = null;
      state.isLoggedIn = false;
      state.userId = null;
      state.token = null;
      state.token = null;
      state.user = null;
      state.userRegSuccess = false;
      state.spId = null
      
  }
    
  },
  extraReducers: builder => {
    builder.addCase(registerUser.pending, state => {
      //console.log('reducer: pending ')
      state.loading = true
      state.error = null
    })
    builder.addCase(registerUser.fulfilled, (state, action) => {
      //console.log('reducer: fulfilled, action ', action.payload)
      state.loading = false
      state.success = true // registration successful
      state.token = null
      state.userId = null
      state.isLoggedIn = null
      state.userRegSuccess = true

    })
    builder.addCase(registerUser.rejected, (state, action) => {
      //console.log('reducer: rejected, action ', action.error)
      state.loading = false
      state.error = action
      state.userRegSuccess = false
    })
    builder.addCase(signinUser.pending, state => {
      //console.log('reducer: pending ')
      state.loading = true
      state.error = null
    })
    builder.addCase(signinUser.fulfilled, (state, action) => {
      //console.log('reducer: fulfilled, action ', action.payload)
      state.loading = false
      state.success = true // login successful
      state.token = action.payload.token
      state.userId = action.payload.user.userId
      state.isLoggedIn = true
      state.user = action.payload.user.user;
      state.email = action.payload.user.user.email;


    })
    builder.addCase(signinUser.rejected, (state, action) => {
      //console.log('reducer: rejected, action ', action.error)
      state.loading = false
      state.error = action
    })
  
  }
})

// Thunk for signout
export const signOut = () => (dispatch) => {
  // Dispatch the setSignOut reducer
  dispatch(authSlice.actions.setSignOut());

  //dispatch(chatClear());
  //dispatch(notificationsClear());


  // Then clear the RTK Query cache for the userApi
  dispatch(userApi.util.resetApiState());
  dispatch(spApi.util.resetApiState());
  dispatch(filesApi.util.resetApiState());
  dispatch(thingsApi.util.resetApiState());
  dispatch(statsApi.util.resetApiState());
  dispatch(unauthApi.util.resetApiState());
};




export const { setSignIn } = authSlice.actions;
export const setSignOut = authSlice.actions.setSignOut
export default authSlice.reducer


