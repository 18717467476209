import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Rating, 
  Chip, 
  Button, 
  Stack, 
  IconButton, 
  TextField,
  useTheme,
  useMediaQuery
} from '@mui/material';
import styled from '@emotion/styled';  // Changed import
import { PhotoCamera, Add, Info, LocationOn, Category, Clear, ExpandMore  } from '@mui/icons-material';

import { useSpReviewPostMutation } from '../../redux/thunks/sp/spreviewpost';
import { useGenerateTextMutation } from '../../redux/thunks/ai/generatetext';
import { useLocation, useNavigate } from 'react-router-dom';


// Styled components using @emotion/styled
const ResponsiveLayout = styled(Box)`
  display: flex;
  gap: ${props => props.theme.spacing(2)};
  padding: ${props => props.theme.spacing(2)};
  
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const BusinessImage = styled('img')`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  margin-bottom: ${props => props.theme.spacing(2)};
`;




const TipsList = styled(Box)`
  max-height: 300px;
  overflow-y: auto;
  padding-right: ${props => props.theme.spacing(1)};
  
  '&::-webkit-scrollbar': {
    width: 6px,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: ${props => props.theme.palette.grey[300]},
    borderRadius: 3,
  }
`;

const LeftColumnContainer = styled(Stack)`
  gap: ${props => props.theme.spacing(2)};
  width: 300px;
  
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;



const ColumnCard = styled(Card)`
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  box-shadow: ${props => props.theme.shadows[0]};
  
  ${props => props.theme.breakpoints.down('md')} {
    width: 100% !important;
    margin-bottom: ${props => props.theme.spacing(1)};
  }
`;

const ReviewTextArea = styled('textarea')`
  width: 100%;
  min-height: 200px;
  padding: ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.body1.fontSize};
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.palette.primary.main};
  }
  
  ${props => props.theme.breakpoints.down('sm')} {
    min-height: 150px;
  }
`;



// Add this styled component for the photo preview
const PhotoPreviewGrid = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(1)};
`;

const PhotoPreviewContainer = styled(Box)`
  position: relative;
  width: 100px;
  height: 100px;
  
  ${props => props.theme.breakpoints.down('sm')} {
    width: 80px;
    height: 80px;
  }
`;

const PhotoPreview = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 4px;
  background-color: ${props => props.theme.palette.error.main};
  color: white;
  
  &:hover {
    background-color: ${props => props.theme.palette.error.dark};
  }
`;

const MainContainer = styled(Box)`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: ${props => props.theme.spacing(2)};
`;

// Add a styled component for AI section
const AiInputSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
`;

// Predefined options
const POSITIVE_EXPERIENCES = [
  'Great Service',
  'Quality Products',
  'Clean Space',
  'Good Value',
  'Professional',
  'Friendly Staff',
];

// Add writing tips array
const WRITING_TIPS = [
  'Be specific about your experience',
  'Include details about the service or product',
  'Mention what makes this business unique',
  'Share both positive and constructive feedback',
  'Describe the customer service quality',
  'Talk about the value for money',
  'Include details about your visit timing',
  'Comment on the atmosphere or environment'
];

const WritingTipsCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Desktop Card View
  const TipsContent = () => (
    <TipsList>
      {WRITING_TIPS.map((tip, index) => (
        <Box 
          key={index} 
          sx={{ 
            display: 'flex', 
            gap: 1, 
            mb: 1.5,
            '&:last-child': { mb: 0 }
          }}
        >
          <Typography 
            variant="body2" 
            color="textSecondary"
            sx={{ 
              display: 'flex',
              alignItems: 'flex-start' 
            }}
          >
            <span style={{ marginRight: '8px' }}>•</span>
            {tip}
          </Typography>
        </Box>
      ))}
    </TipsList>
  );

  // Return different layouts based on screen size
  if (isMobile) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderBottom: `0px solid ${theme.palette.divider}`
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Info fontSize="small" color="primary" />
            <Typography variant="subtitle1">Writing Tips</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TipsContent />
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <ColumnCard>
      <CardContent>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            mb: 2 
          }}
        >
          <Info fontSize="small" color="primary" />
          Writing Tips
        </Typography>
        <TipsContent />
      </CardContent>
    </ColumnCard>
  );
};



const WriteReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedbackProps = location.state;
  console.log('in write review feedbackProps: ', feedbackProps);  
  // Redux state and mutations

  const [createReview, { isLoading: isReviewLoading }] = useSpReviewPostMutation();
  const [generateText, { isLoading: isgenerateTextLoading }] = useGenerateTextMutation();

  // Add state for AI keywords
  const [keyword1, setKeyword1] = useState('');
  const [keyword2, setKeyword2] = useState('');
  const [keyword3, setKeyword3] = useState('');

  // Handle AI review generation
  

  // Local state
  const [rating, setRating] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [selectedPositive, setSelectedPositive] = useState([]);
  const [selectedImprovement, setSelectedImprovement] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = useSelector((state) => state.auth.token);

  const handleGenerateReview = async () => {
    // Combine all selected items and keywords
    

    let auth = {
      token: token,
      authType: 'token',
    };
    const payload = {
      auth,
      businessId: feedbackProps.businessId,
      keywords: [
        ...selectedKeywords,
        keyword1,
        keyword2,
        keyword3
      ].filter(Boolean), // Remove empty strings
      
      generationType: 'review',
    };

    console.log('API Payload:', payload);

    

    try {
      // Here you would call your AI generation endpoint
      const generatedReview = await generateText({payload});

      console.log('generatedReview', generatedReview)
    
      setReviewText(generatedReview.data.text);
    } catch (error) {
      console.error('Error generating review:', error);
    }
  };

  // Handle chip selection
  const handleChipClick = (chip, type) => {
    switch (type) {
      case 'positive':
        setSelectedPositive(prev => 
          prev.includes(chip) ? prev.filter(item => item !== chip) : [...prev, chip]
        );
        break;
      case 'improvement':
        setSelectedImprovement(prev => 
          prev.includes(chip) ? prev.filter(item => item !== chip) : [...prev, chip]
        );
        break;
      case 'keyword':
        setSelectedKeywords(prev => 
          prev.includes(chip) ? prev.filter(item => item !== chip) : [...prev, chip]
        );
        break;
    }
  };

 // Handle photo upload
 const handlePhotoUpload = async (event) => {
  const files = Array.from(event.target.files);
  
  // Create preview URLs for the new files
  const newPhotoUrls = files.map(file => URL.createObjectURL(file));
  
  setPhotos(prevPhotos => [...prevPhotos, ...files]);
  setPhotoUrls(prevUrls => [...prevUrls, ...newPhotoUrls]);
};

// Handle photo deletion
const handleDeletePhoto = (index) => {
  // Revoke the object URL to avoid memory leaks
  URL.revokeObjectURL(photoUrls[index]);
  
  setPhotos(prevPhotos => prevPhotos.filter((_, i) => i !== index));
  setPhotoUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
};

  // Handle review submission
  const handleSubmit = async () => {
    if (!rating) return;

    setIsSubmitting(true);
    try {

        let auth = {
            token: token,
            authType: 'token',
          };
          let review = {
            review: reviewText,
            rating: rating,
            skillId: feedbackProps.skillId,
            businessId: feedbackProps.businessId
          };
  
          const payload = {
            auth,
            review,
            spId: feedbackProps.spId,
            positiveExperiences: selectedPositive,
             improvementAreas: selectedImprovement,
             keywords: selectedKeywords,
             photos: photoUrls,
          };
      await createReview({payload }).unwrap();
      // Add success handling here (redirect or show success message)
    } catch (error) {
      console.error('Error submitting review:', error);
      // Add error handling UI feedback here
    } finally {
      setIsSubmitting(false);
    }
  };

  // Clean up URLs when component unmounts
  React.useEffect(() => {
    return () => {
      photoUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, []);


  return (

    <MainContainer>
      <ResponsiveLayout>
      {/* Business Info Card */}

      <LeftColumnContainer>
          {/* Business Info Card */}
          <ColumnCard>
            <CardContent>
              <BusinessImage
                src={feedbackProps.skillPhoto}
                alt={feedbackProps?.skill?.businessName || 'Business'}
              />
              
              <Typography variant="subtitle" gutterBottom>
                {feedbackProps?.skill?.businessName}
              </Typography>
              
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Category fontSize="small" color="action" />
                  <Typography color="textSecondary" variant="body2">
                    {feedbackProps?.skill?.category[0]}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocationOn fontSize="small" color="action" />
                  <Typography color="textSecondary" variant="body2">
                    {feedbackProps?.skill?.geocodeResults?.formattedAddress || 'Location'}
                  </Typography>
                </Box>
              </Stack>

              {/* Mobile-only Rating Section */}
              <Box sx={{ display: { md: 'none' }, mt: 2 }}>
                <Typography gutterBottom>Overall Rating</Typography>
                <Rating 
                  size="large"
                  value={rating}
                  onChange={(_, newValue) => setRating(newValue)}
                />
              </Box>
            </CardContent>
          </ColumnCard>

          {/* Writing Tips - Now using the new component */}
          <WritingTipsCard />
        </LeftColumnContainer>
     

      {/* Main Review Content */}
      <Box sx={{ flex: 1 }}>
        <ColumnCard>
          <CardContent>
            {/* Desktop-only Rating Section */}
            <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 3 }}>
              <Typography gutterBottom>Overall Rating</Typography>
              <Rating 
                size="large"
                value={rating}
                onChange={(_, newValue) => setRating(newValue)}
              />
            </Box>

            {/* Experience Chips */}
            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>What did you like?</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                {POSITIVE_EXPERIENCES.map(exp => (
                  <Chip
                    key={exp}
                    label={exp}
                    onClick={() => handleChipClick(exp, 'positive')}
                    color={selectedPositive.includes(exp) ? 'primary' : 'default'}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Stack>
            </Box>

            {/* Similar sections for Improvement Areas and Keywords... */}

            {/* Review Text */}
            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Write Your Review</Typography>
              <ReviewTextArea
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                placeholder="Share your experience..."
              />
            </Box>

            {/* Photo Upload Section */}
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        Add Photos
      </Typography>
      <PhotoPreviewGrid>
        <IconButton
          color="primary"
          component="label"
          sx={{
            width: { xs: '80px', sm: '100px' },
            height: { xs: '80px', sm: '100px' },
            border: '2px dashed',
            borderColor: 'divider',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input
            hidden
            type="file"
            multiple
            accept="image/*"
            onChange={handlePhotoUpload}
          />
          <Add />
        </IconButton>
        
        {photoUrls.map((url, index) => (
          <PhotoPreviewContainer key={index}>
            <PhotoPreview
              src={url}
              alt={`Upload ${index + 1}`}
            />
            <DeleteButton
              size="small"
              onClick={() => handleDeletePhoto(index)}
            >
              <Clear sx={{ fontSize: 16 }} />
            </DeleteButton>
          </PhotoPreviewContainer>
        ))}
      </PhotoPreviewGrid>
      
      {photos.length > 0 && (
        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
          {photos.length} photo{photos.length !== 1 ? 's' : ''} selected
        </Typography>
      )}
    </Box>

            {/* Submit Button */}
            <Button
              variant="contained"
              fullWidth
              disabled={!rating || isSubmitting}
              onClick={handleSubmit}
              sx={{ mt: 2 }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Review'}
            </Button>
          </CardContent>
        </ColumnCard>
      </Box>

      {/* AI Assistant - Desktop */}
      <ColumnCard sx={{ width: 300, display: { xs: 'none', md: 'block' } }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>AI Assistant</Typography>
            <AiInputSection>
              <Typography variant="body2" color="textSecondary">
                Add additional keywords to enhance your review:
              </Typography>
              
              <TextField
                fullWidth
                size="small"
                label="Keyword 1"
                value={keyword1}
                onChange={(e) => setKeyword1(e.target.value)}
                variant="outlined"
              />
              
              <TextField
                fullWidth
                size="small"
                label="Keyword 2"
                value={keyword2}
                onChange={(e) => setKeyword2(e.target.value)}
                variant="outlined"
              />
              
              <TextField
                fullWidth
                size="small"
                label="Keyword 3"
                value={keyword3}
                onChange={(e) => setKeyword3(e.target.value)}
                variant="outlined"
              />

              <Button
                fullWidth
                variant="contained"
                onClick={handleGenerateReview}
                disabled={!rating || isSubmitting}
              >
                Generate Review
              </Button>

              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                Selected items:
              </Typography>
              
              <Box sx={{ mt: 1 }}>
                {selectedPositive.length > 0 && (
                  <Typography variant="body2">
                    Positives: {selectedPositive.join(', ')}
                  </Typography>
                )}
                
                {selectedImprovement.length > 0 && (
                  <Typography variant="body2">
                    Improvements: {selectedImprovement.join(', ')}
                  </Typography>
                )}
                
                {selectedKeywords.length > 0 && (
                  <Typography variant="body2">
                    Keywords: {selectedKeywords.join(', ')}
                  </Typography>
                )}
              </Box>
            </AiInputSection>
          </CardContent>
        </ColumnCard>

      {/* AI Assistant - Mobile */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <ColumnCard>
          <CardContent>
            <Typography variant="h6">AI Assistant</Typography>
            {/* Mobile AI assistance features */}
            <AiInputSection>
            <Typography variant="body2" color="textSecondary">
                Add additional keywords to enhance your review:
              </Typography>
              
              <TextField
                fullWidth
                size="small"
                label="Keyword 1"
                value={keyword1}
                onChange={(e) => setKeyword1(e.target.value)}
                variant="outlined"
              />
              
              <TextField
                fullWidth
                size="small"
                label="Keyword 2"
                value={keyword2}
                onChange={(e) => setKeyword2(e.target.value)}
                variant="outlined"
              />
              
              <TextField
                fullWidth
                size="small"
                label="Keyword 3"
                value={keyword3}
                onChange={(e) => setKeyword3(e.target.value)}
                variant="outlined"
              />

              <Button
                fullWidth
                variant="contained"
                onClick={handleGenerateReview}
                disabled={!rating || isSubmitting}
              >
                Generate Review
              </Button>

              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                Selected items:
              </Typography>
              
              <Box sx={{ mt: 1 }}>
                {selectedPositive.length > 0 && (
                  <Typography variant="body2">
                    Positives: {selectedPositive.join(', ')}
                  </Typography>
                )}
                
                {selectedImprovement.length > 0 && (
                  <Typography variant="body2">
                    Improvements: {selectedImprovement.join(', ')}
                  </Typography>
                )}
                
                {selectedKeywords.length > 0 && (
                  <Typography variant="body2">
                    Keywords: {selectedKeywords.join(', ')}
                  </Typography>
                )}
              </Box>
              </AiInputSection>
          </CardContent>
        </ColumnCard>
      </Box>
    </ResponsiveLayout>

    </MainContainer>
    
  );
};


export default WriteReview;
