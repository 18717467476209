import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // For auth state
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth'; // Removed getCurrentUser as it's not used
import { useGetUserByEmailQuery } from '../../redux/thunks/users/getuserbyemail';
import { setSignIn } from '../../redux/slices/auth';
import { useGetActivitiesQuery } from '../../redux/thunks/users/getactivities';
import { useGetUserTrendingQuery } from '../../redux/thunks/users/gettrending';

import moment from 'moment';



import { usePrefetch as useGetStatsGlobalPrefetch } from '../../redux/thunks/stats/statsglobal';
import { usePrefetch as useGetUserChatListPrefetch } from '../../redux/thunks/users/userchatlist';
import { usePrefetch as useGetUserNotificationsPrefetch } from '../../redux/thunks/users/usernotifications';






import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
  Chip,
  Divider,
  LinearProgress,
  IconButton,
  Skeleton,
  Card,
  CardContent,
  Rating,
  AvatarGroup,
  Alert,
} from '@mui/material';
import {
  SearchOutlined as SearchIcon,
  AddBusinessOutlined as AddBusinessIcon,
  RateReviewOutlined as ReviewIcon,
  TrendingUpOutlined as TrendingIcon,
  StarOutline as StarIcon,
  PeopleOutline as PeopleIcon,
  VerifiedOutlined as VerifiedIcon,
  BusinessOutlined as BusinessIcon,
  TimelineOutlined as TimelineIcon,
  ErrorOutline as WarningIcon,
  ArrowForward as ArrowForwardIcon,
  AutoGraph as AutoGraphIcon,
  TrendingUp as TrendingUpIcon,
  LocalActivity as LocalActivityIcon,
  RocketLaunch as RocketLaunchIcon,
  TimerOutlined as TimerIcon,
  StorefrontOutlined as StoreIcon,

  


} from '@mui/icons-material';




// ActivityOverview Component
const ActivityOverview = ({ userData, isLoading }) => {
  const navigate = useNavigate()
  console.log('userData.creationTime', userData.creationTime)
  console.log('isLoading', isLoading)




  
  const getInitials = (name) => {
      if (!name) return '';
      const [firstName, lastName] = name.split(' ');
      return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase();
  };
  if (isLoading || !userData) {
    return (
      <Box sx={{ mb: 3 }}>
        <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Skeleton variant="circular" width={60} height={60} />
            <Box sx={{ ml: 2 }}>
              <Skeleton variant="text" width={120} />
              <Skeleton variant="text" width={80} />
            </Box>
          </Box>
          <Skeleton variant="text" width="60%" />
        </Paper>

        <Paper sx={{ p: 2 }}>
          <Skeleton variant="text" width={150} sx={{ mb: 2 }} />
          {[1, 2, 3, 4].map((i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <Skeleton variant="rectangular" height={70} />
            </Box>
          ))}
        </Paper>
      </Box>
    );
  }

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(userData.creationTime));


  return (
    <Box sx={{ mb: 3 }}>
      <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor:'pointer' }} onClick={() => navigate(`/profile/${userData._id}/active`)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar
            sx={{ width: 60, height: 60 }}           
            src={userData.profileImageUrl || undefined} 
            alt={userData.displayName}
          >
            {!userData.profileImageUrl && getInitials(userData.displayName)}
          </Avatar>
          <Box sx={{ ml: 2 }}>
            <Typography variant="h6">{userData.displayName}</Typography>
            <Typography variant="body2" color="text.secondary">
              Trust Score: {userData.trustScore}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary">
          Member since {formattedDate}
        </Typography>
      </Paper>

      <Paper variant='outlined' sx={{ p: 2,  border: 'none'}}>
        <Typography variant="h6" gutterBottom>
          Activity Overview
        </Typography>

        {/* Reviews Written */}
        <Box
          sx={{
            p: 2,
            mb: 2,
            bgcolor: 'grey.50',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ReviewIcon sx={{ color: 'primary.main', mr: 2 }} />
          <Box>
            <Typography variant="h4" color="primary.main">
            {userData.userStats.reviewsWritten}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Reviews Written
            </Typography>
          </Box>
        </Box>

        {/* Businesses Following */}
        <Box
          sx={{
            p: 2,
            mb: 2,
            bgcolor: 'grey.50',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <BusinessIcon sx={{ color: 'primary.main', mr: 2 }} />
          <Box>
            <Typography variant="h4" color="primary.main">
              {userData.myTrustedThings.length}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Businesses Following
            </Typography>
          </Box>
        </Box>

        {/* People Helped */}
        <Box
          sx={{
            p: 2,
            mb: 2,
            bgcolor: 'grey.50',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <PeopleIcon sx={{ color: 'primary.main', mr: 2 }} />
          <Box>
            <Typography variant="h4" color="primary.main">
              234
            </Typography>
            <Typography variant="body2" color="text.secondary">
              People Helped
            </Typography>
          </Box>
        </Box>

        {/* Engagement Rate */}
        <Box
          sx={{
            p: 2,
            bgcolor: 'grey.50',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TimelineIcon sx={{ color: 'primary.main', mr: 2 }} />
          <Box>
            <Typography variant="h4" color="primary.main">
              85%
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Engagement Rate
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

// QuickActions Component
const QuickActions = ({ isLoading }) => {
  const navigate = useNavigate();

  const handleFindBusiness = () => navigate('/find');
  const handleAddBusiness = () => navigate('/company/new');
  const handleRequestReview = () => {
    // Show coming soon message
    console.log('Coming soon');
  };

  if (isLoading) {
    return (
      <Paper variant='outlined' sx={{ p: 2, mb: 3, border: 'none' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Skeleton variant="rectangular" width={140} height={40} />
          <Skeleton variant="rectangular" width={140} height={40} />
          <Skeleton variant="rectangular" width={200} height={40} />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 3, border: 'none' }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={handleFindBusiness}
        >
          Find Business
        </Button>
        <Button
          variant="contained"
          startIcon={<AddBusinessIcon />}
          onClick={handleAddBusiness}
        >
          Add Business
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<ReviewIcon />}
          onClick={handleRequestReview}
          endIcon={<WarningIcon />}
        >
          Request Reviews
        </Button>
      </Box>
    </Paper>
  );
};

const BusinessInsightChip = ({ type, value }) => {
    // Define chip styles based on insight type
    const getChipProps = () => {
      switch (type) {
        case 'network':
          return {
            icon: <PeopleIcon />,
            color: 'primary'
          };
        case 'ai':
          return {
            icon: <AutoGraphIcon />,
            color: 'secondary'
          };
        case 'trending':
          return {
            icon: <TrendingUpIcon />,
            color: 'success'
          };
        case 'popular':
          return {
            icon: <TrendingUpIcon />,
            color: 'success'
          };
        case 'new':
          return {
            icon: <RocketLaunchIcon />,
            color: 'success'
            };
        case 'event':
          return {
            icon: <LocalActivityIcon />,
            color: 'success'
            };
        default:
          return {
            color: 'default'
          };
      }
    };
  
    return (
      <Chip
        size="small"
        label={value}
        variant="outlined"
        {...getChipProps()}
      />
    );
};

// ForYouSection Component
const ForYouSection = ({ recommendations, isLoading }) => {
    const navigate = useNavigate();
  
    if (isLoading) {
      return (
        <Paper variant='outlined' sx={{ p: 2, mb: 3, border: 'none' }}>
          <Skeleton variant="text" width={200} height={32} />
          <Box sx={{ mt: 2 }}>
            {[1, 2, 3].map((i) => (
              <Skeleton 
                key={i} 
                variant="rectangular" 
                height={120} 
                sx={{ mb: 2 }} 
              />
            ))}
          </Box>
        </Paper>
      );
    }
  
    return (
      <Paper variant='outlined' sx={{ p: 2, mb: 3, border: 'none' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h6">
            For You
          </Typography>
        </Box>
  
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {recommendations.slice(0,3).map((business) => (
            <Paper
              key={business._id}
              elevation={0}
              sx={{ 
                p: 2, 
                bgcolor: 'grey.50',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'grey.100',
                }
              }}
              onClick={() => navigate(`/business/${business._id}/${business.businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`)}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    {business.businessName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {business.category[0]} 
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Rating value={business.avgSkillRating_all} readOnly size="small" />
                    <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                   
                      Smart Rating: {`${Math.floor(business.SkillValue_all*10)/10}`} ★
                    </Typography>
                  </Box>
                </Box>
                <BusinessInsightChip
                  type={business.insightType}
                  value={business.insightValue}
                />
              </Box>
            </Paper>
          ))}
        </Box>
  
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button 
            color="primary"
            onClick={() => navigate('/recommendations')}
            endIcon={<ArrowForwardIcon />}
          >
            Show More Recommendations
          </Button>
        </Box>
      </Paper>
    );
};

// NetworkActivity Component
const NetworkActivity = ({ activities, isLoading }) => {
  const navigate = useNavigate();
  const getActivityContent = (activity) => {
    let firstLine, secondLine;

    switch (activity.action) {
      case 'followedBusiness':
        firstLine = `now follows ${activity.businessName}`;
        secondLine = activity.details || ''; // Second line is details
        break;
      case 'reviewed':
        firstLine = `reviewed ${activity.businessName}`;
        secondLine = activity.summary || ''; // Second line is summary
        break;
      case 'endorsed':
        firstLine = `endorsed ${activity.businessName}`;
        secondLine = ''; // No second line
        break;
      case 'likedReview':
        firstLine = `liked a review of ${activity.businessName}`;
        secondLine = activity.details || ''; // Second line is details
        break;
      case 'dislikedReview':
        firstLine = `disliked a review of ${activity.businessName}`;
        secondLine = activity.details || ''; // Second line is details
        break;
      default:
        firstLine = `performed an action on ${activity.businessName}`;
        secondLine = activity.details || ''; // Default to details
    }

    const thirdLine = moment(activity.creationDate).fromNow(); // e.g., "2 hours ago"

    return { firstLine, secondLine, thirdLine };
  };

  if (isLoading) {
    return (
      <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
        <Skeleton variant="text" width={200} height={32} />
        {[1, 2, 3].map((i) => (
          <Box key={i} sx={{ mt: 2 }}>
            <Skeleton variant="rectangular" height={80} />
          </Box>
        ))}
      </Paper>
    );
  }

  if (!activities || activities.length === 0) {
    return (
      <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
        <Typography variant="h6" gutterBottom>
          Network Activity
        </Typography>
        <Alert severity="info" sx={{ mt: 2 }}>
          No reviews or references yet. This member is just getting started!
        </Alert>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Network Activity
      </Typography>
      {activities.slice(0, 2).map((activity) => {
        // Call getActivityContent and destructure the result
        const { firstLine, secondLine, thirdLine } = getActivityContent(activity);

        return (
          <Paper
            key={activity.id}
            elevation={0}
            sx={{ p: 2, mb: 2, bgcolor: 'grey.50' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt={activity.userDisplayName}
                src={activity.userPhoto}
                sx={{ mr: 2, cursor: 'pointer' }}
                onClick={() => navigate(`/profile/${activity.userId}/active`)}
              />
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/business/${activity.businessId}/${activity.businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`)}
              
              >
                <Typography variant="body1">
                  {activity.userDisplayName} {firstLine}
                </Typography>
                {secondLine && (
                  <Typography variant="body2" color="text.secondary">
                    {secondLine}
                  </Typography>
                )}
                <Typography variant="caption" color="text.secondary">
                  {thirdLine}
                </Typography>
              </Box>
            </Box>
          </Paper>
        );
      })}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          color="primary"
          onClick={() => navigate('/network/activity')}
          endIcon={<ArrowForwardIcon />}
        >
          View All Activity
        </Button>
      </Box>
    </Paper>
  );
};


const NetworkInsight = ({ icon: Icon, title, value }) => (
  <Box sx={{ p: 2, bgcolor: 'grey.50', mb: 2, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
    <Icon sx={{ color: 'primary.main', mr: 2 }} />
    <Box>
      <Typography variant="h6" color="primary.main">
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
    </Box>
  </Box>
);


// InsightsAndTrending Component
const InsightsAndTrending = ({ trendingBusinesses, isLoading }) => {
  // Hardcoded network insight data
  const networkInsights = [
    {
      icon: TimerIcon,
      title: "Hours of research time saved by your network",
      value: "156"
    },
    {
      icon: PeopleIcon,
      title: "People helped through your insights",
      value: "234"
    },
    {
      icon: StoreIcon,
      title: "Hidden gems discovered through your network",
      value: "18"
    },
    {
      icon: TrendingIcon,
      title: "Network growth this month",
      value: "45%"
    }
  ];

  if (isLoading) {
    return (
      <Box>
        <Paper variant='outlined' sx={{ p: 2, mb: 3, border: 'none' }}>
          <Skeleton variant="text" width={150} height={32} />
          <Box sx={{ mt: 2 }}>
            {[1, 2, 3, 4].map((i) => (
              <Skeleton key={i} variant="rectangular" height={80} sx={{ mb: 2 }} />
            ))}
          </Box>
        </Paper>

        <Paper sx={{ p: 2 }}>
          <Skeleton variant="text" width={150} height={32} />
          <Box sx={{ mt: 2 }}>
            {[1, 2].map((i) => (
              <Skeleton key={i} variant="rectangular" height={60} sx={{ mb: 2 }} />
            ))}
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Paper variant='outlined' sx={{ p: 2, mb: 3, border: 'none' }}>
        <Typography variant="h6" gutterBottom>
          Network Impact
        </Typography>
        {networkInsights.map((insight, index) => (
          <NetworkInsight
            key={index}
            icon={insight.icon}
            title={insight.title}
            value={insight.value}
          />
        ))}
      </Paper>

      <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
        <Typography variant="h6" gutterBottom>
          Trending Near You
        </Typography>
        {trendingBusinesses?.map((business) => (
          <Paper
            key={business._id}
            elevation={0}
            sx={{ p: 2, mb: 2, bgcolor: 'grey.50' }}
          >
            <Typography variant="subtitle2">
              {business.businessName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {business.category[0]}
            </Typography>
          </Paper>
        ))}
      </Paper>
    </Box>
  );
};

// Main HomePage Component
const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const prefetchGetStatsGlobal = useGetStatsGlobalPrefetch('getStatsGlobal');
  const prefetchGetUserChatList = useGetUserChatListPrefetch('getUserChatList');
  const prefetchGetNotifications = useGetUserNotificationsPrefetch(
    'getUserNotifications'
  );


  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState(null);
  const [uId, setUId] = useState(null);
  const token = useSelector((state) => state.auth?.token || null);

  

  const userId = useSelector((state) => state.auth?.userId || '');
  const user = useSelector((state) => state.auth?.user || '');

  console.log('user', user)
  console.log('isLoading..', isLoading)

  console.log('email', !email)


  const { data: userData, isError: isUserDataError, isLoading: isUserLoading } = useGetUserByEmailQuery(email, {skip: !email});
  console.log('userData', userData);

  const activityArgs = { userId: uId, activityType: 'providers' };
  const { data: netActivity,  isError: isNetActivityError,  isLoading: isActivityLoading,  error: netActivityError,} = useGetActivitiesQuery(activityArgs, {skip: !uId});
  console.log('netActivity', netActivity)

  const { data: trendingData,  isError: istrendingDataError,  isLoading: isTrendingLoading,  error: trendingError,} = useGetUserTrendingQuery(activityArgs, {skip: !uId});
  console.log('trendingData', trendingData)



  useEffect(() => {
    const printAccessTokenAndIdToken = async () => {
      try {
        const session = await fetchAuthSession();
        console.log('ID Token:', session.tokens.idToken.toString());
        console.log('session:', session);
        setEmail(session.tokens.idToken.payload.email);
        console.log('session.tokens.idToken.payload.email', session.tokens.idToken.payload.email)

      } catch (e) {
        console.log(e);
      }
    }

    printAccessTokenAndIdToken();
  }, []);

  useEffect(() => {
    if (email) {
      console.log('email', email)

    }
  }, [email]); // Add email as a dependency to useEffect


  //potttential race condition while settting the state there might be a time when the page is rendered. 

  useEffect(() => {
    console.log('isUserLoading', isUserLoading)
    console.log('userData', userData)
    
    


    setIsLoading(isUserLoading ||  !userData);
  }, [isUserLoading, email, userData]);

  useEffect(() => {
    if (userData) {
      
      console.log('Setting signin userdata in redux store..');
      dispatch(setSignIn(userData));
      setUId(userData._id)

      const prefetchAll = async () => {       
        const userId = userData._id;
        await Promise.all([
          //prefetchGetIncomingReq(userId),
          //prefetchGetOutgoingReq(userId),
          //prefetchGetUserProfile(userId),
          prefetchGetStatsGlobal(),
          prefetchGetUserChatList(userId),
          //prefetchGetActivities(args),
          prefetchGetNotifications(),
        ]);
      };
      prefetchAll();
   
    }
  }, [userData, navigate]);

  if(userData && userData.userDisplayStatus === 'NEW') {
    console.log('user is new: show onboarding page')
    navigate('/onboarding')

  }

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={3}>
          <ActivityOverview
            userData={user}
            isLoading={isLoading}
          />
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} md={6}>
          <QuickActions isLoading={isLoading} />
          <ForYouSection
            recommendations={userData?.recomendedBusinesses || []}
            isLoading={isLoading}
          />
          
          <NetworkActivity
            activities={netActivity}
            isLoading={isActivityLoading || isLoading}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={3}>
          <InsightsAndTrending
            trendingBusinesses={trendingData}
            isLoading={ isTrendingLoading || isLoading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;