import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { STATUS_CONFIG, DEFAULT_BANNER, getHighestPriorityStatus } from './BusinessStatusConfig';

const BusinessStatusBanner = ({ tags = [] }) => {
  const activeStatus = getHighestPriorityStatus(tags);
  const config = activeStatus ? STATUS_CONFIG[activeStatus] : DEFAULT_BANNER;
  const Icon = config.icon;

  return (
    <Paper 
      sx={{ 
        bgcolor: config.color.bg,
        color: config.color.text,
        p: 2,
        mb: 2,
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
        <Icon sx={{ color: config.color.contrastText }} />
        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: config.color.contrastText }}>
          {config.title}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ ml: 4 }}>
        {config.description}
      </Typography>
    </Paper>
  );
};

export default BusinessStatusBanner;